import images from "../../../../utils/images";

export const expertsList = [
  {
    image: images.expertsAndMentorsImage1,
    name: 'Роман Бортник',
    description: 'Диетолог, фитнес-тренер',
    experienceNote: '10 лет опыта',
    keyPoints: [
      {
        keyPointTitle: 'Чемпион Европы',
        keyPointDescription: 'по стритлифтингу, Мастер Спорта по пауэрлифтингу, КМС по гиревому спорту',
      },
      {
        keyPointTitle: 'Собственная методика',
        keyPointDescription: 'по внедрению пищевых привычек и работе с РПП',
      },
      {
        keyPointTitle: 'Более 1000 человек',
        keyPointDescription: 'помог скорректировать фигуру',
      },
    ]
  },
  {
    image: images.expertsAndMentorsImage2,
    name: 'Татьяна Меловатская',
    description: 'Психотерапевт, психолог',
    experienceNote: '10 лет опыта',
    keyPoints: [
      {
        keyPointTitle: 'Адепт расширения мышления',
        keyPointDescription: 'Избавляю от панических атак за 2 консультации, а от дерматита за одну',
      },
      {
        keyPointTitle: '70+ клиентов',
        keyPointDescription: 'с позитивными переменами в жизни',
      },
      {
        keyPointTitle: '300 часов',
        keyPointDescription: 'личной терапии',
      },
    ]
  },
  {
    image: images.expertsAndMentorsImage3,
    name: 'Medlabel',
    description: 'Медицинский ассистанс №1 в России',
    experienceNote: '15 лет опыта',
    keyPoints: [
      {
        keyPointTitle: 'Команда профессионалов',
        keyPointDescription: 'Команда высококвалифицированных специалистов с обширным опытом работы.',
      },
      {
        keyPointTitle: '15+ лет',
        keyPointDescription: 'в организации медицинского обслуживания',
      },
      {
        keyPointTitle: '2500 клиник',
        keyPointDescription: 'по всей России',
      },
    ]
  },
]