import React, { ChangeEvent, useState } from 'react';
import { PulseLoader } from 'react-spinners'; // https://www.davidhu.io/react-spinners/
import {
  invalidPhoneNumberText,
} from '../../utils/appConstants';
import { baseColors } from '../../utils/themeConstants';
import CommonInlineStyles from '../CommonInlineStyles';
import RequestProcessedModal from '../RequestProcessedModal/RequestProcessedModal';
import { handleCallRequestTemp } from '../../utils/requestApi';
import { validatePhoneNumber } from '../../utils/helper';
import { ConsultCallData } from '../../utils/types';
import './RequestCallForm.scss';
import AppMaskedPhoneInput from '../AppMaskedPhoneInput/AppMaskedPhoneInput';
import PrivacyPolicyTextBlock from '../PrivacyPolicyTextBlock/PrivacyPolicyTextBlock';
import { yandexMetrics } from '../../utils/yandexMetrics';

const RequestCallForm = ({
  consultData,
  callMeButtonText,
  containerStyle,
}: {
  consultData: ConsultCallData;
  callMeButtonText: string;
  containerStyle?: React.CSSProperties;
}) => {
  const namePlaceholder = 'Ваше имя';

  const [fetching, setFetching] = useState(false);

  const [userName, setUserName] = useState('');
  const onUserNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    setUserName(e.target.value);
  };

  const [fieldReceivedInput, setFieldReceivedInput] = useState(false);

  const [userPhone, setUserPhone] = useState('');
  const [userPhoneValid, setUserPhoneValid] = useState(true);
  const onUserPhoneCheck = () => {
    const isValid = validatePhoneNumber(userPhone);
    setUserPhoneValid(isValid);
    return isValid;
  };

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [requestIsSuccessful, setRequestIsSuccessful] = useState<boolean>();
  const openModal = ({ requestSuccessful }: { requestSuccessful?: boolean }) => {
    setModalIsOpen(true);
    setRequestIsSuccessful(requestSuccessful);
  }

  const closeModal = () => setModalIsOpen(false);

  const onCallMeButtonClick = () => {
    const isValid = onUserPhoneCheck();
    if (!isValid) return;

    // start spinner
    setFetching(true);

    if (callMeButtonText === 'Подобрать программу') yandexMetrics.helpChooseProduct();
    else                                            yandexMetrics.phoneCallRequest();

    handleCallRequestTemp({
      userName: userName,
      userPhone: userPhone,
      programDevName: consultData.programDevName,
      durationInMonths: consultData.durationInMonths,
      tags: consultData.tags,
      openModal,
    }).then(() => {
      // stop spinner
      setFetching(false);
      setUserPhone('');
      setUserName('');
    });
  };

  const CallMeButton = ({ className, style }: { className?: string; style?: React.CSSProperties }) => {
    const disabled = fetching || !fieldReceivedInput || !userPhoneValid;
    return (
      <button
        onClick={onCallMeButtonClick}
        disabled={disabled}
        style={{ ...style, ...disabled ? CommonInlineStyles.buttonDisabled : undefined}}
        className={`app-button ${className}`}
      >
        {!fetching ? callMeButtonText : 'Обработка запроса'}
        {fetching && <PulseLoader color={baseColors.white} size={10} loading={true} />}
      </button>
    )
  }

  return (
    <div className="requestCallFormInputs" style={containerStyle}>
      <RequestProcessedModal
        modalIsOpen={modalIsOpen}
        closeModal={closeModal}
        requestIsSuccessful={requestIsSuccessful}
      />
      <input
        type={'text'}
        autoComplete={'name'}
        maxLength={100}
        placeholder={namePlaceholder}
        value={userName}
        onChange={onUserNameChange}
        className="input-field-dynamic-outline requestCallFormInputField"
      />
      <AppMaskedPhoneInput
        userPhone={userPhone}
        setUserPhone={setUserPhone}
        userPhoneValid={userPhoneValid}
        setUserPhoneValid={setUserPhoneValid}
        setFieldReceivedInput={setFieldReceivedInput}
        className="input-field-dynamic-outline requestCallFormInputField"
        style={{ marginTop: 8 }}
      />
      {!userPhoneValid && <div className="requestCallFormErrorText">{invalidPhoneNumberText}</div>}
      <CallMeButton style={{ marginTop: 16, padding: 18 }} />
      <PrivacyPolicyTextBlock
        className="requestCallFormPersonalDataNote"
        mainText={'Нажимая на эту кнопку вы даёте свое '}
      />
    </div>
  );
};

export default RequestCallForm;
