import { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { ClipLoader, PulseLoader } from 'react-spinners';
import { getCensoredEmailString } from '../../../utils/helper';
import images from '../../../utils/images';
import { OrderStatus, handlePaymentStatusRequest, handlePurchaseRetryRequest } from '../../../utils/requestApi';
import PurchaseProductModal from '../../../components/PurchaseProductModal/PurchaseProductModal';
import { baseColors, colors } from '../../../utils/themeConstants';
import CommonInlineStyles from '../../../components/CommonInlineStyles';
import ProductItem from '../../MainPage/components/5.Products/components/ProductItem';
import { PaymentProcessingOrderData, ProductData } from '../../../utils/types';
import { useOnReturnToMainPageButtonClick } from '../../../utils/hooks';
import { useDispatch, useSelector } from 'react-redux';
import { setBlockInterface } from '../../../redux/mainReducer';
import { RootState } from '../../../redux/store';
import { productItems } from '../../MainPage/components/5.Products/constants/products';
import './PaymentProcessingBlock.scss';
import { ProductDevName, TariffPlanDevName } from '../../MainPage/components/5.Products/constants/constants';
import { ProductType } from '../../../utils/enums';

const PaymentProcessingBlock = () => {
  const onReturnButtonClick = useOnReturnToMainPageButtonClick();

  const dispatch = useDispatch();
  const blockInterface = useSelector((state: RootState) => state.main.blockInterface);
  const onRetryButtonClick = () => {
    // start spinner and disable interface
    dispatch(setBlockInterface(true));

    handlePurchaseRetryRequest({
      orderId: orderId!,
      setBlockInterface: (b: boolean) => dispatch(setBlockInterface(b)),
    });
  }

  // ####################################################################################
  // use location object to get query parameters (order id)
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const orderId = searchParams.get('id');

  // use orderId to request order status 
  const [orderData, setOrderData] = useState<PaymentProcessingOrderData>({ orderStatus: OrderStatus.InProgress });
  const [orderStatusRequestIntervalId, setOrderStatusRequestIntervalId] = useState<NodeJS.Timer>();
  useEffect(() => {
    if (!orderId) return;
    
    // request payment processing status every 5 seconds
    const intervalId = setInterval(() => {
      handlePaymentStatusRequest({
        orderId: orderId!,
        setOrderData,
      })
    }, 5000);

    setOrderStatusRequestIntervalId(intervalId);
  
    return () => {
      clearInterval(intervalId);
    }
  }, []);

  // ####################################################################################
  // stop requests if status changes from "in progress" (to either success or error)
  useEffect(() => {
    if (orderData.orderStatus !== OrderStatus.InProgress) {
      clearInterval(orderStatusRequestIntervalId);
    }
  }, [orderData.orderStatus])

  // ####################################################################################
  const emailString = getCensoredEmailString(orderData.userEmail);

  const purchaseProductData = productItems.find(item => item.devName === orderData.productDevName);
  const { firstColumnTitleSuccess, firstColumnTextSuccess, firstColumnTitleError } = useMemo(() => {
    const defaultValue = {
      firstColumnTitleSuccess: 'Вы оплатили продукт',
      firstColumnTextSuccess: `Мы отправили письмо на почту ${emailString} со всей информацией.`,
      firstColumnTitleError: 'Продукт не оплачен',
    };

    if (!purchaseProductData) return defaultValue;
    switch(purchaseProductData.type) {
      case ProductType.TariffPlan: 
        return {
          firstColumnTitleSuccess: 'Вы оплатили программу',
          firstColumnTextSuccess: `Мы отправили письмо на почту ${emailString} со всей информацией. Наши специалисты свяжутся с вами в ближайшее время.`,
          firstColumnTitleError: 'Программа не оплачена',
        };
      case ProductType.Guide:
        return {
          firstColumnTitleSuccess: 'Вы оплатили гайд',
          firstColumnTextSuccess: `Мы отправили письмо на почту ${emailString} со всей информацией.`,
          firstColumnTitleError: 'Гайд не оплачен',
        };
      default: return defaultValue;
    }
  }, [purchaseProductData]);

  const returnToMainPageText = 'Вернуться на главную';
  const getBlockData = (orderStatus: OrderStatus | string) => {
    switch (orderStatus) {
      case OrderStatus.Success:
        return {
          statusImage: images.modalSuccess,
          statusImageClass: 'paymentProcessingStatusIconSuccess',
          firstColumnTitle: firstColumnTitleSuccess,
          firstColumnText: firstColumnTextSuccess,
          buttonTitle: returnToMainPageText,
          onButtonClick: onReturnButtonClick,
          secondColumnTitle: 'Также вам подходит',
        }

      case OrderStatus.InProgress:
        return null;
    
      // not success/waiting = error
      default:
        return {
          statusImage: images.modalError,
          statusImageClass: 'paymentProcessingStatusIconFailure',
          firstColumnTitle: firstColumnTitleError,
          firstColumnText: `Пожалуйста, обратитесь в свой банк или попробуйте выбрать другой способ оплаты.`,
          buttonTitle: 'Попробовать еще раз',
          onButtonClick: onRetryButtonClick,
          showReturnUrl: true,
          secondColumnTitle: 'Может быть интересно',
        }
    }
  }
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const openPurchaseProductModal = () => setModalIsOpen(true);
  const closePurchaseProductModal = () => setModalIsOpen(false);

  const [productData, setProductData] = useState<ProductData>();
  const [selectedVariantId, setSelectedVariantId] = useState<number>();
  const onPurchaseClick = (item: ProductData, variantId: number) => {
    setProductData(item);
    setSelectedVariantId(variantId);
    openPurchaseProductModal();
  }

  // ####################################################################################
  const suggestedTariffPlansBaseList: string[] = [
    ProductDevName.medconcierge,
    ProductDevName.liteHealthDiet,
    ProductDevName.liteHealthDietSport,
  ];
  
  const getSuggestedTariffPlans = (selectedProduct?: string) => {
    const output: string[] = [];
    if (selectedProduct && suggestedTariffPlansBaseList.includes(selectedProduct)) {
      output.push(...suggestedTariffPlansBaseList.filter(item => item !== selectedProduct));
    } else {
      output.push(TariffPlanDevName.medconcierge, TariffPlanDevName.liteHealthDietSport);
    }
    
    return output;
  }

  const suggestedTariffPlans = getSuggestedTariffPlans(orderData.productDevName);

  // ####################################################################################
  const displayData = getBlockData(orderData.orderStatus);
  
  if (!displayData) {
    const inProgressTitle = 'Ожидание подтверждения оплаты';
    const inProgressText = 'Ваша оплата обрабатывается, это может занять несколько минут';

    return (
      <div className="paymentProcessingInProgressContainer">
        <div className="paymentProcessingInProgressContainerInner">
          <ClipLoader color={colors.accent} speedMultiplier={0.25} />
          <div className="paymentProcessingInProgressTitle">{inProgressTitle}</div>
          <div className="paymentProcessingInProgressText">{inProgressText}</div>
        </div>
      </div>
    )
  }

  return (
    <div className="paymentProcessingContainer">
      <PurchaseProductModal
        productData={productData!}
        selectedVariantId={selectedVariantId!}
        selectedTags={[]}
        modalIsOpen={modalIsOpen}
        closeModal={closePurchaseProductModal}
      />
      <div className="paymentProcessingMainSectionContainer">
        <div className="paymentProcessingMainSectionContainerInner">
          <img src={displayData.statusImage} alt="" className={displayData.statusImageClass} />
          <div className="paymentProcessingMainSectionTitle">{displayData.firstColumnTitle}</div>
          <div className="paymentProcessingMainSectionText">{displayData.firstColumnText}</div>
          <button
            onClick={displayData.onButtonClick}
            className="app-button paymentProcessingButton"
            style={blockInterface ? CommonInlineStyles.buttonDisabled : undefined}
          >
            {!blockInterface ? displayData.buttonTitle : 'Обработка запроса'}
            {blockInterface && <PulseLoader color={baseColors.white} size={10} loading={true} />}
          </button>
          {displayData.showReturnUrl && (
            <div className="paymentProcessingUnderButtonLink link opacity-on-hover" onClick={onReturnButtonClick}>
              {returnToMainPageText}
            </div>
          )}
        </div>
      </div>
      <div className="paymentProcessingSuggestedSectionContainer" style={{ backgroundImage: images.successfulPaymentSecondColumnBackground }}>
        <div className="paymentProcessingSuggestedSectionTitle">{displayData.secondColumnTitle}</div>
        <div className="paymentProcessingSuggestedSectionContainerInner">
          {suggestedTariffPlans.map((devName) => (
            <ProductItem
              key={devName}
              productData={productItems.find(item => item.devName === devName)!}
              onPurchaseClick={onPurchaseClick}
              onMoveUserToConsult={() => {}}
              useMobileDesign={false}
              usePaymentProcessingVariant
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default PaymentProcessingBlock;
