import React from "react";
import { ProductCounterTitle, ProductType, ProductTag } from "./enums";
import { SubService, ProductService } from "../pages/MainPage/components/5.Products/constants/constants";
import { OrderStatus } from "./requestApi";

type CSSProperties = {
  [key:string]: React.CSSProperties;
};

export class StyleSheet {
  static create<Styles extends CSSProperties>(styles: Styles): Styles {
    return styles;
  };
};

export interface SocialMediaButtonData {
  icon: string;
  hoverIcon: string;
  href: string;
}

export interface MenuItem {
  text: string;
  onClick: () => void;
}

export interface DimensionsData {
  screenSize: { width: number; height: number; }
  isVerticallyOriented: boolean;
}



export interface ConsultCallData {
  programDevName: string;
  durationInMonths: number | null;
  tags: string[];
}


// request data types
export interface PaymentProcessingOrderData {
  orderStatus: OrderStatus;
  productDevName?: string;
  productDurationInMonths?: number;
  userEmail?: string;
}

// products information
export interface IncludedServiceItem {
  optionName: ProductService;
  note?: string;
  altDescription?: string;
}

export interface ServiceDetails {
  subServiceName: SubService | ProductService;
  serviceDescriptions: string[];
  image: string;
}

export interface CounterData {
  title: ProductCounterTitle;
  count: number;
}

export interface ServiceData {
  title: string;
  titleShort?: string;
  serviceDescriptions: string[];
  image: string;
}

export interface ProductData {
  type: ProductType;
  devName: string;
  productRoute: string;
  prefix: string;
  cardTitle?: string;
  title: string;
  mainPageDescription?: string;
  variants: {
    price: string;
    durationText: string;
    durationInMonths: number | null;
    includesArray: IncludedServiceItem[];
    bonuses?: IncludedServiceItem[];
    customServicesArray?: ServiceData[];
  }[],
  titleBackground: string;
  descriptionPageBackground: string;
  descriptionPageBackgroundMobile: string;
  descriptionPageBackgroundStyleMobile: React.CSSProperties;
  tags: ProductTag[];

  descriptionShort: string;
  descriptionComponents: (() => JSX.Element)[];
  countersData: CounterData[];
}