import React, { useEffect } from 'react';
import images from '../../utils/images';
import LogoBlock from '../LogoBlock/LogoBlock';
import { MenuItem } from '../../utils/types';
import './Header.scss';
import { socialMediaButtons } from '../../utils/appConstants';
import SocialMediaButton from '../SocialMediaButton/SocialMediaButton';

const HeaderMobileMenuModal = ({
  closeModal,
  scrollToConsultSection,
  companyMail,
  companyMailHref,
  companyPhone,
  companyPhoneHref,
  menuItems,
}: {
  closeModal: () => void;
  scrollToConsultSection: () => void;
  companyMail: string;
  companyMailHref: string;
  companyPhone: string;
  companyPhoneHref: string;
  menuItems: MenuItem[];
}) => {
  useEffect(() => {
    document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.overflow = 'unset';
    };
  }, []);

  const onMenuItemClick = (menuItem: MenuItem) => {
    closeModal();
    menuItem.onClick();
  };

  return (
    <div className="headerMenuModalContainer">
      <div className="headerContainer" style={{ padding: 0 }}>
        <LogoBlock height={40} textWidth={61} isVertical isClickable={true} />
        <div className="headerMenuButton opacity-on-hover" onClick={closeModal}>
          <img src={images.headerMobileMenuIconClose} className="headerMenuButtonIconClose" />
        </div>
      </div>
      <div className="headerMenuModalMainBlock">
        <a className="headerMenuCompanyPhone link opacity-on-hover" href={companyPhoneHref}>
          {companyPhone}
        </a>
        <a className="headerMenuEmail link opacity-on-hover" href={companyMailHref}>
          {companyMail}
        </a>
        <button onClick={scrollToConsultSection} className="headerConsultButtonMobile">
          {'Перезвоните мне'}
        </button>
        <div className="headerMenuItemsBlock">
          {menuItems.map((item) => (
            <div
              className="headerMenuItem link opacity-on-hover"
              onClick={() => onMenuItemClick(item)}
              key={item.text}
            >
              {item.text}
            </div>
          ))}
          <div className="headerSocialMediaButtonsContainerMobile">
            {socialMediaButtons.map((item) => (
              <SocialMediaButton item={item} key={item.href} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeaderMobileMenuModal;
