import { ChangeEvent, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PulseLoader } from 'react-spinners';
import AppCheckbox from '../../../../../components/AppCheckbox/AppCheckbox';
import CommonInlineStyles from '../../../../../components/CommonInlineStyles';
import { setBlockInterface, setFreeGuideEmail } from '../../../../../redux/mainReducer';
import { RootState } from '../../../../../redux/store';
import { invalidEmailText, invalidPhoneNumberText } from '../../../../../utils/appConstants';
import { validateEmail, validatePhoneNumber } from '../../../../../utils/helper';
import { useDisableScroll } from '../../../../../utils/hooks';
import images from '../../../../../utils/images';
import { baseColors } from '../../../../../utils/themeConstants';
import PrivacyPolicyTextBlock from '../../../../../components/PrivacyPolicyTextBlock/PrivacyPolicyTextBlock';
import './GetFreeGuideModal.scss';
import { getFreeGuideRequest } from '../tempRequest';
import { useNavigate } from 'react-router-dom';
import { PagePath } from '../../../../../utils/enums';
import AppMaskedPhoneInput from '../../../../../components/AppMaskedPhoneInput/AppMaskedPhoneInput';
import { yandexMetrics } from '../../../../../utils/yandexMetrics';

const GetFreeGuideModal = ({
  modalIsOpen,
  closeModal,
}: {
  modalIsOpen: boolean;
  closeModal: () => void;
}) => {
  useDisableScroll(modalIsOpen);

  const [privacyPolicyChecked, setPrivacyPolicyChecked] = useState(true);
  const [userCredentials, setUserCredentials] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [userEmailValid, setUserEmailValid] = useState(true);
  const [fieldReceivedInput, setFieldReceivedInput] = useState(false);
  
  const onUserEmailFocus = () => {
    if (!userEmailValid) {
      setUserEmailValid(true);
      setFieldReceivedInput(false);
    }
  };

  const onUserEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
    const input = e.target.value;
    setUserEmail(input);
    setFieldReceivedInput(true);
  };


  const onUserEmailCheck = () => {
    const isValid = validateEmail(userEmail);
    setUserEmailValid(isValid);
    return isValid;
  };

  const [userPhone, setUserPhone] = useState('');
  const [userPhoneValid, setUserPhoneValid] = useState(true);
  const onUserPhoneCheck = () => {
    const isValid = validatePhoneNumber(userPhone);
    setUserPhoneValid(isValid);
    return isValid;
  };
  
  const userAgreedToTerms = privacyPolicyChecked;
  const getGuideButtonEnabled =
    fieldReceivedInput &&
    userAgreedToTerms &&
    !!userPhone &&
    userPhone !== '7' &&
    userPhoneValid &&
    !!userEmail &&
    userEmailValid &&
    !!userCredentials;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  
  const blockInterface = useSelector((state: RootState) => state.main.blockInterface);
  const [requestSuccessful, setRequestSuccessful] = useState(true);

  const onPurchaseClickModal = async () => {
    const userEmailIsValid = onUserEmailCheck();
    const userPhoneIsValid = onUserPhoneCheck();
    if (!userEmailIsValid || !userPhoneIsValid) return;

    if (!requestSuccessful) setRequestSuccessful(true);

    // start spinner and disable interface
    dispatch(setBlockInterface(true));

    yandexMetrics.getFreeGuideRequest();

    const result = await getFreeGuideRequest({
      userCredentials,
      userEmail,
      userPhone,
      setBlockInterface: (b: boolean) => dispatch(setBlockInterface(b)),
    });

    if (requestSuccessful !== result) setRequestSuccessful(result);

    if (result === true) {
      dispatch(setFreeGuideEmail(userEmail));
      navigate(PagePath.SentFreeGuidePage);
    }
  };

  if (!modalIsOpen) return null;

  return (
    <div className="getFreeGuideModalOverlay">
      <div
        className="getFreeGuideModalContainer"
        style={{ backgroundImage: `url(${images.getFreeGuideModalBackground})` }}
      >
        <div className="getFreeGuideModalContainerInner">

          <button disabled={blockInterface} className="getFreeGuideModalCloseButton" onClick={closeModal}>
            <img src={images.modalCross} className="getFreeGuideModalCrossIcon" alt="Закрыть" />
          </button>



          <div className="getFreeGuideModalInputsColumn">
            <div className="getFreeGuideModalInputsTitle">{'Бесплатный гайд:\r\n«Хочу в ПП. С чего начать?»'}</div>
            <div className="getFreeGuideModalInputsNote">{'Мы вышлем гайд на указанную почту'}</div>
            <div className="getFreeGuideModalInputsInnerColumnTitle">{'Введите данные:'}</div>

            <input
              type={'text'}
              placeholder={'Ваше имя'}
              autoComplete={'name'}
              maxLength={100}
              value={userCredentials}
              onChange={(e) => setUserCredentials(e.target.value)}
              className="input-field-dynamic-outline getFreeGuideModalInputsInnerColumnInput"
            />

            <input
              type={'text'}
              placeholder={'E-mail'}
              autoComplete={'email'}
              maxLength={100}
              autoCapitalize={'none'}
              autoCorrect={''}
              value={userEmail}
              onFocus={onUserEmailFocus}
              onChange={onUserEmailChange}
              className="input-field-dynamic-outline getFreeGuideModalInputsInnerColumnInput"
              style={!userEmailValid ? CommonInlineStyles.inputFieldErrorBorder : {}}
            />

            {!userEmailValid && <div className="getFreeGuideModalInputsErrorText">{invalidEmailText}</div>}

            <AppMaskedPhoneInput
              userPhone={userPhone}
              setUserPhone={setUserPhone}
              userPhoneValid={userPhoneValid}
              setUserPhoneValid={setUserPhoneValid}
              setFieldReceivedInput={setFieldReceivedInput}
              className="input-field-dynamic-outline getFreeGuideModalInputsInnerColumnInput"
              style={!userPhoneValid ? CommonInlineStyles.inputFieldErrorBorder : {}}
            />

            {!userPhoneValid && <div className="getFreeGuideModalInputsErrorText">{invalidPhoneNumberText}</div>}

            <button
              onClick={onPurchaseClickModal}
              disabled={blockInterface || !getGuideButtonEnabled}
              className="getFreeGuideModalInputsInnerColumnPurchaseButton"
              style={blockInterface || !getGuideButtonEnabled ? CommonInlineStyles.buttonDisabled : undefined}
            >
              {!blockInterface ? 'Получить бесплатно' : 'Обработка запроса'}
              {blockInterface && <PulseLoader color={baseColors.white} size={10} loading={true} />}
            </button>

            {!requestSuccessful && (
              <div className="requestErrorText">{'Ошибка сервера. Повторите попытку позже'}</div>
            )}

            <div className="getFreeGuideModalInputsInnerColumnCheckboxesContainer">
              <div className="getFreeGuideModalInputsInnerColumnCheckboxItemContainer">
                <AppCheckbox isChecked={privacyPolicyChecked} setCheckBoxValue={setPrivacyPolicyChecked} />
                <PrivacyPolicyTextBlock className="getFreeGuideModalInputsInnerColumnCheckboxItemText" />
              </div>
            </div>


          </div>
        </div>
      </div>
    </div>
  );
};

export default GetFreeGuideModal;
