import React from 'react';
import './ReviewItem.scss';
import images from '../../../../../utils/images';

export interface ReviewItemData {
  productName: string;
  reviewText: string;
  reviewerName: string;
  reviewerAgeAndLocation: string;
  reviewerImageSrc: string;
}

const ReviewItem = ({
  reviewItemData,
  useMobileDesign,
}: {
  reviewItemData: ReviewItemData;
  useMobileDesign: boolean;
}) => {
  const { productName, reviewText, reviewerName, reviewerAgeAndLocation, reviewerImageSrc } = reviewItemData;

  const backgroundImageUrl = useMobileDesign ? images.reviewBackgroundMobile : images.reviewBackground;

  return (
    <div className="reviewItemContainer">
      <div className="reviewItemMainBlock">
        <div
          className="reviewItemMainBlockBackgroundImage"
          style={{ backgroundImage: `url(${backgroundImageUrl})` }}
        >
          <div className="reviewItemProductName">{productName}</div>
          <div className="reviewItemReviewText">{reviewText}</div>
        </div>
      </div>
      <div className="reviewItemReviewerInfoBlock">
        <img className="reviewItemReviewerImage" src={reviewerImageSrc} />
        <div className="reviewItemReviewerDetails">
          <div className="reviewItemReviewerName">{reviewerName}</div>
          <div className="reviewItemReviewerAgeAndLocation">{reviewerAgeAndLocation}</div>
        </div>
      </div>
    </div>
  );
};

export default ReviewItem;
