import { useState } from 'react';
import { ProductDevName } from '../constants/constants';
import { ProductData } from '../../../../../utils/types';
import images from '../../../../../utils/images';
import { ProductType, SectionName } from '../../../../../utils/enums';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setInitialProductVariantId, setSectionToReturnTo } from '../../../../../redux/mainReducer';
import { getItemDescriptionNavLink } from '../../../../../utils/helper';
import './ProductItem.scss';
import { yandexMetrics } from '../../../../../utils/yandexMetrics';

const ProductItem = ({
  productData,
  onMoveUserToConsult,
  onPurchaseClick,
  useMobileDesign,
  usePaymentProcessingVariant,
  showDetailsButton = false,
}: {
  productData: ProductData;
  onMoveUserToConsult: (productItem: ProductData, selectedVariantId: number, sectionName: SectionName) => void;
  onPurchaseClick: (productItem: ProductData, selectedVariantId: number) => void;
  useMobileDesign: boolean;
  showDetailsButton?: boolean;
  usePaymentProcessingVariant?: boolean;
}) => {
  const [selectedVariantId, setSelectedVariantId] = useState(0);
  const currentData = productData.variants[selectedVariantId];
  
  const mobileStyles: React.CSSProperties = { maxWidth: 'calc(100% - 32px)' };
  
  const containerStyle: React.CSSProperties = {
    ...useMobileDesign && mobileStyles,
    ...usePaymentProcessingVariant && { minHeight: 400 },
  }

  // custom logic for personal program
  const isPersonalProgram = productData.devName === ProductDevName.personalProgram;
  const onPurchaseButtonClick = () => {
    if (isPersonalProgram) {
      onMoveUserToConsult(productData, selectedVariantId, SectionName.ConsultSection2);
    } else {
      onPurchaseClick(productData, selectedVariantId);
    }
  }

  const moveUserToFirstConsultSection = () => {
    onMoveUserToConsult(productData, selectedVariantId, SectionName.ConsultSection1);
  }
  const itemDescriptionPageNavLink = getItemDescriptionNavLink(productData.productRoute);

  const dispatch = useDispatch();
  const onNavigateToProductDescriptionPage = () => {
    console.log(`onNavigateToProductDescriptionPage`);
    yandexMetrics.productDetailsClick()
    dispatch(setInitialProductVariantId(selectedVariantId));
    dispatch(setSectionToReturnTo(SectionName.ServicesList));
  }

  const getPrefix = (product: ProductData) => {
    switch (product.type) {
      case ProductType.TariffPlan:
        return `Программа ${productData.prefix}`;

      default:
        return productData.prefix;
    }
  }

  return (
    <div className="productItemContainer" style={containerStyle}>
      <div>
        <div className="productItemTitlePrefix">{getPrefix(productData)}</div>
        <div className="productItemTitle" style={{ backgroundImage: `url(${productData.titleBackground})` }}>
          {productData.cardTitle || productData.title}
        </div>
        <div className="productItemDurationButtonsBlock">
          {productData.variants.map((variant, index) => (
            <button
              key={variant.durationText}
              onClick={productData.variants.length > 1 ? () => setSelectedVariantId(index) : undefined}
              className={
                index === selectedVariantId ? 'productItemDurationButtonSelected' : 'productItemDurationButton'
              }
              style={{ ...(!variant.durationText && { display: 'none' }) }}
            >
              {variant.durationText}
            </button>
          ))}
        </div>
        {productData.type === ProductType.Guide && <div className="productItemTextDescription">
          {productData.descriptionShort}
        </div>}
        <div className="productItemIncludedBlock">
          {currentData.includesArray.map((includedItem) => (
            <div className="productItemIncludedItem" key={`${includedItem.optionName}${includedItem.note}`}>
              <div className="productItemIncludedItemText">{includedItem.optionName}</div>
              <div className="productItemIncludedItemText" style={{ whiteSpace: 'nowrap' }}>
                {includedItem.note}
              </div>
            </div>
          ))}
        </div>
        <div className="productItemIncludedBonusesBlock">
          {currentData.bonuses?.map((bonusItem) => (
            <div className="productItemIncludedBonusItem" key={`${bonusItem.optionName}${bonusItem.note}`}>
              <img className="productItemIncludedBonusItemIcon" src={images.servicesGiftIcon} />
              <div className="productItemIncludedItemText">{bonusItem.optionName}</div>
              <div className="productItemIncludedItemText" style={{ whiteSpace: 'nowrap' }}>
                {bonusItem.note}
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="productItemColumn">
        <div className="productItemPrice">{`${currentData.price}`}</div>

        <a onClick={onPurchaseButtonClick} className="productItemPurchaseButton">
          {'Купить'}
        </a>

        {showDetailsButton && isPersonalProgram ? (
          <button
            onClick={moveUserToFirstConsultSection}
            className="productItemDetailsButton"
            style={{ width: '100%' }}
          >
            {'Подробнее'}
          </button>
        ) : (
          <Link
            to={itemDescriptionPageNavLink}
            onClick={onNavigateToProductDescriptionPage}
            className="productItemDetailsButton"
          >
            {'Подробнее'}
          </Link>
        )}
      </div>
    </div>
  );
};

export default ProductItem;
