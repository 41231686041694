import { baseUrl } from "../../../../utils/requestApi";

export const getFreeGuideRequest = async ({
  userCredentials,
  userEmail,
  userPhone,
  setBlockInterface,
}: {
  userCredentials: string;
  userEmail: string;
  userPhone: string;
  setBlockInterface: (b: boolean) => void;
}) => {
  const userPhoneFormatted = `+${userPhone.replaceAll(/\D/g, '')}`;

  const url = `${baseUrl}/v1/graphql`;
  const query = `mutation {
    mobileGetFreeGuide(arg: { email: "${userEmail}", phone: "${userPhoneFormatted}", full_name: "${userCredentials}", program_name: "Бесплатный гайд" }) {
      error
      status
    }
  }`;

  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({query}),
      signal: AbortSignal.timeout(15000),
    })

    const data = await response.json();
    console.log(`data = ${JSON.stringify(data)}`)

    const responseData = (data as any)?.data?.mobileGetFreeGuide;
    const requestStatus = responseData?.status;

    console.log(`getFreeGuideRequest requestStatus = ${requestStatus}`);
    return requestStatus === 'ok';
  } catch (error) {
    console.log(`getFreeGuideRequest error: ${JSON.stringify(error)}\r\n${(error as any).message}`);
    return false;
  } finally {
    setBlockInterface(false);
  }
}