import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { setSectionToScrollTo } from "../redux/mainReducer";
import { SectionName, PagePath } from "./enums";
import { getProductTitle, scrollToTop } from "./helper";
import { learnMoreDataArray } from "../pages/ValuesPage/ValuesPage";
import { productItems } from "../pages/MainPage/components/5.Products/constants/products";
import { productRoutes } from "..";

export const useSetTitle = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  let targetTitle = 'ETNA';

  switch (location.pathname) {
    case PagePath.MainPage:
      break;

    case PagePath.OrderStatusPage:
      targetTitle = 'ETNA — Статус заказа';
      break;

    case PagePath.ValuesPage:
      targetTitle = 'ETNA — Ценности';
      const valueId = parseInt(searchParams.get('id')!) || 0;
      const learnMoreData = learnMoreDataArray[valueId];
      if (!learnMoreData) break;
      targetTitle = learnMoreData.titleShort;
      break;

    default:
      if (productRoutes.includes(location.pathname)) {
        targetTitle = 'ETNA — Описание тарифа';
        const productRouteName = location.pathname;
        const productData = productItems.find(item => item.productRoute === productRouteName);
        if (!productData) break;
        targetTitle = getProductTitle(productData);
      }
      break;
  }

  document.title = targetTitle;
}

export const useScrollToTop = ({smooth = true }: { smooth?: boolean; }) => {
  useEffect(() => {
    scrollToTop(smooth);
  }, []);
}

export const useOnReturnToMainPageButtonClick = (sectionName?: SectionName | null) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const onReturnButtonClick = () => {
    if (sectionName) {
      dispatch(setSectionToScrollTo(sectionName));
    } else {
      scrollToTop();
    }
    
    navigate(PagePath.MainPage);
  }
  return onReturnButtonClick;
}

export const useDisableScroll = (modalIsOpen: boolean) => {
  const scrollbarWidth = window.innerWidth - document.body.offsetWidth;

  useEffect(() => {
    const defaultOverflowValue = 'unset';
    document.body.style.overflowY = modalIsOpen ? 'hidden' : defaultOverflowValue;
    document.documentElement.style.overflowY = modalIsOpen ? 'hidden' : defaultOverflowValue;

    const appContainer = document.getElementsByClassName('appContainer')[0] as HTMLElement;
    const targetComponent = appContainer;
    
    if (targetComponent) {
      targetComponent.style.paddingRight = modalIsOpen ? `${scrollbarWidth * 2}px` : '0px';
      targetComponent.style.marginRight = modalIsOpen ? `-${scrollbarWidth}px` : '0px';
    }

    return () => {
      document.body.style.overflowY = defaultOverflowValue;
      document.documentElement.style.overflowY = defaultOverflowValue;

      if (targetComponent) {
        targetComponent.style.paddingRight = '0px';
        targetComponent.style.marginRight = '0px';
      }
    };
  }, [modalIsOpen]);
};

export const useScreenSize = () => {
  const [isVerticallyOriented, setIsVerticallyOriented] = useState(window.innerHeight >= window.innerWidth);

  const [screenSize, setScreenSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const handleResize = () => {
      setScreenSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });

      setIsVerticallyOriented(window.innerHeight > window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return { screenSize, isVerticallyOriented };
};