import { PaymentProcessingOrderData, ProductData } from "./types";

export const isProd = process.env.REACT_APP_ENV === 'prod';
export const baseUrl = isProd ? 'https://api.etnamed.ru' : 'https://dev.api.etnamed.ru';

export enum OrderStatus {
  InProgress = 'wait',
  Success = 'paid',
  Error = 'error',
}

export const handlePaymentStatusRequest = async ({
  orderId,
  setOrderData,
}: {
  orderId: string;
  setOrderData: (updatedOrderData: PaymentProcessingOrderData) => void;
}) => {
  const requestBody = {
    order_id: orderId,
  };

  interface ResponseData {
    mobileGetStatusMedicalConciergeBilling: {
      email: string;
      status: OrderStatus;
      error: string;
      program_name: string;
      period: string;
    }
  }

  const url = `${baseUrl}/api/rest/mobile_get_status_medical_concierge_billing`;
  await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(requestBody),
  })
    .then(async (response) => {
      // request resolved
      const data = await response.json();
      const responseData = (data as ResponseData).mobileGetStatusMedicalConciergeBilling;

      const orderData: PaymentProcessingOrderData = {
        orderStatus: OrderStatus.Error,
      }

      if (responseData) {
        orderData.orderStatus = responseData.status;

        switch(responseData.status) {
          case OrderStatus.Success:
            orderData.productDevName = responseData.program_name;
            orderData.productDurationInMonths = parseInt(responseData.period);
            orderData.userEmail = responseData.email;
            break;
          
          case OrderStatus.Error:
            orderData.orderStatus = OrderStatus.Error;
            console.warn(`Error in handlePaymentStatusRequest: ${responseData.error}`);
            break;
          
          case null:
            orderData.orderStatus = OrderStatus.Error;
            console.warn(`Error in handlePaymentStatusRequest: ${responseData.error}`);
            break;
        }
      } else {
        orderData.orderStatus = OrderStatus.Error;
      }

      setOrderData(orderData);

    })
};



export const handlePurchaseRequest = async ({
  userCredentials,
  userPhone,
  userEmail,
  userComment,
  tags,
  productData,
  selectedVariantId,
  setBlockInterface,
  setRequestReturnedError,
}: {
  userCredentials: string;
  userPhone: string;
  userEmail: string;
  userComment: string;
  tags: string[];
  productData: ProductData;
  selectedVariantId: number;
  setBlockInterface: (b: boolean) => void;
  setRequestReturnedError: (b: boolean) => void;
}) => {
  const userPhoneFormatted = `+${userPhone.replaceAll(/\D/g, '')}`;

  const productDevName = productData.devName;

  const selectedVariantData = productData.variants[selectedVariantId];
  const durationInMonths = selectedVariantData.durationInMonths;
  
  const formattedTags: { tag: string }[] = tags.map(tag => ({ tag: tag }));

  const requestBody = {
    phone: userPhoneFormatted, // "+7964935xxxx",
    fio: userCredentials,            // "Alexander",
    program_name: productDevName,    // "Программа Персональная",
    email: userEmail,                // "test@test.ru",
    comment: userComment || null,    // 
    period: durationInMonths,        // 1,
    tags: formattedTags,             // [{"tag": "Сложные случаи"},{"tag": "Мужчина"},{"tag": "25–35 лет"}]
  };

  interface PurchaseRequestResponseData {
    mobilePurchasingMedicalConcierge: {
      status: boolean;
      payment_link: string;
      error: string;
    }
  }

  const url = `${baseUrl}/api/rest/mobile_purchasing_medical_concierge`;
  await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(requestBody),
  })
    .then(async (response) => {
      // request resolved
      const data = await response.json();
      const responseData = (data as PurchaseRequestResponseData)?.mobilePurchasingMedicalConcierge;

      // process errors
      if (!responseData) {
        console.warn('handlePurchaseRequest responseData is undefined/null');
        setRequestReturnedError(true);
        setBlockInterface(false);
        return;
      }
      if (responseData.status === false) {
        console.warn('handlePurchaseRequest status === false');
        setRequestReturnedError(true);
        setBlockInterface(false);
        return;
      }

      if (responseData?.status === true) {
        const paymentUrl = responseData.payment_link;
        window.location.href = paymentUrl;
        return;

        //after payment is complete user gets redirected back to:
        // https://dev.wellness.etna.global/order?id=...
      }

      setBlockInterface(false);
    })
};



export const handlePurchaseRetryRequest = async ({
  orderId,
  setBlockInterface,
}: {
  orderId: string;
  setBlockInterface: (b: boolean) => void;
}) => {
  const requestBody = {
    order_id: orderId,
  };

  interface PurchaseRequestResponseData {
    mobilePurchasingMedicalConciergeRetry: {
      status: boolean;
      payment_link: string;
      error: string;
    }
  }

  const url = `${baseUrl}/api/rest/mobile_purchasing_medical_concierge_retry`;
  await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(requestBody),
  })
    .then(async (response) => {
      // request resolved
      const data = await response.json();
      const responseData = (data as PurchaseRequestResponseData).mobilePurchasingMedicalConciergeRetry;

      if (responseData.status === true) {
        setBlockInterface(true);
        const paymentUrl = responseData.payment_link;
        window.location.href = paymentUrl;
        return;

        //after payment is complete user gets redirected back to:
        // https://dev.wellness.etna.global/order?id=...
      }

      // process errors
      if (responseData.status === false) {
        console.warn('handlePurchaseRetryRequest status === false');
      }
      if (!responseData) {
        console.warn('handlePurchaseRetryRequest responseData is undefined/null');
      }
      setBlockInterface(false);
    });
};



export const handleCallRequest = async ({
  userName,
  userPhone,
  programDevName,
  durationInMonths,
  tags,
  openModal,
}: {
  userName: string;
  userPhone: string;
  programDevName: string;
  durationInMonths: number | null;
  tags: string[];
  openModal: () => void;
}) => {
  const userPhoneFormatted = userPhone.replaceAll(/\D/g, '');

  const formattedTags: { tag: string }[] = tags.map(tag => ({ tag: tag }));

  const requestBody = {
    user_name: userName || null,
    email_or_phone: `+${userPhoneFormatted}`,
    program_name: programDevName,
    period: durationInMonths,
    tags: formattedTags,
  };

  const url = `${baseUrl}/api/rest/mobile_amocrm_create_lead`;
  await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(requestBody),
  })
    .then(() => {
      // request resolved
      openModal();
    })
};



// temp call request before introducing graphql/apollo
export const handleCallRequestTemp = async ({
  userName,
  userPhone,
  programDevName,
  durationInMonths,
  tags,
  openModal,
}: {
  userName: string;
  userPhone: string;
  programDevName: string;
  durationInMonths: number | null;
  tags: string[];
  openModal: ({ requestSuccessful }: { requestSuccessful?: boolean }) => void;
}) => {
  const userPhoneFormatted = userPhone.replaceAll(/\D/g, '');

  const formattedTags: { tag: string }[] = tags.map(tag => ({ tag: tag }));

  const requestBody = {
    user_name: userName || null,
    email_or_phone: `+${userPhoneFormatted}`,
    program_name: programDevName,
    period: durationInMonths,
    tags: formattedTags,
  };

  interface Response {
    mobileAmocrmCreateLead?: {
      status: boolean;
      error?: string;
    }
    error?: string;
    path?: string;
    code?: string;
  }

  const url = `${baseUrl}/api/rest/mobile_amocrm_create_lead`;
  await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(requestBody),
  })
    .then(async (response) => {
      // request resolved
      const data = await response.json();
      const requestSuccessful = (data as Response).mobileAmocrmCreateLead?.status;
      openModal({ requestSuccessful: requestSuccessful });
    });
};