import { ProductType, ProductCounterTitle, ProductTag } from "../../../../../utils/enums";
import images from "../../../../../utils/images";
import { ProductData } from "../../../../../utils/types";
import { GuideDevName } from "./constants";
import GuideTextBlocks from "./guideTextBlocks";
import { commonTags } from "./tags";

const commonGuideTags: ProductTag[] = [
  ProductTag.psychAssistance,
  ProductTag.noEnergy,
  ProductTag.anger,
  ProductTag.depression,
  ProductTag.apathy,
]

const guides: ProductData[] = [
  {
    type: ProductType.Guide,
    devName: GuideDevName.stressImportantMoments,
    productRoute: '/gayd-stress-vazhnyye-momenty',
    prefix: 'Гайд',
    cardTitle: 'Стресс.\r\nВажные моменты',
    title: 'Стресс. Важные моменты',
    descriptionShort: 'Этот гайд представляет собой глубокое погружение в мир стресса, помогая понять его опасности и последствия. Содержание включает рассмотрение причин стресса, эмоционального подавления, а также эффективные стратегии преодоления этого состояния. Это практическое руководство поможет вам обрести новые инсайты и навыки для эффективного управления своим стрессом.',
    descriptionComponents: [GuideTextBlocks.Guide7TextBlock],
    variants: [
      {
        price: '2 990 ₽',
        durationText: '',
        durationInMonths: 0,
        includesArray: [],
        customServicesArray: [{
          title: 'Гайд на 15 страниц в формате pdf',
          titleShort: 'Гайд',
          serviceDescriptions: [
            'Чем опасен стресс? ',
            'Почему плохо гасить эмоции?',
            'Подавление эмоций. ',
            'Что «нельзя» делать в периоды стресса? ',
            'Как справиться с этим состоянием? ',
            'Почему, испытывая стресс, многие люди хотят есть больше? ',
            'Может есть какие-то техники/установки? ',
            'Можно ли «заедать» стресс?',
            'Из личного опыта.',
          ],
          image: images.guideDescriptionCardBackground7,
        }],
      },
    ],
    
    titleBackground: images.servicesLine1,
    descriptionPageBackground: images.guideDescriptionBackground7,
    descriptionPageBackgroundMobile: images.guideDescriptionBackgroundMobile7,
    descriptionPageBackgroundStyleMobile: { backgroundPositionX: '70%' },

    countersData: [
      { title: ProductCounterTitle.BoughtGuide, count: 794 },
    ],
    tags: [...commonTags, ...commonGuideTags],
    // pageTitle: 'Гайд «Стресс. Важные моменты»',
    // metaDescription: 'Гайд «Стресс. Важные моменты» представляет собой глубокое погружение в мир стресса, помогая понять его опасности, причины, последствия, а также эффективные стратегии его преодоления. Это практическое руководство поможет вам обрести новые инсайты и навыки для эффективного управления своим стрессом.',
  },
  {
    type: ProductType.Guide,
    devName: GuideDevName.selfDefinition,
    productRoute: '/gayd-praktikum-samoopredeleniye',
    prefix: 'Гайд-практикум',
    title: 'Самоопределение',
    descriptionShort: 'Этот гайд — пошаговая инструкция к себе. Он помогает определиться в разных сферах жизни: личная жизнь, самореализация, отношения с собой, с миром и тд. Иногда люди чувствуют себя потерянными, не уверены, какую дорогу выбрать в жизни и чего на самом деле хотят достичь. Пора с этим определиться!',
    descriptionComponents: [GuideTextBlocks.Guide1TextBlock],
    variants: [
      {
        price: '3 990 ₽',
        durationText: '',
        durationInMonths: 0,
        includesArray: [],
        customServicesArray: [{
          title: 'Гайд на 28 страниц в формате pdf',
          titleShort: 'Гайд',
          serviceDescriptions: [
            '13 проверенных инструментов',
            'Вопросы для саморефлексии',
            '7 практик для самостоятельной работы',
          ],
          image: images.guideDescriptionCardBackground1,
        }],
      },
    ],
    
    titleBackground: images.servicesLine1,
    descriptionPageBackground: images.guideDescriptionBackground1,
    descriptionPageBackgroundMobile: images.guideDescriptionBackgroundMobile1,
    descriptionPageBackgroundStyleMobile: { backgroundPositionX: '90%' },

    countersData: [
      { title: ProductCounterTitle.BoughtGuide, count: 768 },
    ],
    tags: [...commonTags, ...commonGuideTags],
    // pageTitle: 'Гайд-практикум «Самоопределение»',
    // metaDescription: 'Гайд-практикум «Самоопределение» — пошаговая инструкция к себе. Он помогает определиться в разных сферах жизни: личная жизнь, самореализация, отношения с собой, с миром и тд. Иногда люди чувствуют себя потерянными, не уверены, какую дорогу выбрать в жизни и чего на самом деле хотят достичь. Пора с этим определиться!',
  },
  {
    type: ProductType.Guide,
    devName: GuideDevName.personalSpace,
    productRoute: '/gayd-lichnyye-granitsy',
    prefix: 'Гайд',
    title: 'Личные границы',
    descriptionShort: 'Этот гайд поможет вам не только научиться устанавливать ясные границы в общении с другими, но и глубже понять свои собственные потребности, ценности и предпочтения. Вы обретете инструменты для укрепления своей эмоциональной стойкости, улучшения отношений с окружающими и достижения гармонии с самим собой.',
    descriptionComponents: [GuideTextBlocks.Guide2TextBlock],
    variants: [
      {
        price: '3 990 ₽',
        durationText: '',
        durationInMonths: 0,
        includesArray: [],
        customServicesArray: [{
          title: 'Гайд на 28 страниц в формате pdf',
          titleShort: 'Гайд',
          serviceDescriptions: [
            'Чего мы не замечаем в теме границ? ',
            'Исследование часто встречающихся страхов в теме границ (в теме страхов вы получите информацию и практики для их смягчения) ',
            'Страх стать отвергнутым',
            'Страх стать плохим',
            'Материальны ли мысли?',
            'Страх потери самоидентификации',
            'Как в нашем теле живут границы? (психосоматика)',
          ],
          image: images.guideDescriptionCardBackground2,
        }],
      },
    ],
    
    titleBackground: images.servicesLine1,
    descriptionPageBackground: images.guideDescriptionBackground2,
    descriptionPageBackgroundMobile: images.guideDescriptionBackgroundMobile2,
    descriptionPageBackgroundStyleMobile: { backgroundPositionX: '77%' },

    countersData: [
      { title: ProductCounterTitle.BoughtGuide, count: 452 },
    ],
    tags: [...commonTags, ...commonGuideTags],
    // pageTitle: 'Гайд «Личные границы»',
    // metaDescription: 'Гайд «Личные границы» поможет вам научиться устанавливать ясные границы в общении с другими и глубже понять свои собственные потребности, ценности и предпочтения. Вы обретете инструменты для укрепления своей эмоциональной опоры, улучшения отношений с окружающими и достижения гармонии с самим собой.',
  },
  {
    type: ProductType.Guide,
    devName: GuideDevName.blackAndWhiteThinking,
    productRoute: '/gayd-cherno-beloye-myshleniye',
    prefix: 'Гайд',
    title: 'Черно-белое мышление',
    descriptionShort: 'Этот гайд о черно-белом мышлении предлагает углубленное исследование внутренних процессов, которые могут ослабить нашу энергию и мотивацию. Он поможет обнаружить скрытые «пробоины» в нашем мышлении и поведении, которые могут стать препятствием на пути к достижению наших целей. Разбираясь в них и находя способы их исправления, вы сможете наполнить свою жизнь яркими красками и энергией, обрести ясность и уверенность в своих действиях.',
    descriptionComponents: [GuideTextBlocks.Guide3TextBlock],
    variants: [
      {
        price: '3 990 ₽',
        durationText: '',
        durationInMonths: 0,
        includesArray: [],
        customServicesArray: [{
          title: 'Гайд на 27 страниц в формате pdf',
          titleShort: 'Гайд',
          serviceDescriptions: [
            'Как устроено твоё бессознательное?',
            'Чёрно-белое мышление - что это?',
            'Где берет начало черное-белое мышление?',
            'Что на твоей тёмной стороне?',
            'Отношения с мамой, её влияние на развитие психики и мышления',
            'Полярность чувств',
            'Практика на интеграцию между чёрным и белым ',
            'Практика на исцеление отношений с мамой',
          ],
          image: images.guideDescriptionCardBackground3,
        }],
      },
    ],
    
    titleBackground: images.servicesLine1,
    descriptionPageBackground: images.guideDescriptionBackground3,
    descriptionPageBackgroundMobile: images.guideDescriptionBackgroundMobile3,
    descriptionPageBackgroundStyleMobile: { backgroundPositionX: '95%' },

    countersData: [
      { title: ProductCounterTitle.BoughtGuide, count: 483 },
    ],
    tags: [...commonTags, ...commonGuideTags],
    // pageTitle: 'Гайд «Черно-белое мышление»',
    // metaDescription: 'Гайд «Черно-белое мышление» предлагает углубленное исследование внутренних процессов, которые могут ослабить нашу энергию и мотивацию. Он поможет обнаружить скрытые «пробоины» в нашем мышлении и поведении, которые могут стать препятствием на пути к достижению наших целей.',
  },
  {
    type: ProductType.Guide,
    devName: GuideDevName.pushedOut,
    productRoute: '/gayd-vytesnennoye',
    prefix: 'Гайд',
    title: 'Вытесненное',
    descriptionShort: 'Этот гайд о вытесненном погружается в механизмы, лежащие в основе этого явления, раскрывая, как мы часто вытесняем не только негативные, но и положительные аспекты себя — успех, сила, проявленность и даже наша индивидуальность. Освоив теорию и практику в гайде, вы сможете разгадать тайны этого процесса и вернуть утраченные качества в свою жизнь.',
    descriptionComponents: [GuideTextBlocks.Guide4TextBlock],
    variants: [
      {
        price: '3 990 ₽',
        durationText: '',
        durationInMonths: 0,
        includesArray: [],
        customServicesArray: [{
          title: 'Гайд на 30 страниц в формате pdf',
          titleShort: 'Гайд',
          serviceDescriptions: [
            'Как устроено твоё бессознательное?',
            'Отношения с папой, его влияние на развитие психики и мышления',
            'Что ты в себе не принимаешь, вытесняешь? ',
            'Вытеснение положительных аспектов',
            'Вытеснение детского аспекта',
            'Практика на исцеление внутреннего ребёнка',
            'Вытеснение мужского аспекта',
            'Любовь или успех - обязательно ли выбирать?',
            'Практика исцеления отношений с папой',
            'Практика благодарности',
          ],
          image: images.guideDescriptionCardBackground4,
        }],
      },
    ],
    
    titleBackground: images.servicesLine1,
    descriptionPageBackground: images.guideDescriptionBackground4,
    descriptionPageBackgroundMobile: images.guideDescriptionBackgroundMobile4,
    descriptionPageBackgroundStyleMobile: { backgroundPositionX: '70%' },

    countersData: [
      { title: ProductCounterTitle.BoughtGuide, count: 613 },
    ],
    tags: [...commonTags, ...commonGuideTags],
    // pageTitle: 'Гайд «Вытесненное»',
    // metaDescription: 'Гайд «Вытесненное» погружается в механизмы, лежащие в основе этого явления, раскрывая, как мы часто вытесняем не только негативные, но и положительные аспекты себя — успех, сила, проявленность и даже наша индивидуальность. Вы сможете разгадать тайны этого процесса и вернуть утраченные качества в свою жизнь.',
  },
  {
    type: ProductType.Guide,
    devName: GuideDevName.energyOfAbundance,
    productRoute: '/gayd-energiya-izobiliya',
    prefix: 'Гайд',
    title: 'Энергия изобилия',
    mainPageDescription: 'Руководство по созданию энергии изобилия без использования денег. Роль денег с точки зрения бессознательного и практические шаги для расширения энергии изобилия.',
    descriptionShort: 'Этот гайд представляет собой краткое и емкое руководство по созданию энергии изобилия без использования денег. Вы получите ответ на вопрос о том, как начать изобильную жизнь собственными силами. Содержание включает в себя рассмотрение роли денег с точки зрения бессознательного, а также практические шаги для расширения энергии изобилия.',
    descriptionComponents: [GuideTextBlocks.Guide5TextBlock],
    variants: [
      {
        price: '1 490 ₽',
        durationText: '',
        durationInMonths: 0,
        includesArray: [],
        customServicesArray: [{
          title: 'Гайд на 5 страниц в формате pdf',
          titleShort: 'Гайд',
          serviceDescriptions: [
            'Что такое деньги с точки зрения бессознательного?',
            'Где моя энергия, куда я ее сливаю',
            'Практические шаги на расширение энергии изобилия',
          ],
          image: images.guideDescriptionCardBackground5,
        }],
      },
    ],
    
    titleBackground: images.servicesLine1,
    descriptionPageBackground: images.guideDescriptionBackground5,
    descriptionPageBackgroundMobile: images.guideDescriptionBackgroundMobile5,
    descriptionPageBackgroundStyleMobile: { backgroundPositionX: '85%' },

    countersData: [
      { title: ProductCounterTitle.BoughtGuide, count: 894 },
    ],
    tags: [...commonTags, ...commonGuideTags],
    // pageTitle: 'Гайд «Энергия изобилия»',
    // metaDescription: 'Гайд «Энергия изобилия» представляет собой краткое и емкое руководство по созданию энергии изобилия без использования денег. Содержание включает в себя рассмотрение роли денег с точки зрения бессознательного, а также практические шаги для расширения энергии изобилия.',
  },
  {
    type: ProductType.Guide,
    devName: GuideDevName.breakingUp,
    productRoute: '/gayd-rasstavaniye-kak-prozhit-ekologichno',
    prefix: 'Гайд',
    cardTitle: 'Расставание.\r\nКак экологично прожить?',
    title: 'Расставание. Как экологично прожить?',
    descriptionShort: 'Этот гайд представляет собой путеводитель по преодолению разлук в различных сферах жизни, включая разрывы отношений, утрату и завершение жизненных этапов. Он предлагает теоретические концепции и практические инструменты для прохождения через эти сложные моменты, помогая вам прожить их мягко и эффективно.',
    descriptionComponents: [GuideTextBlocks.Guide6TextBlock],
    variants: [
      {
        price: '2 990 ₽',
        durationText: '',
        durationInMonths: 0,
        includesArray: [],
        customServicesArray: [{
          title: 'Гайд на 33 страниц в формате pdf',
          titleShort: 'Гайд',
          serviceDescriptions: [
            'Инструмент – «Письмо»',
            'Как не винить себя?',
            'Как не считать, что этот сценарий будет повторяться всегда? ',
            'Психологи говорят: надо дезинвестировать отношения, что это значит?',
            'Что вашего он (этот человек) забрал с собой? ',
            'Бегство в работу или новые отношения — про что это?',
            'Как понять, что все, отпустило? ',
            'Невротические (созависимые) отношения, что это? ',
            'Как принять решение расстаться? ',
            'Как экологично выйти из отношений?',
          ],
          image: images.guideDescriptionCardBackground6,
        }],
      },
    ],
    
    titleBackground: images.servicesLine1,
    descriptionPageBackground: images.guideDescriptionBackground6,
    descriptionPageBackgroundMobile: images.guideDescriptionBackgroundMobile6,
    descriptionPageBackgroundStyleMobile: { backgroundPositionX: '85%' },

    countersData: [
      { title: ProductCounterTitle.BoughtGuide, count: 761 },
    ],
    tags: [...commonTags, ...commonGuideTags],
    // pageTitle: 'Гайд «Расставание. Как экологично прожить?»',
    // metaDescription: 'Гайд«Расставание. Как экологично прожить?» представляет собой путеводитель по преодолению разлук в различных сферах жизни, включая разрывы отношений, утрату и завершение жизненных этапов. Он предлагает теоретические концепции и практические инструменты для прохождения через эти сложные моменты, помогая вам прожить их мягко и эффективно.',
  },
  {
    type: ProductType.Guide,
    devName: GuideDevName.methodsToHelpSelfStressPanicAttacks,
    productRoute: '/gayd-samopomoshch-pri-stresse-i-panicheskikh-atakakh',
    prefix: 'Гайд',
    title: 'Методы самопомощи при стрессе и панических атаках',
    descriptionShort: 'В этом гайде вы найдете надежную поддержку в борьбе с паническими атаками. Он предоставляет простые и понятные шаги для самопомощи в острой ситуации, а также методы для восстановления ваших ресурсов и мягкую технику саморефлексии.',
    descriptionComponents: [GuideTextBlocks.Guide8TextBlock],
    variants: [
      {
        price: '2 990 ₽',
        durationText: '',
        durationInMonths: 0,
        includesArray: [],
        customServicesArray: [{
          title: 'Гайд на 13 страниц в формате pdf',
          titleShort: 'Гайд',
          serviceDescriptions: [
            'Методы самопомощи при стрессе',
            'Методы самопомощи при панических атаках',
            'Мягкая техника для саморефлексии',
            'Как восполнить ресурс?',
          ],
          image: images.guideDescriptionCardBackground8,
        }],
      },
    ],
    
    titleBackground: images.servicesLine1,
    descriptionPageBackground: images.guideDescriptionBackground8,
    descriptionPageBackgroundMobile: images.guideDescriptionBackgroundMobile8,
    descriptionPageBackgroundStyleMobile: { backgroundPositionX: '90%' },

    countersData: [
      { title: ProductCounterTitle.BoughtGuide, count: 509 },
    ],
    tags: [...commonTags, ...commonGuideTags],
    // pageTitle: 'Гайд «Методы самопомощи при стрессе и панических атаках»',
    // metaDescription: 'В гайде «Методы самопомощи при стрессе и панических атаках» вы найдете надежную поддержку в борьбе с паническими атаками. Он предоставляет простые и понятные шаги для самопомощи в острой ситуации, а также методы для восстановления ваших ресурсов и мягкую технику саморефлексии.',
  },
  {
    type: ProductType.Guide,
    devName: GuideDevName.acceptingSelf,
    productRoute: '/gayd-praktikum-prinyatiye-sebya',
    prefix: 'Гайд-практикум',
    title: 'Принятие себя',
    descriptionShort: 'Этот гайд представляет собой практическое руководство, сосредоточенное на принятии себя в сложных ситуациях. Он направлен на помощь тем, кто столкнулся с трудностями в принятии своих решений, выборов или ошибок. Гайд предлагает практические упражнения, которые позволяют буквально "за руку вернуть себя" в моменты неуверенности и восстановить внутреннюю гармонию и самопринятие.',
    descriptionComponents: [GuideTextBlocks.Guide9TextBlock],
    variants: [
      {
        price: '1 950 ₽',
        durationText: '',
        durationInMonths: 0,
        includesArray: [],
        customServicesArray: [{
          title: 'Гайд на 7 страниц в формате pdf',
          titleShort: 'Гайд',
          serviceDescriptions: [
            'Практика на принятие себя.',
          ],
          image: images.guideDescriptionCardBackground9,
        }],
      },
    ],
    
    titleBackground: images.servicesLine1,
    descriptionPageBackground: images.guideDescriptionBackground9,
    descriptionPageBackgroundMobile: images.guideDescriptionBackgroundMobile9,
    descriptionPageBackgroundStyleMobile: { backgroundPositionX: '95%' },

    countersData: [
      { title: ProductCounterTitle.BoughtGuide, count: 621 },
    ],
    tags: [...commonTags, ...commonGuideTags],
    // pageTitle: 'Гайд-практикум «Принятие себя»',
    // metaDescription: 'Гайд-практикум «Принятие себя» представляет собой практическое руководство, сосредоточенное на принятии себя в сложных ситуациях. Гайд предлагает практические упражнения, которые позволяют буквально &quot;за руку вернут в себя&quot; в моменты неуверенности и восстановить внутреннюю гармонию и самопринятие.',
  },
  {
    type: ProductType.Guide,
    devName: GuideDevName.selfFulfillment,
    productRoute: '/gayd-samorealizatsiya',
    prefix: 'Гайд',
    title: 'Самореализация',
    descriptionShort: 'Этот гайд представляет собой исчерпывающее руководство по самореализации, охватывающее различные аспекты этого процесса. Он помогает разобраться в основных блоках, мешающих успешной самореализации, и предлагает методы и стратегии для их преодоления. В гайде также рассматриваются основные направления самореализации, секреты успешного позиционирования проекта и принципы расширения личного потенциала. ',
    descriptionComponents: [GuideTextBlocks.Guide10TextBlock],
    variants: [
      {
        price: '3 490 ₽',
        durationText: '',
        durationInMonths: 0,
        includesArray: [],
        customServicesArray: [{
          title: 'Гайд на 16 страниц в формате pdf',
          titleShort: 'Гайд',
          serviceDescriptions: [
            'Основные блоки на пути к самореализации в разных сферах – их 7',
            'Пути выхода из блоков',
            'Основные направления самореализации. ',
            'Как избежать перекосов: секреты успешного позиционирования своего проекта – взгляд психолога. ',
            'Как сделать так, чтобы люди видели истинную ценность того, что вы делаете: важные принципы, чтобы легко пойти в расширение своей самореализации.',
            'Бонус: трансформационная практика на проработку установок',
          ],
          image: images.guideDescriptionCardBackground10,
        }],
      },
    ],
    
    titleBackground: images.servicesLine1,
    descriptionPageBackground: images.guideDescriptionBackground10,
    descriptionPageBackgroundMobile: images.guideDescriptionBackgroundMobile10,
    descriptionPageBackgroundStyleMobile: { backgroundPositionX: '80%' },

    countersData: [
      { title: ProductCounterTitle.BoughtGuide, count: 341 },
    ],
    tags: [...commonTags, ...commonGuideTags],
    // pageTitle: 'Гайд «Самореализация»',
    // metaDescription: 'Гайд «Самореализация» помогает разобраться в основных блоках, мешающих успешной самореализации и предлагает методы и стратегии для их преодоления. В гайде также рассматриваются основные направления самореализации, секреты успешного позиционирования проекта и принципы расширения личного потенциала. ',
  },
  {
    type: ProductType.Guide,
    devName: GuideDevName.yourMentalExpansion,
    productRoute: '/gayd-tvoyo-mentalnoye-rasshireniye',
    prefix: 'Гайд',
    title: 'Твоё ментальное расширение',
    descriptionShort: 'Гайд исследует пути ментального расширения, освобождая от ощущения жизни в узких рамках и преодолевая страх осуждения и критики со стороны других. Пропитанный теорией и подкрепленный практикой, этот гайд предлагает глубокое понимание и множество методов для преодоления сложностей и раскрытия своего потенциала.',
    descriptionComponents: [GuideTextBlocks.Guide11TextBlock],
    variants: [
      {
        price: '3 990 ₽',
        durationText: '',
        durationInMonths: 0,
        includesArray: [],
        customServicesArray: [{
          title: 'Гайд на 28 страниц в формате pdf',
          titleShort: 'Гайд',
          serviceDescriptions: [
            'Что такое расширение?',
            'Почему возникают сложности в разных сферах жизни?',
            'Как проявляется сжатие?',
            'Глубинная причина №1  страха чужого мнения. ',
            'Где выход: 4 причины сжатия.',
            'Глубинная причина №2  страха чужого мнения.',
            'Тоннель восприятия и мышления. ',
            'Как выйти за его рамки?',
            'Глубинная причина №3  страха чужого мнения.',
          ],
          image: images.guideDescriptionCardBackground11,
        }],
      },
    ],
    
    titleBackground: images.servicesLine1,
    descriptionPageBackground: images.guideDescriptionBackground11,
    descriptionPageBackgroundMobile: images.guideDescriptionBackgroundMobile11,
    descriptionPageBackgroundStyleMobile: { backgroundPositionX: '100%' },

    countersData: [
      { title: ProductCounterTitle.BoughtGuide, count: 253 },
    ],
    tags: [...commonTags, ...commonGuideTags],
    // pageTitle: 'Гайд «Твоё ментальное расширение»',
    // metaDescription: 'Гайд «Твоё ментальное расширение» освобождает от ощущения жизни в узких рамках и помогает преодолеть страх осуждения и критики со стороны других. Пропитанный теорией и подкрепленный практикой, этот гайд предлагает глубокое понимание и множество методов для преодоления сложностей и раскрытия своего потенциала.',
  },
  {
    type: ProductType.Guide,
    devName: GuideDevName.energyCapacityOfAWoman,
    productRoute: '/gayd-energeticheskaya-yemkost-zhenshchiny',
    prefix: 'Гайд',
    cardTitle: 'Энергетическая емкость женщины.\r\nРасширение и блокировка женской энергии',
    title: 'Энергетическая емкость женщины. Расширение и блокировка женской энергии',
    descriptionShort: 'Этот гайд предлагает глубокое погружение в понимание женской энергии с использованием принципов бессознательного, квантовой психологии и физики. Независимо от вашего пола, вы узнаете, какие энергии присущи вам, как увеличить вашу внутреннюю энергоемкость, а также как раскрыть и разблокировать потенциал вашей женской энергии.',
    descriptionComponents: [GuideTextBlocks.Guide12TextBlock],
    variants: [
      {
        price: '3 990 ₽',
        durationText: '',
        durationInMonths: 0,
        includesArray: [],
        customServicesArray: [{
          title: 'Гайд на 29 страниц в формате pdf',
          titleShort: 'Гайд',
          serviceDescriptions: [
            'Какие энергии ты несешь в себе',
            'Что такое синхрон?',
            'Как увеличивать внутреннюю энергоемкость?',
            'О кармическом менеджменте',
            'Как мы сами блокируем энергии?',
            'Мужское/женское: тонкости переплетения энергий в нас',
            'Мужское/женское: тонкости взаимоотношений',
          ],
          image: images.guideDescriptionCardBackground12,
        }],
      },
    ],
    
    titleBackground: images.servicesLine1,
    descriptionPageBackground: images.guideDescriptionBackground12,
    descriptionPageBackgroundMobile: images.guideDescriptionBackgroundMobile12,
    descriptionPageBackgroundStyleMobile: { backgroundPositionX: '95%' },

    countersData: [
      { title: ProductCounterTitle.BoughtGuide, count: 190 },
    ],
    tags: [...commonTags, ...commonGuideTags],
    // pageTitle: 'Гайд «Энергетическая емкость женщины. Расширение и блокировка женской энергии»',
    // metaDescription: 'Гайд «Энергетическая емкость женщины. Расширение и блокировка женской энергии» предлагает глубокое погружение в понимание женской энергии с использованием принципов бессознательного, квантовой психологии и физики. Вы узнаете, как увеличить вашу внутреннюю энергоемкость, а также как раскрыть и разблокировать потенциал вашей женской энергии.',
  },
  {
    type: ProductType.Guide,
    devName: GuideDevName.techPersonalDevelopment,
    productRoute: '/gayd-tekhnika-samostoyatelnoy-prorabotki',
    prefix: 'Гайд',
    title: 'Техника самостоятельной проработки',
    descriptionShort: 'Гайд состоит из 5-ти ключевых правил, которые помогут вам трансформировать некомфортные сложные состояния и справиться с эмоциональным напряжением. Незаменимый инструмент в мире высоких скоростей, когда нет возможности обратиться к психологу.',
    descriptionComponents: [GuideTextBlocks.Guide13TextBlock],
    variants: [
      {
        price: '1 950 ₽',
        durationText: '',
        durationInMonths: 0,
        includesArray: [],
        customServicesArray: [{
          title: 'Гайд на 12 страниц в формате pdf',
          titleShort: 'Гайд',
          serviceDescriptions: [
            'Правило №1',
            'Правило №2',
            'Правило №3',
            'Правило №4',
            'Правило №5',
          ],
          image: images.guideDescriptionCardBackground13,
        }],
      },
    ],
    
    titleBackground: images.servicesLine1,
    descriptionPageBackground: images.guideDescriptionBackground13,
    descriptionPageBackgroundMobile: images.guideDescriptionBackgroundMobile13,
    descriptionPageBackgroundStyleMobile: { backgroundPositionX: '90%' },

    countersData: [
      { title: ProductCounterTitle.BoughtGuide, count: 317 },
    ],
    tags: [...commonTags, ...commonGuideTags],
    // pageTitle: 'Гайд «Техника самостоятельной проработки»',
    // metaDescription: 'Гайд «Техника самостоятельной проработки» состоит из 5-ти ключевых правил, которые помогут вам трансформировать некомфортные сложные состояния и справиться с эмоциональным напряжением. Незаменимый инструмент в мире высоких скоростей, когда нет возможности обратиться к психологу.',
  },
];

export default guides;