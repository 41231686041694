import images from '../../../../../utils/images';
import { ServiceDetails } from '../../../../../utils/types';

export enum ProductService {
  // is not used in product cards; present everywhere except medconcierge
  OnlineSurveyConsult = 'Онлайн-анкета и консультация',


  OnlineConsultDoctor = 'Онлайн-консультация с врачом',
  OnlineConsult = 'Онлайн-консультация',
  FreeIntroConsult = 'Бесплатная вводная консультация',

  AnalyzeMedicalRecords = 'Анализ текущей медицинской документации',
  SuitableSpecialist = 'Подбор специалиста и клиники',
  DailyDietPlan = 'План питания на каждый день',
  GroceriesList = 'Список покупок продуктов питания',
  FullDietarySupportUntilResult = 'Полное диетологическое сопровождение до результата',
  ExercisePlan = 'План тренировок на каждую неделю',
  OnlineExercise = 'Онлайн-тренировки',
  SuitableTrainerAndLocation = 'Подбор тренера для занятий офлайн в удобном месте для клиента',
  SuitableSpecialistsForYourGoals = 'Индивидуальный подбор специалистов для достижения ваших целей',

  OnlineExerciseGift = 'Онлайн-тренировка в подарок',
}

export enum SubService {
  DietRecipes = 'Рецепты с пошаговой инструкцией приготовления',
  SuitableSpecialist = 'Подбор нужного специалиста и клиники',
  FullDietarySupport = 'Полное диетологическое сопровождение',
}

export const subServiceItemsOrder: (ProductService | SubService)[] = [
  ProductService.OnlineSurveyConsult,
  ProductService.DailyDietPlan,
  ProductService.GroceriesList,
  SubService.DietRecipes,
  
  ProductService.ExercisePlan,
  ProductService.OnlineConsult,
  ProductService.OnlineExercise,
  SubService.FullDietarySupport,
  
  ProductService.SuitableTrainerAndLocation,
  
  
  ProductService.OnlineConsultDoctor,
  ProductService.FreeIntroConsult,
  ProductService.AnalyzeMedicalRecords,
  SubService.SuitableSpecialist,
  ProductService.SuitableSpecialist,
  ProductService.SuitableSpecialistsForYourGoals,


  ProductService.OnlineExerciseGift,
];

export const servicesDetailedDescriptions: { [key in ProductService]?: ServiceDetails[] } = {
  [ProductService.OnlineConsult]: [{
    subServiceName: ProductService.OnlineConsult,
    serviceDescriptions: ['Длительность около 1 часа. Онлайн-консультация даст возможность уточнить любые детали, обсудить ваши индивидуальные потребности и цели, а также разъяснить план действий для достижения желаемых результатов.'],
    image: images.itemDescriptionCard1,
  }],

  [ProductService.OnlineSurveyConsult]: [{
    subServiceName: ProductService.OnlineSurveyConsult,
    serviceDescriptions: ['Консультация + заполнение и разбор подробной онлайн-анкеты, где будут учитываться продукты, которые вы любите, ваши цели, уровень физической активности, а также сколько раз в неделю вы хотите готовить.'],
    image: images.itemDescriptionCard2,
  }],

  [ProductService.OnlineConsultDoctor]: [{
    subServiceName: ProductService.OnlineConsultDoctor,
    serviceDescriptions: ['консультация врача по вопросам медицинской тематики;', 'сбор анамнеза;', 'предоставление рекомендаций по возможному плану лечения.'],
    image: images.itemDescriptionCardM1,
  }],

  [ProductService.AnalyzeMedicalRecords]: [{
    subServiceName: ProductService.AnalyzeMedicalRecords,
    serviceDescriptions: ['консультация врача по вопросам медицинской тематики, в том числе сбор анамнеза,', 'обзор направленных клиентом медицинских заключений, документов;', 'предоставление рекомендаций по дальнейшему лечению на основании проведенного анализа/обзора направленной медицинской документации.'],
    image: images.itemDescriptionCardM2,
  }],

  [ProductService.SuitableSpecialist]: [{
    subServiceName: SubService.SuitableSpecialist,
    serviceDescriptions: ['консультация врача по вопросам медицинской тематики,в том числе сбор анамнеза;', 'предоставление рекомендаций по дальнейшему лечению', 'подбор целевого специалиста по имеющимся показаниям в одном из рекомендованных медицинских учреждений РФ, запись на прием к данному специалисту.'],
    image: images.itemDescriptionCardM3,
  }],
  
  [ProductService.DailyDietPlan]: [{
    subServiceName: ProductService.DailyDietPlan,
    serviceDescriptions: ['Персональный план питания на каждый день, сбалансированный по БЖУ и калориям, разработанный профессиональным диетологом.'],
    image: images.itemDescriptionCard3,
  },
  {
    subServiceName: SubService.DietRecipes,
    serviceDescriptions: ['Рецепты с пошаговой инструкцией приготовления, позволят  легко освоить приготовление блюд. Благодаря детальным инструкциям вы сможете готовить, наслаждаясь процессом готовки и экспериментировать с новыми рецептами.'],
    image: images.itemDescriptionCard5,
  }],

  [ProductService.GroceriesList]: [{
    subServiceName: ProductService.GroceriesList,
    serviceDescriptions: ['Подробный список продуктов, удобно разбитый по категориям продуктов и сроку их хранения, чтобы сэкономить время и деньги на походах в магазин.'],
    image: images.itemDescriptionCard4,
  }],

  [ProductService.FullDietarySupportUntilResult]: [{
    subServiceName: SubService.FullDietarySupport,
    serviceDescriptions: ['Получите полное диетологическое сопровождение с персональным ментором каждый день для поддержки и мотивации. Контроль результатов каждую неделю. Научитесь правильным пищевым привычкам и удерживать результат на автомате.'],
    image: images.itemDescriptionCard8,
  }],

  [ProductService.ExercisePlan]: [{
    subServiceName: ProductService.ExercisePlan,
    serviceDescriptions: ['Индивидуально составленный план тренировок с инструкциями выполнения и видео на каждую неделю. Учитываются ваши цели, состояние здоровья и доступный инвентарь. Коррекция плана каждую неделю на основе вашего прогресса и пожеланий.'],
    image: images.itemDescriptionCard6,
  }],

  [ProductService.OnlineExercise]: [{
    subServiceName: ProductService.OnlineExercise,
    serviceDescriptions: ['У нас есть высококвалифицированные тренеры разных направлений: фитнес, пилатес, стретчинг и др. Во время онлайн-тренировок будет корректироваться техника выполнения упражнений, обеспечивая результативность, где бы вы ни находились.'],
    image: images.itemDescriptionCard7,
  }],

  [ProductService.OnlineExerciseGift]: [{
    subServiceName: ProductService.OnlineExerciseGift,
    serviceDescriptions: ['Вам в подарок онлайн-тренировка от наших высококвалифицированных тренеров по фитнесу, пилатесу, стретчингу и др. направлениям. Во время тренировки будет корректироваться техника выполнения, обеспечивая результат, где бы вы ни находились.'],
    image: images.itemDescriptionCard7,
  }],

  [ProductService.SuitableTrainerAndLocation]: [{
    subServiceName: ProductService.SuitableTrainerAndLocation,
    serviceDescriptions: ['Подберем опытного тренера для ваших офлайн-занятий в удобном для вас месте. А также обеспечим персонализированную поддержку, с учетом ваших потребностей и предпочтений, чтобы достичь желаемых результатов.'],
    image: images.itemDescriptionCard9,
  }],
}

// (!) expected product names on backend:
// Программа Медконсьерж
// Lite ЗОЖ Питание
// Standard ЗОЖ Питание
// Maximum ЗОЖ Питание
// Lite ЗОЖ Питание + Спорт
// Standard ЗОЖ Питание + Спорт
// Maximum ЗОЖ Питание + Спорт
// Программа Персональная

export enum TariffPlanDevName {
  consultProgram = 'Консультация',
  medconcierge = 'Программа Медконсьерж',
  liteHealthDiet  = 'Lite ЗОЖ Питание',
  standardHealthDiet  = 'Standard ЗОЖ Питание',
  maximumHealthDiet  = 'Maximum ЗОЖ Питание',
  liteHealthDietSport = 'Lite ЗОЖ Питание + Спорт',
  standardHealthDietSport = 'Standard ЗОЖ Питание + Спорт',
  maximumHealthDietSport = 'Maximum ЗОЖ Питание + Спорт',
  personalProgram = 'Программа Персональная',
};

export enum GuideDevName {
  stressImportantMoments = 'гайд Стресс. Важные моменты',
  selfDefinition = 'гайд-практикум Самоопределение',
  personalSpace = 'гайд Личные границы',
  blackAndWhiteThinking = 'гайд Черно-белое мышление',
  pushedOut = 'гайд Вытесненное',
  energyOfAbundance = 'гайд Энергия изобилия',
  breakingUp = 'гайд Расставание. Как экологично прожить?',
  methodsToHelpSelfStressPanicAttacks = 'гайд Методы самопомощи при стрессе и панических атаках',
  acceptingSelf = 'гайд-практикум Принятие себя',
  selfFulfillment = 'гайд Самореализация',
  yourMentalExpansion = 'гайд Твоё ментальное расширение',
  energyCapacityOfAWoman = 'гайд Энергетическая емкость женщины. Расширение и блокировка женской энергии',
  techPersonalDevelopment = 'гайд Техника самостоятельной проработки',
}

export const ProductDevName = { ...TariffPlanDevName, ...GuideDevName };

// export const commonTags: ProductTag[] = [
//   ProductTag.man,
//   ProductTag.woman,
//   ProductTag.child,
//   ProductTag.parents,
//   ProductTag.family,

//   ProductTag.ageRange1,
//   ProductTag.ageRange2,
//   ProductTag.ageRange3,
//   ProductTag.ageRange4,
//   ProductTag.ageRange5,
//   ProductTag.ageRange6,
//   ProductTag.ageRange7,
//   ProductTag.ageRange8,
// ];

// export const productItems: ProductData[] = [  
//   ...guides,
//   ...tariffPlans,
// ];