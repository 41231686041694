export const baseColors = {
  white: '#FFFFFF',
  cDDECEE: '#DDECEE',
  c4B6263: '#4B6263',
  c8DA6A6: '#8DA6A6',
  cD2E2E3: '#D2E2E3',
  c1A3233: '#1A3233',
  c334A4B: '#334A4B',
  c373737: '#373737',
  black: '#000000',

  c85E0E4: '#85E0E4',
  c3ACFD5: '#3ACFD5',

  c9B2C2C: '#9B2C2C',

  cEAF3F4: '#EAF3F4',
  cF1F8F9: '#F1F8F9',
  cF4FBFF: '#F4FBFF',
  cE0EFF5: '#E0EFF5',
  cF0F9FD: '#F0F9FD',
  cDEEDF3: '#DEEDF3',
  cECF6FB: '#ECF6FB',
}

export const colors = {
  textMain: baseColors.c1A3233,
  text2: baseColors.c334A4B,
  text3: baseColors.c373737,
  text4: baseColors.c8DA6A6,
  accent: baseColors.c3ACFD5,
  error: baseColors.c9B2C2C,
}