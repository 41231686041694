import React from 'react';
import images from '../../../../utils/images';
import FaqItemCollapsible, { QuestionItem } from './FaqItemCollapsible';
import {
  agreementTerminationHref,
  companyMail,
  companyMailHref,
  telegramBotHref,
  telegramBotName,
} from '../../../../utils/appConstants';
import './FAQ.scss';

const FAQ = ({ blockRef, mobileGap }: { blockRef: React.RefObject<HTMLDivElement>; mobileGap?: number; }) => {
  const blockTitle = 'Частые вопросы';
  const agreementTerminationLinkBlock = <a style={{ color: '#3182CE'}} href={agreementTerminationHref} className='link opacity-on-hover' target="_blank">скачать заявление</a>;
  const companyMailLinkBlock = <a className='faqAnswerLink link opacity-on-hover' href={companyMailHref}>{companyMail}</a>;

  const questions: QuestionItem[] = [
    { question: 'Что я получу?', answer: 'Вы можете обратиться с любой проблемой по состоянию вашего здоровья, где мы составим персональный план для восстановления, поддержания и совершенствования вашей личности.' },
    { question: 'А если я не получу ожидаемого результата?', answer: 'Мы стремимся предоставить качественное обслуживание и делаем всё возможное, чтобы достичь лучших результатов для наших клиентов.\r\n В зависимости от полученных данных, мы в любой момент готовы скорректировать персональный план для достижения успеха.' },
    { question: 'Как оформить возврат?', answer: <div className='faqAnswerText'>Вам необходимо {agreementTerminationLinkBlock}, заполнить данные, подписать и отправить скан на почту {companyMailLinkBlock}</div> },
    // { question: 'На какой период я могу оформить подписку?', answer: 'Выберите оптимальный вариант для себя: подписка на 1, 3 или 6 месяцев, а также выгодный годовой план.' },
    { question: 'Как вы подбираете специалистов?', answer: 'Все врачи проходят тщательную проверку на нашем сервисе. Они предоставляют для этого все необходимые документы, подтверждающие личность, дипломы, сертификаты, свидетельства, указывающие на наличие специализации и повышения квалификации. Внимательно изучается трудовой стаж в книжке, говорящий о достаточном опыте работы.' },
    { question: 'Какие регионы охватывает сервис?', answer: 'Сервис не имеет ограничений в доступе для любого пользователя в пределах РФ, а также по индивидуальному запросу можем организовать консилиум для лечения за рубежом.' },
    { question: 'Как защищается конфиденциальность данных?', answer: 'Для нас сохранение втайне данных пользователя является главным условием, поэтому мы следуем всем требованиям закона N 152-ФЗ РОССИЙСКАЯ ФЕДЕРАЦИЯ «ФЕДЕРАЛЬНЫЙ ЗАКОН О ПЕРСОНАЛЬНЫХ ДАННЫХ».' },
  ];

  const sideNoteText1 = 'Не нашли ответ на свой вопрос?';
  const sideNoteText2 = 'Задайте его нам и мы обязательно ответим';

  const sideNoteBackground: React.CSSProperties = {
    backgroundImage: `url(${images.faqRectangleBg})`,
    backgroundPosition: 'right top',
    backgroundSize: 'auto 100%',
    backgroundRepeat: 'no-repeat',
  }

  return (
    <div className='faqContainer' ref={blockRef}>
      <div className='blockTitle'>{blockTitle}</div>
      <div className='faqInnerContainer' style={mobileGap ? { gap: mobileGap } : {}}>
        <div className='faqQuestionsContainer'>
        {questions.map((item) => (
          <FaqItemCollapsible
            key={item.question}
            faqItem={item}
          />
        ))}
        </div>
        <div className='faqSideNote' style={sideNoteBackground}>
          <div className='faqSideNoteText1'>{sideNoteText1}</div>
          <div className='faqSideNoteText2'>{sideNoteText2}</div>
          <div className='faqContactUsItem' style={{ marginTop: 30 }}>
            <img src={images.mail} alt="mail" className='faqContactUsIcon' />
            <a className='faqContactUsItemText link opacity-on-hover' href={companyMailHref}>{companyMail}</a>
          </div>
          <div className='faqContactUsItem' style={{ marginTop: 20 }}>
            <img src={images.telegramIcon} alt="telegram" className='faqContactUsIcon' />
            <a className='faqContactUsItemText link opacity-on-hover' href={telegramBotHref} target="_blank">{telegramBotName}</a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FAQ;
