import images from '../../../../../utils/images';
import './MainBannerFreeGuideItem.scss';
import { useState } from 'react';
import GetFreeGuideModal from '../../4.GetFreeGuideBlock/components/GetFreeGuideModal';
import { yandexMetrics } from '../../../../../utils/yandexMetrics';

const MainBannerFreeGuideItem = () => {
  const freeGuideData = {
    typeTitle: 'Гайд',
    title: 'Гайд «Хочу в ПП. С чего начать?»',
    description: 'Забирайте наш бесплатный гайд по правильному питанию!',
    price: 'БЕСПЛАТНО',
  };

  const onOpenFreeGuideForm = () => {
    openModal();
    yandexMetrics.openFreeGuideForm();
  }
  // ###################################################################################################
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const openModal = () => setModalIsOpen(true);
  const closeModal = () => setModalIsOpen(false);

  return (
    <div className="mainBannerFreeGuideItemOuterContainer" key={'freeGuide'}>
      <GetFreeGuideModal modalIsOpen={modalIsOpen} closeModal={closeModal} />

      <div className="mainBannerFreeGuideItemMainContainer link" onClick={onOpenFreeGuideForm}>

        <img className="mainBannerFreeGuideItemIllustration" src={images.mainPageFreeGuideCardIllustration} alt="" />

        <div className="mainBannerFreeGuideItemMainContainerGradient">
          <div className="mainBannerFreeGuideItemMainContainerTitle">{freeGuideData.typeTitle}</div>
          <div className="mainBannerFreeGuideItemMainContainerBottomAlignedColumn">
            <div className="mainBannerFreeGuideItemMainContainerDescription">
              {freeGuideData.description}
            </div>
            <div className="mainBannerFreeGuideItemMainContainerBottomRow">
              <div className="mainBannerFreeGuideItemMainContainerPrice">{freeGuideData.price}</div>
              <div className="mainBannerFreeGuideItemMainContainerArrowButton">
                <img className="mainBannerFreeGuideItemMainContainerArrow" src={images.mainBannerArrow} alt="" />
              </div>
            </div>
          </div>
        </div>
        
      </div>
      <div className="mainBannerFreeGuideItemBottomContainer">{freeGuideData.title}</div>
    </div>
  );
};

export default MainBannerFreeGuideItem;
