import React from 'react'
import images from '../../../../utils/images';
import './MainBannerSummary.scss';

const MainBannerSummary = () => {
  const title = 'Универсальный Медицинский консьерж №1 в России';
  const leftBlockHeaderText = 'Наш сервис впервые представлен в России в 2014 году';

  const keyPointsTitle1 = 'Наша цель';
  const keyPointsDescription1 = 'На деле показать и рассказать как мы умеем работать, передать лучший опыт и практики, накопленные за 10 лет в области организации медицинской помощи в России и за рубежом.';
  const keyPointsTitle2 = 'Наша миссия';
  const keyPointsDescription2 = 'Сделать медицинскую помощь понятной и доступной для каждого. Мы стремимся к тому, чтобы каждый пациент мог найти идеального врача, способного найти подход к его индивидуальным потребностям, ведь как гласит древнее учение Гиппократа: «Врач лечит не болезнь, а больного».';

  return (
    <div className="mainBannerSummaryContainer">
      <div className="mainBannerSummaryContainerTitle">{title}</div>
      <div className="mainBannerSummaryContainerInnerContainer">
        <div className="mainBannerSummaryContainerFirstBlock">
          <div className="mainBannerSummaryContainerFirstBlockHeader">
            <img
              className="mainBannerSummaryContainerFirstBlockHeaderImage"
              src={images.mainBannerSummaryBlockHeader}
            />
            <div className="mainBannerSummaryContainerFirstBlockHeaderText">{leftBlockHeaderText}</div>
          </div>
          <div className="mainBannerSummaryContainerFirstBlockMainText">
            Мы берём <b>опыт работы медицинского ассистанса по принципу «Единого окна»</b> в области обслуживания
            корпоративных клиентов по добровольному медицинскому страхованию (ДМС), а также физических лиц, выезжающих
            за рубеж (ВЗР) и даём возможность широкому кругу клиентов воспользоваться нашим сервисом «Универсальный
            Медицинский консьерж № 1».
          </div>
          <div className="mainBannerSummaryContainerFirstBlockMainText" style={{ marginTop: 16 }}>
            <b>Мы предлагаем популярную базовую программу «Медконсьерж»</b> для клиентов, которые нуждаются
            в информационно-консультационной поддержке по медицинским вопросам, организации медицинской помощи
            в рамках бесплатного полиса ОМС или платных услуг на территории России, а также в юридической поддержке,
            оформлении медицинской документации или получения налогового вычета.
          </div>
        </div>
        <div className="mainBannerSummaryContainerSecondBlock">
          <div className="mainBannerSummaryContainerSecondBlockSection">
            <div className="mainBannerSummaryContainerSecondBlockSectionTitle">{keyPointsTitle1}</div>
            <div className="mainBannerSummaryContainerSecondBlockSectionDescription">{keyPointsDescription1}</div>
          </div>
          <div className="mainBannerSummaryContainerSecondBlockSection">
            <div className="mainBannerSummaryContainerSecondBlockSectionTitle">{keyPointsTitle2}</div>
            <div className="mainBannerSummaryContainerSecondBlockSectionDescription">{keyPointsDescription2}</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MainBannerSummary