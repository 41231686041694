import PaymentProcessingBlock from './components/PaymentProcessingBlock';
import BaseLayout from '../BaseLayout';

const OrderStatusPage = () => {
  return (
    <BaseLayout
      hideSectionLinks={false}
      content={
        <PaymentProcessingBlock />
      }
    />
  );
}

export default OrderStatusPage;
