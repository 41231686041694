import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { ConsultCallData } from '../utils/types';
import { ProductDevName } from '../pages/MainPage/components/5.Products/constants/constants';
import { SectionName } from '../utils/enums';

interface MainState {
  fetching: boolean;
  blockInterface: boolean;
  sectionNameToScrollTo: SectionName | null;
  sectionNameToReturnTo: SectionName | null;
  productPagesToShow: number;
  consultCallData: ConsultCallData;
  freeGuideEmail: string;
  initialProductVariantId: number;
};

const initialState: MainState = {
  fetching: false,
  blockInterface: false,
  sectionNameToScrollTo: null,
  sectionNameToReturnTo: null,
  productPagesToShow: 1,
  consultCallData: {
    programDevName: ProductDevName.consultProgram,
    durationInMonths: 0,
    tags: [],
  },
  freeGuideEmail: '',
  initialProductVariantId: 0,
};

const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    // ####################################################################################
    setBlockInterface: (state, { payload }: PayloadAction<boolean>) => {
      state.blockInterface = payload;
    },
    setSectionToScrollTo: (state, { payload }: PayloadAction<SectionName | null>) => {
      state.sectionNameToScrollTo = payload;
    },
    setSectionToReturnTo: (state, { payload }: PayloadAction<SectionName | null>) => {
      state.sectionNameToReturnTo = payload;
    },
    setConsultCallData: (state, { payload }: PayloadAction<ConsultCallData>) => {
      state.consultCallData = payload;
    },
    setProductPagesToShow: (state, { payload }: PayloadAction<number>) => {
      state.productPagesToShow = payload;
    },
    setFreeGuideEmail: (state, { payload }: PayloadAction<string>) => {
      state.freeGuideEmail = payload;
    },
    setInitialProductVariantId: (state, { payload }: PayloadAction<number>) => {
      state.initialProductVariantId = payload;
    },


    setSetting: (state, { payload }: PayloadAction<Partial<MainState>>) => {
      const updatedState = {
        ...state,
        ...payload,
      };
      return updatedState;
    },
    // ####################################################################################
  },
});

export const {
  setBlockInterface,
  setSectionToScrollTo,
  setSectionToReturnTo,
  setProductPagesToShow,
  setConsultCallData,
  setFreeGuideEmail,
  setInitialProductVariantId,
} = settingsSlice.actions;

export default settingsSlice.reducer;
