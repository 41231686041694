import BaseLayout from '../BaseLayout';
import './ItemDescriptionPage.scss';
import { ProductService, servicesDetailedDescriptions, subServiceItemsOrder, ProductDevName } from '../MainPage/components/5.Products/constants/constants';
import { useEffect, useRef, useState } from 'react';
import PurchaseProductModal from '../../components/PurchaseProductModal/PurchaseProductModal';
import ItemDescriptionPageMainBanner from './components/ItemDescriptionPageMainBanner';
import ItemDescriptionPageServiceCards from './components/ItemDescriptionPageServiceCards';
import ItemDescriptionPageDetailsSection from './components/ItemDescriptionPageDetailsSection';
import { useNavigate, useLocation } from 'react-router-dom';
import { useOnReturnToMainPageButtonClick, useScreenSize, useScrollToTop } from '../../utils/hooks';
import { ProductType, SectionName } from '../../utils/enums';
import { ConsultCallData, DimensionsData, ServiceData, ServiceDetails } from '../../utils/types';
import FAQ from '../MainPage/components/7.FAQ/FAQ';
import Consult from '../MainPage/components/8.Consult/Consult';
import { useDispatch, useSelector } from 'react-redux';
import { setConsultCallData, setSectionToScrollTo } from '../../redux/mainReducer';
import { RootState } from '../../redux/store';
import { getProductTitle, scrollToBlockRef } from '../../utils/helper';
import { productItems } from '../MainPage/components/5.Products/constants/products';

const ItemDescriptionPage = () => {
  useScrollToTop({ smooth: false });
  const sectionNameToReturnTo = useSelector((state: RootState) => state.main.sectionNameToReturnTo);
  const onReturnButtonClick = useOnReturnToMainPageButtonClick(sectionNameToReturnTo);

  const { screenSize, isVerticallyOriented } = useScreenSize();
  const dimensionsData: DimensionsData = { screenSize, isVerticallyOriented };

  // ###################################################################################################
  const faqRef = useRef<HTMLDivElement>(null);
  const consultRef2 = useRef<HTMLDivElement>(null);

  const scrollToSection = (sectionName: SectionName | null) => {
    switch (sectionName) {
      case SectionName.ConsultSection2: scrollToBlockRef(consultRef2); break;
      case SectionName.FAQ:            scrollToBlockRef(faqRef); break;
      default: return;
    }
  }

  const dispatch = useDispatch();
  const sectionNameToScrollTo = useSelector((state: RootState) => state.main.sectionNameToScrollTo);
  useEffect(() => {
    scrollToSection(sectionNameToScrollTo);
    if (sectionNameToScrollTo) dispatch(setSectionToScrollTo(null));
  }, [sectionNameToScrollTo]);

  // ###################################################################################################
  // use location object to get query parameters (id) from e.g. .../item?id=123
  const location = useLocation();
  const productRouteName = location.pathname;

  console.log(`productRouteName = ${productRouteName}`);
  const productData = productItems.find(item => item.productRoute === productRouteName) || productItems[0];

  const initialProductVariantId = useSelector((state: RootState) => state.main.initialProductVariantId)
  const [selectedVariantId, setSelectedVariantId] = useState(initialProductVariantId);

  const currentVariantData = productData.variants[selectedVariantId];
  
  useEffect(() => {
    const consultCallData: ConsultCallData = {
      programDevName: productData.devName,
      durationInMonths: currentVariantData.durationInMonths,
      tags: [],
    }
    dispatch(setConsultCallData(consultCallData));
  }, [productData, currentVariantData])
  

  // ###################################################################################################
  // data about included services (for cards and descriptions column)

  const includedServices = [...currentVariantData.includesArray];
  if (currentVariantData.bonuses) includedServices.push(...currentVariantData.bonuses);

  const subServicesDetailsArrayRaw = includedServices.reduce(
    (arr: ServiceDetails[], item) => [...arr, ...servicesDetailedDescriptions[item.optionName]!], []);

  const isTariffPlan = productData.type === ProductType.TariffPlan;
  const isMedconcierge = productData.devName === ProductDevName.medconcierge;
  if (isTariffPlan && !isMedconcierge) {
    subServicesDetailsArrayRaw.unshift(servicesDetailedDescriptions[ProductService.OnlineSurveyConsult]![0]);
  }

  subServicesDetailsArrayRaw.sort((a, b) => {
    return subServiceItemsOrder.indexOf(a.subServiceName) - subServiceItemsOrder.indexOf(b.subServiceName);
  });

  // transform into ServiceData[] and 
  const subServicesDataArray = subServicesDetailsArrayRaw.reduce(
    (arr: ServiceData[], item) => [
      ...arr,
      { title: item.subServiceName, serviceDescriptions: item.serviceDescriptions, image: item.image },
    ], []);

  // add custom services if they are present
  const { customServicesArray } = currentVariantData;
  if (customServicesArray) subServicesDataArray.push(...customServicesArray);
    
  // ###################################################################################################
  const productTitle = getProductTitle(productData);

  // ###################################################################################################
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const openPurchaseModal = () => setModalIsOpen(true);
  const closePurchaseModal = () => setModalIsOpen(false);

  return (
    <BaseLayout
      hideSectionLinks={false}
      hideFooter={false}
      content={
        <div className="itemDescriptionContainer">
          <PurchaseProductModal
            productData={productData}
            selectedVariantId={selectedVariantId}
            selectedTags={[]}
            modalIsOpen={modalIsOpen}
            closeModal={closePurchaseModal}
          />

          <ItemDescriptionPageMainBanner
            productTitle={productTitle}
            productData={productData}
            selectedVariantId={selectedVariantId}
            setSelectedVariantId={setSelectedVariantId}
            openPurchaseModal={openPurchaseModal}
          />

          <ItemDescriptionPageServiceCards
            productData={productData}
            subServicesDataArray={subServicesDataArray}
          />

          <ItemDescriptionPageDetailsSection
            productTitle={productTitle}
            productData={productData}
            subServicesDataArray={subServicesDataArray}
            selectedVariantId={selectedVariantId}
            setSelectedVariantId={setSelectedVariantId}
            openPurchaseModal={openPurchaseModal}
          />
          <div className='itemDescriptionContainerNegativeMargins'>
            <FAQ blockRef={faqRef} mobileGap={40} />
            <Consult blockRef={consultRef2} dimensionsData={dimensionsData} />
          </div>
        </div>
      }
    />
  );
}

export default ItemDescriptionPage;
