import React, { useEffect, useState } from 'react';
import PurchaseProductModal from '../../../../../components/PurchaseProductModal/PurchaseProductModal';
import { SectionName, ProductTag } from '../../../../../utils/enums';
import { DimensionsData, ProductData } from '../../../../../utils/types';
import ProductItem from './ProductItem';
import './ProductsRows.scss';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../redux/store';
import { setProductPagesToShow } from '../../../../../redux/mainReducer';

const ProductsRows = ({
  productItems,
  onMoveUserToConsult,
  dimensionsData,
  selectedTags,
}: {
  productItems: ProductData[];
  onMoveUserToConsult: (productItem: ProductData, selectedVariantId: number, consultSection: SectionName) => void;
  dimensionsData: DimensionsData;
  selectedTags: ProductTag[];
}) => {
  const { screenSize } = dimensionsData;
  const maxMobileScreenWidth = 640;
  const useMobileDesign = screenSize.width <= maxMobileScreenWidth;

  const selectProductText = 'Программы для вас:';
  const showMoreButtonTitle = 'Ещё предложения';

  const [itemsPerRow, setItemsPerRow] = useState(useMobileDesign ? 1 : 4);
  const [itemsPerPage, setItemsPerPage] = useState(8);

  const [useMobileDesignOnProductItemStyles, setUseMobileDesignOnProductItemStyles] = useState(false);
  useEffect(() => {
    let desktopResponsiveItemsPerRowCount = 1;
    if (!useMobileDesign) {
      if      (screenSize.width > 1280)                  desktopResponsiveItemsPerRowCount = 4;
      else if (screenSize.width > 1024)                  desktopResponsiveItemsPerRowCount = 3;
      else if (screenSize.width > maxMobileScreenWidth)  desktopResponsiveItemsPerRowCount = 2;
    }
    
    const updatedItemsPerRow = useMobileDesign ? 1 : desktopResponsiveItemsPerRowCount;
    setItemsPerRow(updatedItemsPerRow);
    
    const rowsPerPage = useMobileDesign ? 4 : 2;
    setItemsPerPage(updatedItemsPerRow * rowsPerPage);
    
    setUseMobileDesignOnProductItemStyles(desktopResponsiveItemsPerRowCount <= 2);
  }, [screenSize.width])
  

  const productPagesToShow = useSelector((state: RootState) => state.main.productPagesToShow);
  const [showMoreButtonEnabled, setShowMoreButtonEnabled] = useState(false);
  
  // ###################################################################################################
  useEffect(() => {
    const shownItems = productPagesToShow * itemsPerPage;
    if (productItems.length > shownItems) setShowMoreButtonEnabled(true);
    else                                       setShowMoreButtonEnabled(false);
  }, [productItems.length, productPagesToShow, itemsPerPage])
  
  const dispatch = useDispatch();
  const onShowMore = () => {
    const updatedPagesToShow = productPagesToShow + 1;
    dispatch(setProductPagesToShow(updatedPagesToShow));

    const currentMaxItems = updatedPagesToShow * itemsPerPage;
    if (productItems.length > currentMaxItems) setShowMoreButtonEnabled(true);
    else                                          setShowMoreButtonEnabled(false);
  }

  // center product items if amount is less than itemsPerRow
  const visualItemLimit = Math.min(itemsPerRow, productItems.length);
  const gridTemplateStyle: React.CSSProperties = {
    gridTemplateColumns: '1fr '.repeat(visualItemLimit).trimEnd(),
  }

  // ###################################################################################################
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const openModal = () => setModalIsOpen(true);
  const closeModal = () => setModalIsOpen(false);

  const [productData, setProductData] = useState<ProductData>();
  const [selectedVariantId, setSelectedVariantId] = useState<number>();

  const onPurchaseClick = (item: ProductData, variantId: number) => {
    setProductData(item);
    setSelectedVariantId(variantId);
    openModal();
  }

  return (
    <div className="servicesRowsContainerOuter" style={useMobileDesign ? { width: '100%' } : {}}>
      <PurchaseProductModal
        productData={productData!}
        selectedVariantId={selectedVariantId!}
        selectedTags={selectedTags}
        modalIsOpen={modalIsOpen}
        closeModal={closeModal}
      />
      <div className="servicesRowsTitle">{selectProductText}</div>
      <div className="servicesRowsContainer" style={gridTemplateStyle}>
        {productItems.slice(0, productPagesToShow * itemsPerPage).map((item, index) => (
          <ProductItem
            key={`${item.devName}${productItems.length}${index}`}
            productData={item}
            onMoveUserToConsult={onMoveUserToConsult}
            onPurchaseClick={onPurchaseClick}
            useMobileDesign={useMobileDesignOnProductItemStyles}
            showDetailsButton
          />
        ))}
      </div>
      {showMoreButtonEnabled && (
        <button
          onClick={onShowMore}
          className="servicesRowsShowMoreButton app-button-alt"
          style={useMobileDesign ? { maxWidth: '100%' } : {}}
        >
          {showMoreButtonTitle}
        </button>
      )}
    </div>
  );
};

export default ProductsRows;
