import React from 'react';
import { getItemDescriptionNavLink, splitTextsByLineBreak } from "../../../../../utils/helper";
import { productItems } from './products';

const Guide1TextBlock = () => {
  const title = 'Откройте для себя свои потенциальные возможности!';
  const mainText = `Иногда мы чувствуем, что теряемся среди разнообразия выборов и внешних ожиданий, не зная, что на самом деле хотим и кем хотим стать. Этот гайд для тех, кто хочет лучше узнать себя без налета проекций и ожиданий, услышать свое истинное «я хочу» и проложить к нему путь. Он станет надежным компасом в путешествии к самопониманию и осознанности, помогая обрести ясность и уверенность на пути к своим истинным желаниям и целям. Также вы получите действенные практики, которые помогут сделать выбор в случае, если вы на распутье перед принятием важного и сложного решения.
  \r\nПора с этим определяться с помощью:`;
  const listItems = [
    'Инструмент — «Блокнот»',
    'Инструмент — «Время»',
    'Инструмент — «Правда»',
    'Инструмент — «Пространство»',
    'Инструмент — «Интерес»',
    'Инструмент — «Конверт — как принять решение»',
    'Инструмент — «Разрешить себе»',
    'Инструмент — «Работа с чувством вины»',
    'Инструмент — «Посыл»',
    'Инструмент — «Путешествие в будущее»',
    'Инструмент — «Насмотренность»',
    'Инструмент — «Страх»',
    'Инструмент — «Всё получилось»',
    'Если ты не знаешь, чем хочешь заниматься',
  ];

  return (
    <div className='itemDescriptionPageTextBlock'>
      <div className='itemDescriptionPageTextBlockTitle'>{title}</div>
      {splitTextsByLineBreak(mainText).map(text => <div className='itemDescriptionPageTextBlockText'>{text}</div>)}
      <ul className="itemDescriptionPageTextBlockItemsList" style={{ marginLeft: 25 }}>
        {listItems.map((listItem) => <li key={listItem}>{listItem}</li>)}
      </ul>
    </div>
  );
};

const Guide2TextBlock = () => {
  const title1 = 'Откройте новые горизонты личной силы и гармонии!';
  const text1 = `Личные границы гораздо шире, чем просто умение сказать «НЕТ» и отстоять свою точку зрения. Там есть более глубинные слои, про них и пойдет речь в гайде. Этот гайд раскроет силу вашего внутреннего мира и поможет вам строить более осознанные связи с миром вокруг. `;
  
  const title2 = 'Вы узнаете:';
  const listItems2 = [
    'Почему на самом деле трудно сказать нет',
    'Глубинные страхи в теме границ и практики на их смягчение',
    'Очень подробно раскрыта тема отвергнутости и ее важных смыслов',
  ];

  const title3 = 'Бонусы:';
  const listItems3 = [
    'Малоизвестные факты про границы',
    'Важное про родовую систему',
    'Влияние на границы первого года жизни',
  ];

  return (
    <div className='itemDescriptionPageTextBlock'>
      <div className='itemDescriptionPageTextBlockTitle'>{title1}</div>
      {splitTextsByLineBreak(text1).map(text => <div className='itemDescriptionPageTextBlockText'>{text}</div>)}

      <div className='itemDescriptionPageTextBlockTitle'>{title2}</div>
      <ul className="itemDescriptionPageTextBlockItemsList">
        {listItems2.map((listItem) => <li key={listItem}>{listItem}</li>)}
      </ul>

      <div className='itemDescriptionPageTextBlockTitle'>{title3}</div>
      <ul className="itemDescriptionPageTextBlockItemsList">
        {listItems3.map((listItem) => <li key={listItem}>{listItem}</li>)}
      </ul>
    </div>
  );
};

const Guide3TextBlock = () => {
  const title1 = 'Выход за рамки привычного или где мой ресурс?';
  const text1 = `Этот гайд поможет лучше узнать себя и свои самые потаенные уголки, а вдруг там что-то ценное?
  \r\nОдин из самых частых запросов - нехватка или отсутствие сил. Но верный вопрос -  не «где взять силы?», а «куда они медленно, но верно уходят?» Для того, чтобы сосуд наполнить, в нем не должно быть пробоин. Как найти эти «пробоины» и что с ними сделать – в этом гайде. Вы исследуете свои внутренние процессы, помогая заполнить «пробоины» и обрести энергию и мотивацию.`;

  return (
    <div className='itemDescriptionPageTextBlock'>
      <div className='itemDescriptionPageTextBlockTitle'>{title1}</div>
      {splitTextsByLineBreak(text1).map(text => <div className='itemDescriptionPageTextBlockText'>{text}</div>)}
    </div>
  );
};

const Guide4TextBlock = () => {
  const text1 = `Все мы слышали фразу – вытесненное. Но как именно работает этот механизм вытеснения? Интуитивно понятно, что человек может вытеснять негативные чувства – гнев, злость и тд. Но часто вытесняется нужное и хорошее: положительные аспекты - успех, проявленность, привлекательность. А также что происходит с не менее важными частями личности, как мужской аспект и детское восприятие мира? Они часто оказываются забытыми или потерянными в этом процессе. Почему так происходит и как их вернуть обратно – в гайде. Теория, подкрепленная практикой и конкретные шаги для возвращения этих аспектов в нашу жизнь.`;

  return (
    <div className='itemDescriptionPageTextBlock'>
      {splitTextsByLineBreak(text1).map(text => <div className='itemDescriptionPageTextBlockText'>{text}</div>)}
    </div>
  );
};

const Guide5TextBlock = () => {
  const title1 = 'Тайны создания изобилия и энергии без зависимости от финансов.';
  const text1 = `В этом Гайде кратко и емко вы найдете ответ на вопрос: как создать без помощи денег энергию изобилия?
  \r\nА что так можно? Нужно! Поскольку именно с этого начинается ваша изобильная жизнь.
  \r\nЭтот гайд проложит путь к вашему собственному источнику изобилия, вдохновляя на изменения и успехи в вашей жизни.
  \r\nЦенности этого гайда заключаются в его способности вдохновить и обучить людей созданию изобилия и энергии, не зависящих от внешних обстоятельств. Он призван помочь людям осознать свои внутренние ресурсы, развить внутреннюю силу и уверенность, а также научиться привлекать и создавать позитивные изменения в жизни. Гайд обеспечивает практические инструменты и конкретные шаги для достижения желаемых результатов и раскрытия своего потенциала.`;

  return (
    <div className='itemDescriptionPageTextBlock'>
      <div className='itemDescriptionPageTextBlockTitle'>{title1}</div>
      {splitTextsByLineBreak(text1).map(text => <div className='itemDescriptionPageTextBlockText'>{text}</div>)}
    </div>
  );
};

const Guide6TextBlock = () => {
  const title1 = 'Преодоление разлуки с уверенностью и мудростью.';
  const text1 = `Жизнь невозможна без разлук по совершенно разным причинам с разными людьми (партнер, друг, родственники и др).  Даже завершение какого-то этапа своей жизни – тоже ощущается как разлука и нуждается в горевании и отпускании. Проживание разлуки – это работа с определенными этапами. В гайде раскрываются эти этапы, чтобы максимально мягко и экологично помочь вам их прожить. Теория, подкрепленная практикой.
  \r\nЦенности этого гайда заключаются в том, что он помогает людям найти способы мягкого и экологичного проживания различных видов разлук и расставаний. Он предлагает не только теоретические знания о различных этапах разлуки, но и конкретные практические инструменты для эффективного преодоления этих сложных моментов. Гайд помогает людям найти внутреннюю силу и уверенность в собственных способностях справиться с изменениями и потерями, которые они испытывают в жизни.`;

  return (
    <div className='itemDescriptionPageTextBlock'>
      <div className='itemDescriptionPageTextBlockTitle'>{title1}</div>
      {splitTextsByLineBreak(text1).map(text => <div className='itemDescriptionPageTextBlockText'>{text}</div>)}
    </div>
  );
};

const Guide7TextBlock = () => {
  const title1 = 'Исследуйте свой стресс и научитесь преодолевать его.';
  const text1 = `Все мы знаем о стрессе. Человек может годами жить в латентном стрессе и даже не замечать этого, принимая это состояние за норму. Часто мы недооцениваем влияние стресса на наше физическое и эмоциональное благополучие, игнорируя его подводные камни и последствия. Погружение в глубины стресса с помощью этого гайда позволит нам понять его истоки и научиться эффективно справляться с этим состоянием, обретая внутренний покой и гармонию.`;
  
  const recommendedProductRoute = productItems[15].productRoute;
  const recommendationHref = getItemDescriptionNavLink(recommendedProductRoute);

  return (
    <div className='itemDescriptionPageTextBlock'>
      <div className='itemDescriptionPageTextBlockTitle'>{title1}</div>
      {splitTextsByLineBreak(text1).map(text => <div className='itemDescriptionPageTextBlockText'>{text}</div>)}

      <div className='itemDescriptionPageTextBlockText'>
        В дополнение рекомендуем гайд: <a className='opacity-on-hover link' href={recommendationHref} target="_blank" rel="noreferrer">{'Методы самопомощи при стрессе и панических атаках.'}</a>
      </div>
    </div>
  );
};

const Guide8TextBlock = () => {
  const title1 = 'Преодоление панических атак: шаг за шагом к внутреннему спокойствию.';
  const text1 = `В последнее время возросло количество людей с паническими атаками. Они могут быть ошеломляющими и запутанными, заставляя чувствовать себя потерянными и беззащитными. При остром состоянии не до поиска смыслов и причин - в первую очередь важно нейтрализовать это состояние с помощью простых и понятных шагов. О них речь в гайде. Также гайд предлагает не только поддержку в момент кризиса, но и обучение эффективным стратегиям для повседневной практики, способствующим укреплению психологической стойкости в долгосрочной перспективе.`;
  
  const recommendedProductRoute = productItems[8].productRoute;
  const recommendationHref = getItemDescriptionNavLink(recommendedProductRoute);

  return (
    <div className='itemDescriptionPageTextBlock'>
      <div className='itemDescriptionPageTextBlockTitle'>{title1}</div>
      {splitTextsByLineBreak(text1).map(text => <div className='itemDescriptionPageTextBlockText'>{text}</div>)}

      <div className='itemDescriptionPageTextBlockText'>
        В дополнение рекомендуем гайд: <a className='opacity-on-hover link' href={recommendationHref} target="_blank" rel="noreferrer">{'Стресс. Важные моменты.'}</a>
      </div>
    </div>
  );
};

const Guide9TextBlock = () => {
  const title1 = 'Возвращение к себе: путь к осознанию и принятию себя';
  const text1 = `Гайд полностью состоит из практики, направленной на ситуацию, где вы не смогли принять себя, свой выбор, свое решение, свою, как кажется, ошибку, свои действия. Возможно, это была не одна такая ситуация. Практику можно делать сколько угодно раз к каждой такой ситуации, чтобы, буквально, за руку вернуть себя оттуда в настоящее и вернуть себе себя.`;

  return (
    <div className='itemDescriptionPageTextBlock'>
      <div className='itemDescriptionPageTextBlockTitle'>{title1}</div>
      {splitTextsByLineBreak(text1).map(text => <div className='itemDescriptionPageTextBlockText'>{text}</div>)}
    </div>
  );
};

const Guide10TextBlock = () => {
  const title1 = 'Раскрой свой потенциал: на пути к истинной самореализации';
  const text1 = `Тема самореализации волнует многих. Эта тема шире, чем только профессиональная деятельность человека, и глубже, чем фраза «просто встань, иди и делай», поскольку в теме самореализации много блоков. Пока они есть – путь к самореализации может быть сложным и полным трудностей. Что именно препятствует успешной самореализации и как это изменить – об этом пойдет речь в гайде.
  \r\nБонусом является трансформационная практика, помогающая проработать личные установки и достичь новых высот в саморазвитии.`;

  return (
    <div className='itemDescriptionPageTextBlock'>
      <div className='itemDescriptionPageTextBlockTitle'>{title1}</div>
      {splitTextsByLineBreak(text1).map(text => <div className='itemDescriptionPageTextBlockText'>{text}</div>)}
    </div>
  );
};

const Guide11TextBlock = () => {
  const title1 = 'Открытие новых горизонтов: ментальное расширение как ключевой этап перемен';
  const text1 = `Часто человек ощущает, что живет в жёстких рамках, тисках, живет не свою подлинную жизнь, где он свободен и раскрепощен в своих проявлениях. Важным этапом перехода из одного состояния в другое является ментальное расширение. Что оно подразумевает и из чего состоит – об этом речь в гайде. Теория, подкрепленная практикой.
  \r\nТакже в этом гайде детально и разносторонне рассматривается тема: я боюсь осуждения, критики, негативного мнения других. Этот страх очень мешает проявиться.  Но это лишь верхушка айсберга, а что скрывается в его темных водах? – ответы в гайде.`;

  return (
    <div className='itemDescriptionPageTextBlock'>
      <div className='itemDescriptionPageTextBlockTitle'>{title1}</div>
      {splitTextsByLineBreak(text1).map(text => <div className='itemDescriptionPageTextBlockText'>{text}</div>)}
    </div>
  );
};

const Guide12TextBlock = () => {
  const title1 = 'Раскрытие женской силы: путеводитель по расширению и блокировке женской энергии';
  const text1 = `В последнее время много говорят про женскую энергию. Тема очень интересная, но не с точки зрения: «дыши маткой и будет тебе счастье», а с точки зрения бессознательного, квантовой психологии и физики. Этот гайд и для женщин, и для мужчин, поскольку мужская и женская энергия есть в каждом из нас и они неразрывно связаны друг с другом.`;

  return (
    <div className='itemDescriptionPageTextBlock'>
      <div className='itemDescriptionPageTextBlockTitle'>{title1}</div>
      {splitTextsByLineBreak(text1).map(text => <div className='itemDescriptionPageTextBlockText'>{text}</div>)}
    </div>
  );
};

const Guide13TextBlock = () => {
  const title1 = 'Пять ключей к внутреннему спокойствию: техника самостоятельной трансформации.';
  const text1 = `Мы живем в мире высоких скоростей. За день в калейдоскопе нашего внутреннего мира одни состояния стремительно сменяются другими и часто это неприятно.  Что делать, если внутри довлеет какое-то некомфортное состояние, а обратиться к психологу сейчас нет возможности? – ответы в гайде. 
  \r\nОн состоит из 5-ти правил, которые важно применить к своему состоянию (любому) для его трансформации. Это поможет снять эмоциональное напряжение, изменить свое восприятие и почувствовать облегчение.`;

  return (
    <div className='itemDescriptionPageTextBlock'>
      <div className='itemDescriptionPageTextBlockTitle'>{title1}</div>
      {splitTextsByLineBreak(text1).map(text => <div className='itemDescriptionPageTextBlockText'>{text}</div>)}
    </div>
  );
};

const GuideTextBlocks = {
  Guide1TextBlock,
  Guide2TextBlock,
  Guide3TextBlock,
  Guide4TextBlock,
  Guide5TextBlock,
  Guide6TextBlock,
  Guide7TextBlock,
  Guide8TextBlock,
  Guide9TextBlock,
  Guide10TextBlock,
  Guide11TextBlock,
  Guide12TextBlock,
  Guide13TextBlock,
}

export default GuideTextBlocks;
