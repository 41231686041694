import React from 'react';
import { SocialMediaButtonData } from '../../utils/types';
import './SocialMediaButton.scss';

const SocialMediaButton = ({ item }: { item: SocialMediaButtonData }) => {
  return (
    <a href={item.href} key={item.href} target="_blank">
      <img
        className="socialMediaButtonImage"
        src={item.icon}
        onMouseOver={(e) => (e.currentTarget.src = item.hoverIcon)}
        onMouseOut={(e) => (e.currentTarget.src = item.icon)}
      />
    </a>
  );
};

export default SocialMediaButton;
