import { privacyPolicyHref } from '../../utils/appConstants';
import { colors } from '../../utils/themeConstants';

const PrivacyPolicyTextBlock = ({
  className,
  mainText = 'Вы даёте своё ',
  linkText = 'согласие на обработку персональных данных',
}: {
  className: string;
  mainText?: string;
  linkText?: string;
}) => {
  return (
    <div className={className}>
      {mainText}
      <a
        href={privacyPolicyHref}
        className="link opacity-on-hover"
        style={{ color: colors.accent, fontWeight : 600 }}
        target="_blank"
      >
        {linkText}
      </a>
    </div>
  );
};

export default PrivacyPolicyTextBlock;
