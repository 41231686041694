import { useMemo } from 'react';
import { ProductType } from '../../../../../utils/enums';
import images from '../../../../../utils/images';
import { ProductData } from '../../../../../utils/types';
import './ProductItemModal.scss';

const ProductItemModal = ({
  productData,
  selectedVariantId,
}: {
  productData: ProductData;
  selectedVariantId: number;
}) => {
  const currentData = productData.variants[selectedVariantId];

  const title = useMemo(() => {
    switch(productData.type) {
      case ProductType.TariffPlan: return productData.title;
      case ProductType.Guide:      return `${productData.prefix} «${productData.title}»`;
      default:                     return productData.title;
    }
  }, [productData, selectedVariantId]);
  
  return (
    <div className="modalProductItemContainer">
      <div>
        <div className="modalProductItemTitle" style={{ backgroundImage: `url(${productData.titleBackground})` }}>
          {title}
        </div>
        {productData.type === ProductType.Guide && <div className="modalProductItemTextDescription">
          {productData.descriptionShort}
        </div>}
        <div className="modalProductItemIncludedBlock">
          {currentData.includesArray.map((includedItem) => (
            <div className="modalProductItemIncludedItem" key={`${includedItem.optionName}${includedItem.note}`}>
              <div className="modalProductItemIncludedItemText">{includedItem.optionName}</div>
              <div className="modalProductItemIncludedItemText" style={{ whiteSpace: 'nowrap' }}>
                {includedItem.note}
              </div>
            </div>
          ))}
        </div>
        <div className="modalProductItemIncludedBonusesBlock">
          {currentData.bonuses?.map((bonusItem) => (
            <div className="modalProductItemIncludedBonusItem" key={`${bonusItem.optionName}${bonusItem.note}`}>
              <img className="modalProductItemIncludedBonusItemIcon" src={images.servicesGiftIcon} />
              <div className="modalProductItemIncludedItemText">{bonusItem.optionName}</div>
              <div className="modalProductItemIncludedItemText" style={{ whiteSpace: 'nowrap' }}>
                {bonusItem.note}
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="modalProductItemPriceAndDuration">
        <div className="modalProductItemPrice">{`${currentData.price}`}</div>
        <div
          className={'modalProductItemDuration'}
          style={{ ...(!currentData.durationText && { display: 'none' }) }}
        >
          {currentData.durationText}
        </div>
      </div>
    </div>
  );
};

export default ProductItemModal;
