import React from 'react';

import images from '../../../../utils/images';
import { DimensionsData } from '../../../../utils/types';
import RequestCallForm from '../../../../components/RequestCallForm/RequestCallForm';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../redux/store';
import './Consult.scss';

interface ConsultBlockData {
  blockTitle: string;
  blockSubtitle: string;
  rightImage: string,
  rightImageStyles: React.CSSProperties;
  backgroundImageStyles: React.CSSProperties;
  buttonText: string;
}

const Consult = ({
  blockRef,
  dimensionsData,
  isFirstSection,
}: {
  blockRef?: React.RefObject<HTMLDivElement>;
  dimensionsData: DimensionsData;
  isFirstSection?: boolean;
}) => {
  const consultCallData = useSelector((state: RootState) => state.main.consultCallData);

  const consultData1: ConsultBlockData = {
    blockTitle: 'Не можете определиться или у вас остались вопросы?',
    blockSubtitle: 'Наши специалисты свяжутся с вами в ближайшее время',
    rightImage: images.consult23Cards,
    rightImageStyles: {
      maxWidth: '50%',
      maxHeight: '80%',
      right: 25,
    },
    backgroundImageStyles: {
      minHeight: 392,
      backgroundImage: `url(${images.consult1Background})`,
      backgroundPosition: 'right 57px center',
      backgroundSize: 'auto 100%',
      backgroundRepeat: 'no-repeat',
    },
    buttonText: 'Подобрать программу',
  };

  const consultData2: ConsultBlockData = {
    // blockTitle: 'Нужна консультация?',
    blockTitle: 'Оформить покупку',
    blockSubtitle:'Мы перезвоним вам в кратчайшие сроки!',
    rightImage: images.consult2RightImageBlock,
    rightImageStyles: {
      maxWidth: '50%',
      maxHeight: '120%',
      right: 40,
    },
    backgroundImageStyles: {
      backgroundImage: `url(${images.consult2Background})`,
      backgroundPosition: 'right',
      backgroundSize: 'auto 100%',
      backgroundRepeat: 'no-repeat',
    },
    buttonText: 'Перезвоните мне',
  };

  const consultData1Mobile: ConsultBlockData = {
    ...consultData1,
    rightImage: '',
    rightImageStyles: {
      display: 'none',
    },
    backgroundImageStyles: {},
  };

  const consultData2Mobile: ConsultBlockData = {
    ...consultData2,
    rightImage: '',
    rightImageStyles: {
      display: 'none',
    },
    backgroundImageStyles: {},
  };

  const card1 = { icon: images.strength, text: 'Новое качество жизни' }
  const card2 = { icon: images.explosion, text: 'Увеличение продуктивности' }
  const card3 = { icon: images.run, text: 'Путь к гармонии и успеху' }

  const { isVerticallyOriented: useMobileDesign } = dimensionsData;

  const firstConsultData = useMobileDesign ? consultData1Mobile : consultData1;
  const secondConsultData = useMobileDesign ? consultData2Mobile : consultData2;
  const consultDesignData = isFirstSection ? firstConsultData : secondConsultData;

  return (
    <div className="consultContainer" ref={blockRef}>
      <div className="consultInnerContainer" style={consultDesignData.backgroundImageStyles}>
        <div className="consultInnerColumn">
          <div className="consultTitle">{consultDesignData.blockTitle}</div>
          <div className="consultSubtitle">{consultDesignData.blockSubtitle}</div>
          {isFirstSection && (
            <div className="consultCards">
              <div className="consultCard consultCard1">
                <img src={card1.icon} className="consultCardIcon" />
                {card1.text}
              </div>
              <div className="consultCard consultCard2">
                <img src={card2.icon} className="consultCardIcon" />
                {card2.text}
              </div>
              <div className="consultCard consultCard3">
                <img src={card3.icon} className="consultCardIcon" />
                {card3.text}
              </div>
            </div>
          )}
          <RequestCallForm
            consultData={consultCallData}
            callMeButtonText={consultDesignData.buttonText}
            containerStyle={{ ...(!isFirstSection && { marginTop: 0 }) }}
          />
        </div>
        {!useMobileDesign && (
          <img src={consultDesignData.rightImage} alt="" className='consultRightImage' style={consultDesignData.rightImageStyles} />
        )}
      </div>
    </div>
  );
};

export default Consult;
