import React, { useState } from 'react';
import {
  companyMail,
  companyMailHref,
  companyPhone1,
  companyPhone1Href,
  socialMediaButtons,
} from '../../utils/appConstants';
import images from '../../utils/images';
import { DimensionsData, MenuItem } from '../../utils/types';
import LogoBlock from '../LogoBlock/LogoBlock';
import HeaderMobileMenuModal from './HeaderMobileMenuModal';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { setSectionToScrollTo } from '../../redux/mainReducer';
import { PagePath, SectionName } from '../../utils/enums';
import SocialMediaButton from '../SocialMediaButton/SocialMediaButton';
import './Header.scss';
import { productRoutes } from '../..';

const Header = ({
  dimensionsData,
  hideSectionLinks,
}: {
  dimensionsData: DimensionsData;
  hideSectionLinks: boolean;
}) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const scrollToSection = async (sectionName: SectionName) => {
    dispatch(setSectionToScrollTo(sectionName));
    if (menuIsOpen) setMenuIsOpen(false);

    // item description page has it's own faq and consult section, do not navigate back to main page
    if (
      productRoutes.includes(location.pathname) &&
      (sectionName === SectionName.FAQ || sectionName === SectionName.ConsultSection2)
    ) {
      return;
    }

    if (location.pathname !== PagePath.MainPage) navigate(PagePath.MainPage);
  };

  const menuItems: MenuItem[] = [
    {
      text: 'Программы и услуги',
      onClick: () => scrollToSection(SectionName.Products),
    },
    {
      text: 'Частые вопросы',
      onClick: () => scrollToSection(SectionName.FAQ),
    },
  ];

  const companyPhoneComment = 'работаем по всей России';

  const [menuIsOpen, setMenuIsOpen] = useState(false);

  const { isVerticallyOriented: useMobileDesign } = dimensionsData;

  if (menuIsOpen) {
    return (
      <HeaderMobileMenuModal
        closeModal={() => setMenuIsOpen(false)}
        scrollToConsultSection={() => scrollToSection(SectionName.ConsultSection2)}
        companyMail={companyMail}
        companyMailHref={companyMailHref}
        companyPhone={companyPhone1}
        companyPhoneHref={companyPhone1Href}
        menuItems={menuItems}
      />
    );
  }

  return (
    <div className="headerContainer">
      <LogoBlock
        isVertical={useMobileDesign}
        height={useMobileDesign ? 40 : undefined}
        textWidth={useMobileDesign ? 61 : undefined}
        isClickable={true}
      />

      {!hideSectionLinks && (
        <div className="headerMenuContainer headerHideOnMobile">
          {menuItems.map((item) => (
            <div className="headerMenuItem link opacity-on-hover" onClick={item.onClick} key={item.text}>
              {item.text}
            </div>
          ))}
        </div>
      )}

      {!hideSectionLinks && (
        <div className="headerSocialMediaButtonsContainer headerHideOnMobile">
          {socialMediaButtons.map((item) => (
            <SocialMediaButton item={item} key={item.href} />
          ))}
        </div>
      )}

      {!hideSectionLinks && (
        <button
          onClick={() => scrollToSection(SectionName.ConsultSection2)}
          className="app-button headerConsultButton headerHideOnMobile"
        >
          <span className="headerConsultButtonText">{'Перезвоните мне'}</span>
        </button>
      )}

      <div className="headerCompanyPhoneBlock">
        <a className="headerCompanyPhone link opacity-on-hover" href={companyPhone1Href}>
          {companyPhone1}
        </a>
        <div className="headerCompanyPhoneComment">{companyPhoneComment}</div>
      </div>

      {!hideSectionLinks && (
        <div className="headerMenuButton opacity-on-hover" onClick={() => setMenuIsOpen(true)}>
          <img src={images.headerMobileMenuIcon} className="headerMenuButtonIcon" />
        </div>
      )}
    </div>
  );
};

export default Header;
