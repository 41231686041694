import React, { useEffect, useState } from 'react';
import images from '../../../../utils/images';
import { ConsultCallData, DimensionsData, ProductData } from '../../../../utils/types';
import { TariffPlanDevName } from './constants/constants';
import ProductsRows from './components/ProductsRows';
import { SectionName, ProductTag as Tag } from '../../../../utils/enums';
import { useDispatch } from 'react-redux';
import { setConsultCallData, setSectionToScrollTo } from '../../../../redux/mainReducer';
import { productItems, sortedProductItems } from './constants/products';
import './Products.scss';

const Products = ({
  servicesRef,
  servicesListRef,
  dimensionsData,
}: {
  servicesRef: React.RefObject<HTMLDivElement>;
  servicesListRef: React.RefObject<HTMLDivElement>;
  dimensionsData: DimensionsData;
}) => {
  interface SelectableItem {
    icon?: string;
    isSelected?: boolean;
    title: Tag;
  }

  const categoryTagItems: SelectableItem[] = [
    { title: Tag.man,             icon: images.man },
    { title: Tag.woman,           icon: images.girl },
    { title: Tag.child,           icon: images.boy },
    { title: Tag.parents,         icon: images.couple },
    { title: Tag.family,          icon: images.family },
  ];

  const ageTagItems: SelectableItem[] = [
    { title: Tag.ageRange1, },
    { title: Tag.ageRange2, },
    { title: Tag.ageRange3, },
    { title: Tag.ageRange4, },
    { title: Tag.ageRange5, },
    { title: Tag.ageRange6, },
    { title: Tag.ageRange7  },
    { title: Tag.ageRange8, },
  ];

  const goalTagItemsInitial: SelectableItem[] = [
    { title: Tag.pregnancy,       icon: images.pregnant },
    { title: Tag.sport,           icon: images.run },
    { title: Tag.diet,            icon: images.salad },
    { title: Tag.treatment,       icon: images.hospital },
    { title: Tag.international,   icon: images.planet },
    { title: Tag.chronicDiseases, icon: images.xray },
    { title: Tag.psychAssistance, icon: images.meditate },
    { title: Tag.sleepIssues,     icon: images.insomnia },
    { title: Tag.noEnergy,        icon: images.sleep },
    { title: Tag.stopSmoking,     icon: images.nosmoking },
    { title: Tag.stopAlcohol,     icon: images.wine },
    { title: Tag.badHabits,       icon: images.stop },
    { title: Tag.anger,           icon: images.anger },
    { title: Tag.depression,      icon: images.tear },
    { title: Tag.apathy,          icon: images.apathy },
    { title: Tag.complexCases,    icon: images.magnify },
    // { title: Tag.prophylaxis,  icon: images.cool },
  ];

  const [selectedCategoryTag, setSelectedCategoryTag] = useState('');
  const selectCategory = (toggledItem: SelectableItem) => {
    const item = toggledItem.title;
    if (selectedCategoryTag !== toggledItem.title) setSelectedCategoryTag(item);
    else                                           setSelectedCategoryTag('');
  }

  const [selectedAgeTag, setAgeTag] = useState('');
  const selectAge = (toggledItem: SelectableItem) => {
    const item = toggledItem.title;
    if (selectedAgeTag !== toggledItem.title) setAgeTag(item);
    else                                      setAgeTag('');
  }

  const [goalTagItems, setGoalTagItems] = useState(goalTagItemsInitial);
  const toggleGoalTagItemSelection = (toggledItem: SelectableItem) => {
    const itemId = goalTagItems.findIndex(item => item.title === toggledItem.title);
    const updatedTagItems = [...goalTagItems];
    updatedTagItems[itemId].isSelected = !updatedTagItems[itemId].isSelected;
    setGoalTagItems(updatedTagItems);
  }

  // ###################################################################################################
  const selectedGoalTags = goalTagItems.filter(tag => tag.isSelected);
  const filteredProductItems = sortedProductItems.filter(productItem => selectedGoalTags.every(selectedTag => productItem.tags.includes(selectedTag.title)));
  const personalProgram = productItems.find(item => item.devName === TariffPlanDevName.personalProgram);

  // ###################################################################################################
  const [selectedTags, setSelectedTags] = useState<Tag[]>([]);
  useEffect(() => {
    const selectedTagsStrings = selectedGoalTags.map(item => item.title);
    const updatedConsultTags = selectedTagsStrings;
    if (selectedAgeTag)      updatedConsultTags.unshift(selectedAgeTag      as Tag); // age range is second
    if (selectedCategoryTag) updatedConsultTags.unshift(selectedCategoryTag as Tag); // category is first

    setSelectedTags(updatedConsultTags);

  }, [selectedCategoryTag, selectedAgeTag, goalTagItems]);

  // ###################################################################################################
  const dispatch = useDispatch();
  const onMoveUserToConsult = (
    productData: ProductData,
    selectedVariantId: number,
    consultSection: SectionName
  ) => {
    const programDevName = productData.devName;
    const selectedVariantData = productData.variants[selectedVariantId];
    const durationInMonths = selectedVariantData.durationInMonths;

    const selectedTagsStrings = selectedGoalTags.map((item) => item.title);

    const updatedConsultTags = selectedTagsStrings;
    if (selectedAgeTag)      updatedConsultTags.unshift(selectedAgeTag      as Tag); // age range is second
    if (selectedCategoryTag) updatedConsultTags.unshift(selectedCategoryTag as Tag); // category is first

    const updatedConsultData: ConsultCallData = { programDevName: programDevName, durationInMonths, tags: updatedConsultTags }
    dispatch(setConsultCallData(updatedConsultData));
    dispatch(setSectionToScrollTo(consultSection));
  }

  const { isVerticallyOriented: useMobileDesign } = dimensionsData;

  const mobileTitle = 'Программы и услуги';
  const mobileNote = 'Выберите опции и цели, а мы подберём подходящую программу:';

  const category = 'Выберите категорию:';
  const age = 'Возраст:';
  const goals = 'Цели:';

  const TagItem = ({ item, isSelected, onSelect }: { item: SelectableItem; isSelected?: boolean; onSelect: (item: SelectableItem) => void }) => {
    return (
      <div
        onClick={() => onSelect(item)}
        className={`servicesOptionItem accent-border-on-hover ${isSelected ? 'button-base' : ''}`}
      >
        {item.icon && <img src={item.icon} alt="" className='servicesOptionItemIcon' />}
        {item.title}
      </div>
    )
  }

  return (
    <div className="servicesContainer" ref={servicesRef}>
      <div className="servicesContainerInner">
        {useMobileDesign && <div className="blockTitleMobile">{mobileTitle}</div>}
        {useMobileDesign && <div className="servicesNote">{mobileNote}</div>}
        <div className="servicesTagsOuterRow">
          <div className="servicesTagsColumnLeft" style={{ flex: 4 }}>
            <div className="servicesTagsBlock" style={{ flex: 1 }}>
              <div className="servicesTagsBlockTitle">{category}</div>
              <div className="servicesOptionSelectionBlock">
                {categoryTagItems.map((item) => (
                  <TagItem
                    item={item}
                    isSelected={item.title === selectedCategoryTag}
                    onSelect={selectCategory}
                    key={item.title}
                  />
                ))}
              </div>
            </div>
            <div className="servicesTagsBlock" style={{ flex: 1 }}>
              <div className="servicesTagsBlockTitle">{age}</div>
              <div className="servicesOptionSelectionBlock">
                {ageTagItems.map((item) => (
                  <TagItem
                    item={item}
                    isSelected={item.title === selectedAgeTag}
                    onSelect={selectAge}
                    key={item.title}
                  />
                ))}
              </div>
            </div>
          </div>
          <div className="servicesTagsBlock" style={{ flex: 6 }}>
            <div className="servicesTagsBlockTitle">{goals}</div>
            <div className="servicesOptionSelectionBlock">
              {goalTagItems.map((item) => (
                <TagItem
                  item={item}
                  isSelected={item.isSelected}
                  onSelect={toggleGoalTagItemSelection}
                  key={item.title}
                />
              ))}
            </div>
          </div>
        </div>
      </div>

      <div className="servicesContainerRowsOuter" ref={servicesListRef}>
        <ProductsRows
          productItems={filteredProductItems.length ? filteredProductItems : [personalProgram!]}
          selectedTags={selectedTags}
          onMoveUserToConsult={onMoveUserToConsult}
          dimensionsData={dimensionsData}
        />
      </div>
    </div>
  );
};

export default Products;
