import React from 'react';
import { useOnReturnToMainPageButtonClick } from '../../utils/hooks';
import images from '../../utils/images';
import './LogoBlock.scss';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setSectionToScrollTo } from '../../redux/mainReducer';
import { PagePath, SectionName } from '../../utils/enums';

const LogoBlock = ({
  isVertical,
  isClickable,
  height,
  textWidth,
}: {
  isClickable: boolean;
  isVertical?: boolean;
  height?: number;
  textWidth?: number;
}) => {
  const returnToMainPage = useOnReturnToMainPageButtonClick();

  const dispatch = useDispatch();
  const location = useLocation();
  const onLogoClick = () => {
    // when on main page, scroll to "join us" section
    if (location.pathname === PagePath.MainPage) {
      dispatch(setSectionToScrollTo(SectionName.JoinUs));
    }
    // otherwise, return to main page
    else {
      returnToMainPage();
    }
  }

  const containerClassName = isVertical ? 'logoContainerVertical' : 'logoContainer';
  const clickableStyles: React.CSSProperties = {
    cursor: 'pointer',
  };

  const logoHeight = (isVertical ? 40 : height) || undefined;
  const logoTextWidth = (isVertical ? 61 : textWidth) || undefined;

  return (
    <div
      className={containerClassName}
      style={isClickable ? clickableStyles : undefined}
      onClick={isClickable ? onLogoClick : undefined}
    >
      <img
        src={images.logoIcon}
        alt=""
        className='logoImage'
        style={{ height: logoHeight }}
      />
      <img
        src={images.logoText}
        alt={isClickable ? 'На главную' : ''}
        className='logoTextImage'
        style={{ width: logoTextWidth }}
      />
    </div>
  );
}

export default LogoBlock;
