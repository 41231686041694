import React, { useMemo } from 'react'
import images from '../../../utils/images';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './ItemDescriptionPageServiceCards.scss';
import { ProductData, ServiceData } from '../../../utils/types';
import { ProductType } from '../../../utils/enums';

const ItemDescriptionPageServiceCards = ({
  productData,
  subServicesDataArray,
}: {
  productData: ProductData;
  subServicesDataArray: ServiceData[];
}) => {
  const getAccessText = useMemo(() => {
    switch(productData.type) {
      case ProductType.TariffPlan: return 'Получите доступ к продуктам программы:';
      case ProductType.Guide:      return 'Получите доступ:';
      default:                     return 'Получите доступ:';
    }
  }, []);
  
  if (!subServicesDataArray?.length) return null;
  
  const gradientStyling = 'linear-gradient(180deg, rgba(33, 33, 33, 0) 23%, rgba(33, 33, 33, 0.7) 72%)';
  const showCarouselOnMobile = subServicesDataArray.length > 2;

  return (
    <div className="itemDescriptionServicesSection">
      <div className="itemDescriptionServicesSectionTitle">{getAccessText}</div>
      <div className="itemDescriptionServicesSectionItemsContainer">
        {subServicesDataArray.map((item, index) => (
          <div
            key={index + item.title}
            className="itemDescriptionServicesSectionItem"
            style={{ backgroundImage: `${gradientStyling}, url(${item.image})` }}
          >
            <img className="itemDescriptionServicesSectionItemLockImage" src={images.lockIcon} />
            <div className="itemDescriptionServicesSectionItemText">{item.title}</div>
          </div>
        ))}
      </div>

      {!showCarouselOnMobile && <div className="itemDescriptionServicesSectionItemsNonCarouselContainerMobile">
        {subServicesDataArray.map((item, index) => (
          <div
            key={index + item.title}
            className="itemDescriptionCardNonCarouselMobileItem"
            ref={(node) => {
              if (node) {
                node.style.setProperty('background-image', `${gradientStyling}, url(${item.image})`, 'important');
              }
            }}
          >
            <img className="itemDescriptionCardCarouselMobileItemLockImage" src={images.lockIconMobile} />
            <div className="itemDescriptionCardCarouselMobileItemText">{item.title}</div>
          </div>
        ))}
      </div>}



      {showCarouselOnMobile && <div className="itemDescriptionServicesSectionItemsContainerMobile">
        <Slider
          className="itemDescriptionCardCarouselMobileContainer"
          swipeToSlide={true}
          arrows={false}
          dots={false}
          variableWidth={false}
          speed={500}
          slidesToScroll={1}

          autoplay
          autoplaySpeed={2500}
          centerMode
          centerPadding='16px'
          infinite
          slidesToShow={2}
          initialSlide={1}
          cssEase={'ease'}
        >
          {subServicesDataArray.map((item, index) => (
            <div
              key={index + item.title}
              className="itemDescriptionCardCarouselMobileItem"
              ref={(node) => {
                if (node) {
                  node.style.setProperty('background-image', `${gradientStyling}, url(${item.image})`, 'important');
                }
              }}
            >
              <img className="itemDescriptionCardCarouselMobileItemLockImage" src={images.lockIconMobile} />
              <div className="itemDescriptionCardCarouselMobileItemText">{item.title}</div>
            </div>
          ))}
        </Slider>
      </div>}
    </div>
  );
};

export default ItemDescriptionPageServiceCards