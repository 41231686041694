import React, { useMemo } from 'react';
import { ProductData, ServiceData } from '../../../utils/types';
import './ItemDescriptionPageDetailsSection.scss';
import { Duration, ProductType } from '../../../utils/enums';
import { ProductDevName } from '../../MainPage/components/5.Products/constants/constants';

const ItemDescriptionPageDetailsSection = ({
  productTitle,
  productData,
  subServicesDataArray,
  selectedVariantId,
  setSelectedVariantId,
  openPurchaseModal,
}: {
  productTitle: string;
  productData: ProductData;
  subServicesDataArray: ServiceData[];
  selectedVariantId: number;
  setSelectedVariantId: (variandId: number) => void;
  openPurchaseModal: () => void;
}) => {
  const currentVariantData = productData.variants[selectedVariantId];

  const getItemNote = (serviceDetailsItem: ServiceData) => {
    if (productData.devName === ProductDevName.medconcierge) return '';

    const includedItems = currentVariantData.includesArray;
    const itemData = includedItems.find(it => it.optionName.includes(serviceDetailsItem.title));
    if (itemData?.note && itemData.note !== Duration.d1) {
      return ` — ${itemData.note}`
    }
    return '';
  };

  const whatIsIncludedText = useMemo(() => {
    switch(productData.type) {
      case ProductType.TariffPlan: return 'Что входит в программу?';
      case ProductType.Guide:      return 'Что входит в гайд?';
      default:                     return 'Что включено?';
    }
  }, []);

  const productCostText = useMemo(() => {
    switch(productData.type) {
      case ProductType.TariffPlan: return 'Стоимость программы';
      case ProductType.Guide:      return 'Стоимость гайда';
      default:                     return 'Стоимость';
    }
  }, []);

  const isTariffPlan = productData.type === ProductType.TariffPlan;

  return (
    <div className="itemDescriptionDetailsSection">
      <div className="itemDescriptionDetailsSectionFirstBlock">
        <div className="itemDescriptionDetailsSectionFirstBlockTitle">{productTitle}</div>
        {productData.descriptionComponents.map(DescriptionComponent => <DescriptionComponent />)}

        <div className="itemDescriptionDetailsSectionFirstBlockDurationBlock">
          {isTariffPlan && <div className="itemDescriptionDetailsSectionFirstBlockDurationBlockDurationSection">
            <div className="itemDescriptionDetailsSectionFirstBlockDurationBlockDurationSectionTitle">
              {'Выберите период:'}
            </div>
            <div className="itemDescriptionDetailsSectionFirstBlockDurationBlockDurationSectionItems">
              {productData.variants.map((variant, index) => (
                <button
                  key={variant.durationText}
                  onClick={productData.variants.length > 1 ? () => setSelectedVariantId(index) : undefined}
                  className={
                    index === selectedVariantId
                      ? 'itemDescriptionMainSectionFirstBlockDurationButtonActive'
                      : 'itemDescriptionMainSectionFirstBlockDurationButton'
                  }
                  style={{ ...(!variant.durationText && { display: 'none' }) }}
                >
                  {variant.durationText}
                </button>
              ))}
            </div>
          </div>}
          <div className="itemDescriptionDetailsSectionFirstBlockDurationBlockPriceSection">
            <div className="itemDescriptionDetailsSectionFirstBlockDurationBlockPriceSectionTitle">
              {productCostText}
            </div>
            <div className="itemDescriptionDetailsSectionFirstBlockDurationBlockPriceSectionPrice">
              {currentVariantData.price}
            </div>
          </div>
        </div>
        <button
          className="app-button itemDescriptionDetailsSectionFirstBlockPurchaseButton"
          onClick={openPurchaseModal}
        >
          {'Купить'}
        </button>
      </div>

      <div className="itemDescriptionDetailsSectionSecondBlock">
        <div className="itemDescriptionDetailsSectionSecondBlockTitle">{whatIsIncludedText}</div>
        <div className="itemDescriptionDetailsSectionSecondBlockServiceItemsContainer">
          {subServicesDataArray.map((subService) => (
            <div 
              key={JSON.stringify(subService.serviceDescriptions)}
              className="itemDescriptionDetailsSectionSecondBlockServiceItem"
            >
              <div className="itemDescriptionDetailsSectionSecondBlockServiceItemTitle">
                {subService.titleShort || subService.title}{getItemNote(subService)}
                </div>
              <ul className="itemDescriptionDetailsSectionSecondBlockServiceItemPointList">
                {subService.serviceDescriptions.map((description) => <li key={description}>{description}</li>)}
              </ul>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ItemDescriptionPageDetailsSection;
