import React, { useState } from 'react'
import images from '../../../../utils/images';
import './GetFreeGuideBlock.scss';
import GetFreeGuideModal from './components/GetFreeGuideModal';
import { yandexMetrics } from '../../../../utils/yandexMetrics';

const GetFreeGuideBlock = ({
  blockRef,
}: {
  blockRef?: React.RefObject<HTMLDivElement>;
}) => {
  const title = 'Как найти баланс между брокколи и бургерами?';
  const buttonText = 'Скачать бесплатно';
  const getForFreeText = 'ПОЛУЧИТЕ БЕСПЛАТНО';

  const onGetFreeGuideButtonClick = () => {
    openModal();
    yandexMetrics.openFreeGuideForm();
  };

  // ###################################################################################################
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const openModal = () => setModalIsOpen(true);
  const closeModal = () => setModalIsOpen(false);

  return (
    <div className="getFreeGuideBlockContainerOuter">
      <GetFreeGuideModal
        modalIsOpen={modalIsOpen}
        closeModal={closeModal}
      />

      <div className="getFreeGuideBlockContainer" ref={blockRef}>
        <div className="getFreeGuideBlockContent">
          <div className="getFreeGuideBlockContentTitle">{title}</div>

          <div className="getFreeGuideImageAndFlairBlockMobile">
            <img className="getFreeGuideBlockImageMobile" src={images.getFreeGuideBannerMobile}/>
            <div className="getFreeGuideGetForFreeButtonMobile">{getForFreeText}</div>
          </div>


          <div className="getFreeGuideBlockContentDescription">
            Забирайте наш <b>бесплатный гайд</b> по правильному питанию и узнайте все секреты, как сделать ваше меню здоровым, вкусным и полезным одновременно!
          </div>
          <button className="app-button getFreeGuideBlockButton" onClick={onGetFreeGuideButtonClick}>
            {buttonText}
          </button>
        </div>

        <img className="getFreeGuideBlockImage" src={images.getFreeGuideBanner} />
        <div className="getFreeGuideGetForFreeButton">{getForFreeText}</div>
      </div>
    </div>
  );
};

export default GetFreeGuideBlock;
