import images from "../../../../utils/images";
import { ReviewItemData } from "./components/ReviewItem";

const reviewsArray: ReviewItemData[] = [
  {
    productName: 'Программа «ЗОЖ Питание + Спорт»',
    reviewText: 'В этом году я решила изменить свою жизнь и коллега рассказала о программах ETNA. Я получила подробный план питания, учитывающий мои пожелания по похудению и даже непереносимость куриного белка. Роман подробно ответил на все мои вопросы, а также подобрал продукты со списком рецептов. Я даже не думала, что можно так разнообразно питаться и соблюдать здоровое питание!!!',
    reviewerName: 'Наталья Р.',
    reviewerAgeAndLocation: '52 года, г. Москва',
    reviewerImageSrc: images.reviewer1,
  },
  {
    productName: 'Программа «ЗОЖ Питание»',
    reviewText: 'Программа открыла для меня новый подход к питанию. Я всегда думала, что здоровое питание — это сложно и дорого. Но вместе с Романом мы подобрали мне такое меню, которое идеально подходит для моего образа жизни. Теперь я не только наслаждаюсь каждым приёмом пищи, но и чувствую себя лучше. Спасибо за такой подход!',
    reviewerName: 'Елизавета М.',
    reviewerAgeAndLocation: '44 года, г. Екатеринбург',
    reviewerImageSrc: images.reviewer2,
  },
  {
    productName: 'Программа «Медконсьерж»',
    reviewText: '«Медконсьерж» — просто спасение. У меня были сложности с диагнозом, и онлайн-консультация с врачом помогла понять, что делать дальше. Документы проанализировали быстро, а ещё нашли отличного специалиста и клинику для моего случая.',
    reviewerName: 'Любовь С.',
    reviewerAgeAndLocation: '57 лет, г. Нижний Новгород',
    reviewerImageSrc: images.reviewer3,
  },
  {
    productName: 'Гайд «Стресс. Важные моменты»',
    reviewText: 'Я давно искал что‑то, что поможет мне справляться со стрессом, так как испытывал постоянное напряжение на работе и в повседневных делах. Очень простые и понятные советы. Теперь справляться со стрессом стало намного легче (особенно понравились упражнения). Определённо рекомендую!',
    reviewerName: 'Кирилл А.',
    reviewerAgeAndLocation: '31 год, г. Cамара',
    reviewerImageSrc: images.reviewer4,
  },
];

export default reviewsArray;