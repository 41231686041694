// import reportWebVitals from './reportWebVitals';

import React from 'react';
import ReactDOM from 'react-dom/client';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';

import MainPage from './pages/MainPage/MainPage';
import OrderStatusPage from './pages/OrderStatusPage/OrderStatusPage';

import { Provider } from 'react-redux';
import { store } from './redux/store';
import ItemDescriptionPage from './pages/ItemDescriptionPage/ItemDescriptionPage';
import { PagePath } from './utils/enums';
import ValuesPage from './pages/ValuesPage/ValuesPage';

import './components/CommonStyles.scss';
import './index.scss';
import SentFreeGuidePage from './pages/SentFreeGuidePage/SentFreeGuidePage';

export const productRoutes = [
  '/meditsinskaya-pomoshch',
  '/programma-pitaniya-30-dney',
  '/programma-zozh-pitaniye-standard',
  '/programma-zozh-pitaniye-maximum',
  '/programma-zozh-pitaniye-sport-lite',
  '/plan-pitaniya-i-trenirovok-standart',
  '/programma-zozh-pitaniye-sport-maximum',
  '/gayd-stress-vazhnyye-momenty',
  '/gayd-praktikum-samoopredeleniye',
  '/gayd-lichnyye-granitsy',
  '/gayd-cherno-beloye-myshleniye',
  '/gayd-vytesnennoye',
  '/gayd-energiya-izobiliya',
  '/gayd-rasstavaniye-kak-prozhit-ekologichno',
  '/gayd-samopomoshch-pri-stresse-i-panicheskikh-atakakh',
  '/gayd-praktikum-prinyatiye-sebya',
  '/gayd-samorealizatsiya',
  '/gayd-tvoyo-mentalnoye-rasshireniye',
  '/gayd-energeticheskaya-yemkost-zhenshchiny',
  '/gayd-tekhnika-samostoyatelnoy-prorabotki',
];

const router = createBrowserRouter([
  {
    path: PagePath.MainPage,
    element: <MainPage />,
  },
  {
    path: PagePath.OrderStatusPage, // this is where backend navigates us, and should remain the same
    element: <OrderStatusPage />,
  },
  {
    path: PagePath.SentFreeGuidePage,
    element: <SentFreeGuidePage />,
  },
  ...productRoutes.map(route => ({
    path: route,
    element: <ItemDescriptionPage />,
  })),
  {
    path: PagePath.ValuesPage,
    element: <ValuesPage />,
  },
]);


const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <RouterProvider router={router} />
    </Provider>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
