
import images from '../../../../utils/images';
import { learnMoreDataArray } from '../../../ValuesPage/ValuesPage';
import './ValuesBlock.scss';
import { PagePath, SectionName } from '../../../../utils/enums';
import { useDispatch } from 'react-redux';
import { setSectionToReturnTo } from '../../../../redux/mainReducer';
import { Link } from 'react-router-dom';

const ValuesBlock = ({
  blockRef,
}: {
  blockRef: React.RefObject<HTMLDivElement>;
}) => {
  const itemBackgrounds: React.CSSProperties[] = [
    {
      backgroundImage: `url(${images.mainBannerBg1})`,
      backgroundPosition: 'right',
      backgroundSize: 'auto 100%',
      backgroundRepeat: 'no-repeat',
    },
    {},
    {
      backgroundImage: `url(${images.mainBannerBg2})`,
      backgroundPosition: 'right',
      backgroundSize: 'auto 100%',
      backgroundRepeat: 'no-repeat',
    },
  ]

  const getLearnMoreNavLink = (id: number) => `${PagePath.ValuesPage}?id=${id}`;
  const dispatch = useDispatch();
  const onLearnMoreButtonClick = () => {
    dispatch(setSectionToReturnTo(SectionName.Values));
  }

  return (
    <div className="valuesBlockContainer" ref={blockRef}>
      {learnMoreDataArray.map((item, id) => (
        <div key={item.titleShort} className="valuesBlockCardItem" style={itemBackgrounds[id]}>
          <img src={item.icon} alt="" className="valuesBlockCardIcon" />
          <div className="valuesBlockCardTitle">{item.titleShort}</div>
          <Link
            className="valuesBlockCardLearnMoreButton"
            to={getLearnMoreNavLink(id)}
            onClick={onLearnMoreButtonClick}
          >
            {'Подробнее'}
          </Link>
        </div>
      ))}
    </div>
  );
}

export default ValuesBlock