import React from 'react';
import { expertsList } from './expertsList';
import './ExpertsAndMentors.scss';

const ExpertsAndMentors = () => {
  const blockTitle = 'Наши менторы и эксперты';

  return (
    <div className="expertsAndMentorsContainer">
      <div className="expertsAndMentorsBlockTitle">{blockTitle}</div>
      <div className="expertsAndMentorsMainContainer">
        {expertsList.map((expert) => (
          <div className="expertsAndMentorsItemContainer">
            <div className="expertsAndMentorsItemImageContainer">
              <img className="expertsAndMentorsItemImage" src={expert.image} />
              <div className="expertsAndMentorsItemImageExperienceNote">{expert.experienceNote}</div>
            </div>
            

            <div className="expertsAndMentorsItemMainBlock">
              <div className="expertsAndMentorsItemName">{expert.name}</div>
              <div className="expertsAndMentorsItemDescription">{expert.description}</div>

              {expert.keyPoints.map((keyPoint) => (
                <div className="expertsAndMentorsItemKeyPointItem">
                  <div className="expertsAndMentorsItemKeyPointItemTitleBlock">
                    <div className="expertsAndMentorsItemKeyPointItemTitleBlueDot" />
                    <div className="expertsAndMentorsItemKeyPointItemTitle">{keyPoint.keyPointTitle}</div>
                  </div>
                  <div className="expertsAndMentorsItemKeyPointItemDescription">{keyPoint.keyPointDescription}</div>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ExpertsAndMentors;
