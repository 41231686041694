import LogoBlock from '../../../../components/LogoBlock/LogoBlock';
import { appStoreHref, googlePlayHref } from '../../../../utils/appConstants';
import images from '../../../../utils/images';
import { yandexMetrics } from '../../../../utils/yandexMetrics';
import './JoinUs.scss';

const JoinUs = ({ blockRef }: { blockRef: React.RefObject<HTMLDivElement> }) => {
  const blockTitle = 'Присоединяйтесь к нам';

  const firstBlockFirstSectionTitle = 'Наша команда — это профессионалы с огромным опытом работы.';
  const firstBlockFirstSectionTitleMobile = 'Наша команда — \r\nэто профессионалы с огромным опытом работы.';
  const firstBlockFirstSectionText = 'Мы часто получаем запросы от клиентов или страховых компаний, требующие оперативного и профессионального реагирования, чтобы обеспечить необходимую поддержку и помощь.';
  const firstBlockSecondSectionText = 'В будущем мы можем поделиться в ленте нашего мобильного приложения ETNA APP интересными примерами, которыми гордимся. Нам не стыдно за нашу работу!';

  interface Item {
    title: string;
    text?: string;
  }

  const column1: Item = {
    title: 'Скачайте наше приложение',
    text: 'Присоединяйтесь к нашему сообществу и получите поддержку на пути к здоровому образу жизни.',
  }

  const column2: Item = {
    title: 'Вступите в клуб ЗОЖ-энтузиастов',
  }

  const column3: Item = {
    title: 'Делитесь своими результатами',
  }

  const appStoreButtonText = 'Скачать в\r\n App Store';
  const googlePlayButtonText = 'Доступно в\r\n Google Play';

  const onAppStoreClick = () => {
    yandexMetrics.downloadIOS();
  }

  const onGooglePlayClick = () => {
    yandexMetrics.downloadAndroid();
  }

  return (
    <div className="joinUsContainer" ref={blockRef}>
      <div className="blockTitle">{blockTitle}</div>

      <div className="joinUsFirstBlockContainer">
        <div className="joinUsFirstBlockSection">
          <div className="joinUsFirstBlockTitle joinUsHideOnMobile">{firstBlockFirstSectionTitle}</div>
          <div className="joinUsFirstBlockTitle joinUsHideOnDesktop">{firstBlockFirstSectionTitleMobile}</div>
          <div className="joinUsFirstBlockText">{firstBlockFirstSectionText}</div>
        </div>
        <div className="joinUsFirstBlockSection">
          <LogoBlock height={50} textWidth={82} isClickable={false} />
          <div className="joinUsFirstBlockText">{firstBlockSecondSectionText}</div>
        </div>
      </div>

      <div className="joinUsSecondBlockContainer">
        <div className="joinUsColumn joinUsColumn1">
          <div className="joinUsColumn">
            <div className="joinUsColumnTitle">{column1.title}</div>
            <div className="joinUsColumnText">{column1.text}</div>
          </div>
          <div className="joinUsMarketplaceButtons">
            <a
              className="joinUsMarketplaceButton link"
              href={appStoreHref}
              onClick={onAppStoreClick}
              target="_blank"
            >
              <img src={images.appleIcon} alt="" className="joinUsStoreIcon" />
              {appStoreButtonText}
            </a>
            <a
              className="joinUsMarketplaceButton link"
              href={googlePlayHref}
              onClick={onGooglePlayClick}
              target="_blank"
            >
              <img src={images.googlePlayIcon} alt="" className="joinUsStoreIcon" />
              {googlePlayButtonText}
            </a>
          </div>
        </div>
        <div className="joinUsColumn joinUsColumn2">
          <img src={images.joinUsAppEvents} alt="" className="joinUsColumn2image" />
          <div className="joinUsColumnTitle">{column2.title}</div>
        </div>
        <div className="joinUsColumn joinUsColumn3">
          <div className="joinUsColumnTitle">{column3.title}</div>
          <img src={images.joinUs1} alt="" className="joinUsColumn3image" />
        </div>
      </div>
    </div>
  );
}

export default JoinUs;
