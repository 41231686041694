// misc images
import logoIcon from '../images/logo-icon.png';
import logoText from '../images/logo-text.png';
import checkboxEmpty from '../images/checkbox-empty.png';
import checkboxChecked from '../images/checkbox-checked.png';
import appleIcon from '../images/soon-apple.png';
import googlePlayIcon from '../images/soon-gplay.png';
import telegramIcon from '../images/icon-telegram.png';
import telegramIconHover from '../images/icon-telegram-hover.png';
import vkIcon from '../images/icon-vk.png';
import vkIconHover from '../images/icon-vk-hover.png';
import lockIcon from '../images/lockIcon.png';
import lockIconMobile from '../images/lockIconMobile.png';

// modals
import modalSuccess from '../images/modalSuccess.png';
import modalError from '../images/modalError.png';
import modalCross from '../images/modalCross.png';
import purchaseModalCreditCards from '../images/purchase-modal-credit-cards.png';

// reviews
import reviewsCarouselArrow from '../images/reviewsCarouselArrow.png';
import reviewer1 from '../images/reviewers/reviewer1.png';
import reviewer2 from '../images/reviewers/reviewer2.png';
import reviewer3 from '../images/reviewers/reviewer3.png';
import reviewer4 from '../images/reviewers/reviewer4.png';
import reviewBackground from '../images/reviewers/reviewBackground.png';
import reviewBackgroundMobile from '../images/reviewers/reviewBackgroundMobile.png';

// free guide
import mainPageFreeGuideCardIllustration from '../images/mainPageFreeGuideCardIllustration.png';
import mainPageFreeGuideCardBackground from '../images/mainPageFreeGuideCardBackground.png';

// experts and mentors
import expertsAndMentorsImage1 from '../images/expertsAndMentorsImage1.png';
import expertsAndMentorsImage2 from '../images/expertsAndMentorsImage2.png';
import expertsAndMentorsImage3 from '../images/expertsAndMentorsImage3.png';

// successful payment page
// directly imported in styles
import successfulPaymentSecondColumnBackground from '../images/successful-payment-second-column-background.png';

// item description page
import serviceItemMassage2 from '../images/itemDescriptionPage/serviceItemMassage2.png';
import arrowLeft from '../images/itemDescriptionPage/arrowLeft.png';
import itemDescriptionBackground from '../images/itemDescriptionPage/itemDescriptionBackground.png';
import serviceItemDietPlan from '../images/itemDescriptionPage/serviceItemDietPlan.png';
import serviceItemTrainingPlan from '../images/itemDescriptionPage/serviceItemTrainingPlan.png';
import serviceItemMassage1 from '../images/itemDescriptionPage/serviceItemMassage1.png';

import itemDescriptionBackground0 from '../images/productDescriptionBackgrounds/0.jpg';
import itemDescriptionBackground1 from '../images/productDescriptionBackgrounds/1.jpg';
import itemDescriptionBackground2 from '../images/productDescriptionBackgrounds/2.jpg';
import itemDescriptionBackground3 from '../images/productDescriptionBackgrounds/3.jpg';
import itemDescriptionBackground4 from '../images/productDescriptionBackgrounds/4.jpg';
import itemDescriptionBackground5 from '../images/productDescriptionBackgrounds/5.jpg';
import itemDescriptionBackground6 from '../images/productDescriptionBackgrounds/6.jpg';

import itemDescriptionBackgroundMobile0 from '../images/productDescriptionBackgroundsMobile/0.jpg';
import itemDescriptionBackgroundMobile1 from '../images/productDescriptionBackgroundsMobile/1.jpg';
import itemDescriptionBackgroundMobile2 from '../images/productDescriptionBackgroundsMobile/2.jpg';
import itemDescriptionBackgroundMobile3 from '../images/productDescriptionBackgroundsMobile/3.jpg';
import itemDescriptionBackgroundMobile4 from '../images/productDescriptionBackgroundsMobile/4.jpg';
import itemDescriptionBackgroundMobile5 from '../images/productDescriptionBackgroundsMobile/5.jpg';
import itemDescriptionBackgroundMobile6 from '../images/productDescriptionBackgroundsMobile/6.jpg';

import itemDescriptionCardM1 from '../images/productDescriptionCards/m1.jpg';
import itemDescriptionCardM2 from '../images/productDescriptionCards/m2.jpg';
import itemDescriptionCardM3 from '../images/productDescriptionCards/m3.jpg';
import itemDescriptionCard1 from '../images/productDescriptionCards/1.jpg';
import itemDescriptionCard2 from '../images/productDescriptionCards/2.jpg';
import itemDescriptionCard3 from '../images/productDescriptionCards/3.jpg';
import itemDescriptionCard4 from '../images/productDescriptionCards/4.jpg';
import itemDescriptionCard5 from '../images/productDescriptionCards/5.jpg';
import itemDescriptionCard6 from '../images/productDescriptionCards/6.jpg';
import itemDescriptionCard7 from '../images/productDescriptionCards/7.jpg';
import itemDescriptionCard8 from '../images/productDescriptionCards/8.jpg';
import itemDescriptionCard9 from '../images/productDescriptionCards/9.jpg';

// guides descriptionShort
import guideDescriptionBackground1 from '../images/guideDescription/backgrounds/1.jpg';
import guideDescriptionBackground2 from '../images/guideDescription/backgrounds/2.jpg';
import guideDescriptionBackground3 from '../images/guideDescription/backgrounds/3.jpg';
import guideDescriptionBackground4 from '../images/guideDescription/backgrounds/4.jpg';
import guideDescriptionBackground5 from '../images/guideDescription/backgrounds/5.jpg';
import guideDescriptionBackground6 from '../images/guideDescription/backgrounds/6.jpg';
import guideDescriptionBackground7 from '../images/guideDescription/backgrounds/7.jpg';
import guideDescriptionBackground8 from '../images/guideDescription/backgrounds/8.jpg';
import guideDescriptionBackground9 from '../images/guideDescription/backgrounds/9.jpg';
import guideDescriptionBackground10 from '../images/guideDescription/backgrounds/10.jpg';
import guideDescriptionBackground11 from '../images/guideDescription/backgrounds/11.jpg';
import guideDescriptionBackground12 from '../images/guideDescription/backgrounds/12.jpg';
import guideDescriptionBackground13 from '../images/guideDescription/backgrounds/13.jpg';

import guideDescriptionBackgroundMobile1 from '../images/guideDescription/backgroundsMobile/1.jpg';
import guideDescriptionBackgroundMobile2 from '../images/guideDescription/backgroundsMobile/2.jpg';
import guideDescriptionBackgroundMobile3 from '../images/guideDescription/backgroundsMobile/3.jpg';
import guideDescriptionBackgroundMobile4 from '../images/guideDescription/backgroundsMobile/4.jpg';
import guideDescriptionBackgroundMobile5 from '../images/guideDescription/backgroundsMobile/5.jpg';
import guideDescriptionBackgroundMobile6 from '../images/guideDescription/backgroundsMobile/6.jpg';
import guideDescriptionBackgroundMobile7 from '../images/guideDescription/backgroundsMobile/7.jpg';
import guideDescriptionBackgroundMobile8 from '../images/guideDescription/backgroundsMobile/8.jpg';
import guideDescriptionBackgroundMobile9 from '../images/guideDescription/backgroundsMobile/9.jpg';
import guideDescriptionBackgroundMobile10 from '../images/guideDescription/backgroundsMobile/10.jpg';
import guideDescriptionBackgroundMobile11 from '../images/guideDescription/backgroundsMobile/11.jpg';
import guideDescriptionBackgroundMobile12 from '../images/guideDescription/backgroundsMobile/12.jpg';
import guideDescriptionBackgroundMobile13 from '../images/guideDescription/backgroundsMobile/13.jpg';

import guideDescriptionCardBackground1 from '../images/guideDescription/cards/1.jpg';
import guideDescriptionCardBackground2 from '../images/guideDescription/cards/2.jpg';
import guideDescriptionCardBackground3 from '../images/guideDescription/cards/3.jpg';
import guideDescriptionCardBackground4 from '../images/guideDescription/cards/4.jpg';
import guideDescriptionCardBackground5 from '../images/guideDescription/cards/5.jpg';
import guideDescriptionCardBackground6 from '../images/guideDescription/cards/6.jpg';
import guideDescriptionCardBackground7 from '../images/guideDescription/cards/7.jpg';
import guideDescriptionCardBackground8 from '../images/guideDescription/cards/8.jpg';
import guideDescriptionCardBackground9 from '../images/guideDescription/cards/9.jpg';
import guideDescriptionCardBackground10 from '../images/guideDescription/cards/10.jpg';
import guideDescriptionCardBackground11 from '../images/guideDescription/cards/11.jpg';
import guideDescriptionCardBackground12 from '../images/guideDescription/cards/12.jpg';
import guideDescriptionCardBackground13 from '../images/guideDescription/cards/13.jpg';


// learn more page
import learnMoreBannerDesktop from '../images/learnMoreBannerDesktop.webp';
import learnMoreBannerMobile from '../images/learnMoreBannerDesktop.webp';
// import learnMoreBannerMobile from '../images/learnMoreBannerMobile.png';

// main page blocks
import headerMobileMenuIcon from '../images/header-mobile-menu-button-icon.png';
import headerMobileMenuIconClose from '../images/header-mobile-menu-button-close.png';

import mainBannerBg1 from '../images/main-banner-bg-1.png';
import mainBannerBg2 from '../images/main-banner-bg-2.png';
// offer v2 main banner
import mainBannerProgramBg1 from '../images/offer-v2/mainBannerProgramBg1.png';
import mainBannerProgramBg2 from '../images/offer-v2/mainBannerProgramBg2.png';
import mainBannerProgramBg3 from '../images/offer-v2/mainBannerProgramBg3.png';
import mainBannerProgramBg4 from '../images/offer-v2/mainBannerProgramBg4.jpg';
import mainBannerCard1 from '../images/offer-v2/mainBannerCard1.png';
import mainBannerCard2 from '../images/offer-v2/mainBannerCard2.png';
import mainBannerCard3 from '../images/offer-v2/mainBannerCard3.png';
import mainBannerCard1Mobile from '../images/offer-v2/mainBannerCard1Mobile.png';
import mainBannerCard2Mobile from '../images/offer-v2/mainBannerCard2Mobile.png';
import mainBannerCard3Mobile from '../images/offer-v2/mainBannerCard3Mobile.png';
import mainBannerArrow from '../images/offer-v2/mainBannerArrow.png';
import mainBannerSummaryBlockHeader from '../images/offer-v2/mainBannerSummaryBlockHeader.png';

import howItWorksImage1Group from '../images/howitworks-image-1-group.png';
import howItWorksArrow1v2 from '../images/howitworks-arrow-1-v2.png';
import howItWorksArrow2v2 from '../images/howitworks-arrow-2-v2.png';
import getFreeGuideBanner from '../images/getFreeGuideBanner.png';
import getFreeGuideBannerMobile from '../images/getFreeGuideBannerMobile.png';
import getFreeGuideModalBackground from '../images/getFreeGuideModalBackground.png';
import getFreeGuideModalBackgroundMobile from '../images/getFreeGuideModalBackgroundMobile.png';

import servicesLine1 from '../images/services-line-1.png';
import servicesLine2 from '../images/services-line-2.png';
import servicesLine3 from '../images/services-line-3.png';
import servicesGiftIcon from '../images/services-gift-icon.png';

import consult1Background from '../images/consult-etnamed-logo.svg';
import consult2Background from '../images/consult2-background.png';
import consult2RightImageBlock from '../images/consult2-right-image-block.png';
import consult23Cards from '../images/consult2-3-cards.png';

import joinUs1 from '../images/soon-1.png';
import joinUsAppEvents from '../images/already-in-app-events.png';

import whyUsRunAvocado from '../images/whyus-run-avocado.png';
import whyUsLifting from '../images/whyus-lifting.png';
import whyRectangleBg1 from '../images/why-rectangle-bg-1.png';
import whyRectangleBg2 from '../images/why-rectangle-bg-2.png';

import faqRectangleBg from '../images/faq-rectangle-bg.png';
import faqPlus from '../images/faq-plus.png';

// emojis:
import doctors from '../images/emojis/doctors.png';
import stop from '../images/emojis/stop.png';
import planet from '../images/emojis/planet.png';
import document from '../images/emojis/document.png';
import rocket from '../images/emojis/rocket.png';
import handshake from '../images/emojis/handshake.png';
import stareyes from '../images/emojis/stareyes.png';
import xray from '../images/emojis/xray.png';
import strength from '../images/emojis/strength.png';
import brain from '../images/emojis/brain.png';
import run from '../images/emojis/run.png';
import nosmoking from '../images/emojis/nosmoking.png';
import chemistry from '../images/emojis/chemistry.png';
import doc from '../images/emojis/doc.png';
import educate from '../images/emojis/educate.png';
import gym from '../images/emojis/gym.png';
import hospital from '../images/emojis/hospital.png';
import meditate from '../images/emojis/meditate.png';
import salad from '../images/emojis/salad.png';
import stationaryphone from '../images/emojis/stationaryphone.png';
import anger from '../images/emojis/anger.png';
import apathy from '../images/emojis/apathy.png';
import boy from '../images/emojis/boy.png';
import cool from '../images/emojis/cool.png';
import couple from '../images/emojis/couple.png';
import family from '../images/emojis/family.png';
import girl from '../images/emojis/girl.png';
import insomnia from '../images/emojis/insomnia.png';
import magnify from '../images/emojis/magnify.png';
import man from '../images/emojis/man.png';
import pregnant from '../images/emojis/pregnant.png';
import sleep from '../images/emojis/sleep.png';
import tear from '../images/emojis/tear.png';
import wine from '../images/emojis/wine.png';
import mail from '../images/emojis/mail.png';
import explosion from '../images/emojis/explosion.png';

const images = {
  // misc images
  logoIcon,
  logoText,

  checkboxEmpty,
  checkboxChecked,
  appleIcon,
  googlePlayIcon,
  telegramIcon,
  telegramIconHover,
  vkIcon,
  vkIconHover,
  lockIcon,
  lockIconMobile,

  reviewsCarouselArrow,
  reviewer1,
  reviewer2,
  reviewer3,
  reviewer4,
  reviewBackground,
  reviewBackgroundMobile,

  // modals
  modalSuccess,
  modalError,
  modalCross,
  purchaseModalCreditCards,

  // free guide
  mainPageFreeGuideCardIllustration,
  mainPageFreeGuideCardBackground,

  // experts and mentors
  expertsAndMentorsImage1,
  expertsAndMentorsImage2,
  expertsAndMentorsImage3,


  // successful payment page
  successfulPaymentSecondColumnBackground, // directly imported in styles

  // item description page
  serviceItemMassage2,
  arrowLeft,
  itemDescriptionBackground,
  serviceItemDietPlan,
  serviceItemTrainingPlan,
  serviceItemMassage1,
  itemDescriptionBackground0,
  itemDescriptionBackground1,
  itemDescriptionBackground2,
  itemDescriptionBackground3,
  itemDescriptionBackground4,
  itemDescriptionBackground5,
  itemDescriptionBackground6,
  itemDescriptionBackgroundMobile0,
  itemDescriptionBackgroundMobile1,
  itemDescriptionBackgroundMobile2,
  itemDescriptionBackgroundMobile3,
  itemDescriptionBackgroundMobile4,
  itemDescriptionBackgroundMobile5,
  itemDescriptionBackgroundMobile6,
  itemDescriptionCardM1,
  itemDescriptionCardM2,
  itemDescriptionCardM3,
  itemDescriptionCard1,
  itemDescriptionCard2,
  itemDescriptionCard3,
  itemDescriptionCard4,
  itemDescriptionCard5,
  itemDescriptionCard6,
  itemDescriptionCard7,
  itemDescriptionCard8,
  itemDescriptionCard9,

  // guide descriptions
  guideDescriptionBackground1,
  guideDescriptionBackground2,
  guideDescriptionBackground3,
  guideDescriptionBackground4,
  guideDescriptionBackground5,
  guideDescriptionBackground6,
  guideDescriptionBackground7,
  guideDescriptionBackground8,
  guideDescriptionBackground9,
  guideDescriptionBackground10,
  guideDescriptionBackground11,
  guideDescriptionBackground12,
  guideDescriptionBackground13,
  guideDescriptionBackgroundMobile1,
  guideDescriptionBackgroundMobile2,
  guideDescriptionBackgroundMobile3,
  guideDescriptionBackgroundMobile4,
  guideDescriptionBackgroundMobile5,
  guideDescriptionBackgroundMobile6,
  guideDescriptionBackgroundMobile7,
  guideDescriptionBackgroundMobile8,
  guideDescriptionBackgroundMobile9,
  guideDescriptionBackgroundMobile10,
  guideDescriptionBackgroundMobile11,
  guideDescriptionBackgroundMobile12,
  guideDescriptionBackgroundMobile13,
  guideDescriptionCardBackground1,
  guideDescriptionCardBackground2,
  guideDescriptionCardBackground3,
  guideDescriptionCardBackground4,
  guideDescriptionCardBackground5,
  guideDescriptionCardBackground6,
  guideDescriptionCardBackground7,
  guideDescriptionCardBackground8,
  guideDescriptionCardBackground9,
  guideDescriptionCardBackground10,
  guideDescriptionCardBackground11,
  guideDescriptionCardBackground12,
  guideDescriptionCardBackground13,

  // learn more page
  learnMoreBannerDesktop,
  learnMoreBannerMobile,

  // main page blocks
  headerMobileMenuIcon,
  headerMobileMenuIconClose,
  mainBannerBg1,
  mainBannerBg2,
  mainBannerProgramBg1,
  mainBannerProgramBg2,
  mainBannerProgramBg3,
  mainBannerProgramBg4,
  mainBannerCard1,
  mainBannerCard2,
  mainBannerCard3,
  mainBannerCard1Mobile,
  mainBannerCard2Mobile,
  mainBannerCard3Mobile,
  mainBannerArrow,
  mainBannerSummaryBlockHeader,
  
  howItWorksImage1Group,
  howItWorksArrow1v2,
  howItWorksArrow2v2,
  getFreeGuideBanner,
  getFreeGuideBannerMobile,
  getFreeGuideModalBackground,
  getFreeGuideModalBackgroundMobile,
  
  servicesLine1,
  servicesLine2,
  servicesLine3,
  servicesGiftIcon,

  consult1Background,
  consult2Background,
  consult2RightImageBlock,
  consult23Cards,

  joinUs1,
  joinUsAppEvents,

  whyUsRunAvocado,
  whyUsLifting,
  whyRectangleBg1,
  whyRectangleBg2,

  faqRectangleBg,
  faqPlus,

  // emojis:
  explosion,
  doctors,
  stop,
  planet,
  document,
  rocket,
  handshake,
  stareyes,
  xray,
  strength,
  brain,
  run,
  nosmoking,
  chemistry,
  doc,
  educate,
  gym,
  hospital,
  meditate,
  salad,
  stationaryphone,
  anger,
  apathy,
  boy,
  cool,
  couple,
  family,
  girl,
  insomnia,
  magnify,
  man,
  pregnant,
  sleep,
  tear,
  wine,
  mail,
};


export default images;
