const metricKey = 96548425;

export const yandexMetrics = {
  openFreeGuideForm:   () => window.ym(metricKey,'reachGoal','open_form_1'),
  getFreeGuideRequest: () => window.ym(metricKey,'reachGoal','send_form_1'),
  phoneCallRequest:    () => window.ym(metricKey,'reachGoal','send_form_2'),
  productPurchase:     () => window.ym(metricKey,'reachGoal','buy'),
  productDetailsClick: () => window.ym(metricKey,'reachGoal','detailed'),
  helpChooseProduct:   () => window.ym(metricKey,'reachGoal','choose_a_program'),
  downloadIOS:         () => window.ym(metricKey,'reachGoal','IOS'),
  downloadAndroid:     () => window.ym(metricKey,'reachGoal','Android'),
}
