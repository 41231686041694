import React, { useEffect, useRef } from 'react';

import Slider from "react-slick"; // https://react-slick.neostack.com/docs/api
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

import images from '../../../../../utils/images';
import "./MainBannerCarousel.scss";

const MainBannerCarousel = () => {
  const carouselItems: { image: string; imageMobile: string; text: string }[] = [
    {
      image: images.mainBannerCard1,
      imageMobile: images.mainBannerCard1Mobile,
      text: 'Идеальное тело без лишних усилий',
    },
    {
      image: images.mainBannerCard2,
      imageMobile: images.mainBannerCard2Mobile,
      text: 'Бережно к себе: легко и эффективно с программой «Медконсьерж»',
    },
    {
      image: images.mainBannerCard3,
      imageMobile: images.mainBannerCard3Mobile,
      text: 'Питание, которое вдохновляет: осознанно и вкусно',
    },
  ];

  const sliderRef = useRef<Slider | null>(null);
  useEffect(() => {
    sliderRef?.current?.slickNext();
  }, []);

  return (
    <div className="mainBannerCarouselSliderOuter">
      <Slider
        ref={sliderRef}
        className="mainBannerCarouselSlider"
        dotsClass="mainBannerCarouselDots"
        fade={true}
        dots={true}
        arrows={false}
        infinite={true}
        autoplay={true}
        rows={1}
        speed={1000} // transition speed
        autoplaySpeed={3500}
        cssEase={'ease-in-out'}
        pauseOnHover={true}
        swipeToSlide={false}
        // adaptiveHeight={false}
      >
        {carouselItems.map((item) => (
          <div className="mainBannerCarouselCard" key={item.text}>
            {/* <img src={item.image} className="mainBannerCarouselCardImage" /> */}
            <picture>
              {/* High-quality image for desktop */}
              <source media="(min-width: 1280px)" srcSet={item.image} />
              {/* Low-quality image for mobile and tablet */}
              <img
                className="mainBannerCarouselCardImage"
                src={item.imageMobile}
                alt="Responsive Image"
              />
            </picture>
            <div className="mainBannerCarouselCardText">{item.text}</div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default MainBannerCarousel;
