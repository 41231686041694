import React, { useRef } from 'react'
import Collapsible from 'react-collapsible';
import images from '../../../../utils/images';
import './FAQ.scss';

export interface QuestionItem {
  question: string;
  answer: string | React.ReactNode;
  expanded?: boolean;
}

const FaqItemCollapsible = ({ faqItem }: { faqItem: QuestionItem }) => {
  const imageRef = useRef<HTMLImageElement>(null);

    const setExpanded = async (expanded: boolean) => {
      const angle = expanded ? 45 : 0;
      const rotationTime = 500;
      imageRef.current!.style.transition = `transform ${rotationTime/1000}s ease-in-out`;
      imageRef.current!.style.transform = `rotate(${angle}deg)`;
    }

    const CollapsibleHeader = () => {
      return (
        <div className='faqQuestionHeader'>
          <img src={images.faqPlus} alt="Открыть ответ" ref={imageRef} className='faqQuestionIcon' />
          <div className='faqQuestionText'>{faqItem.question}</div>
        </div>
      )
    }

    return (
      <div className="faqQuestionItem">
        <Collapsible
          trigger={<CollapsibleHeader />}
          onTriggerOpening={() => setExpanded(true)}
          onTriggerClosing={() => setExpanded(false)}
        >
          {typeof faqItem.answer === 'string' ? (
            <div className="faqAnswerText">{faqItem.answer}</div>
          ) : (
            faqItem.answer
          )}
        </Collapsible>
      </div>
    );
}

export default FaqItemCollapsible