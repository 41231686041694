import { ProductDevName } from "../pages/MainPage/components/5.Products/constants/constants";
import { ProductType } from "./enums";
import { ProductData } from "./types";

export const getItemDescriptionNavLink = (productPageName: string) => {
  // const itemDescriptionPageNavLink = `${PagePath.ItemDescriptionPage}?id=${itemId}_${variantId}`;
  const itemDescriptionPageNavLink = productPageName;
  return itemDescriptionPageNavLink;
}

export const getProductAndVariantIdFromSearchParams = (searchParams: URLSearchParams) => {
  const itemIdString = searchParams.get('id')!;
  const [productIdString, variantIdString] = itemIdString.split('_');

  if (!productIdString || !variantIdString) return { productId: 0, variantId: 0 };

  const productId = parseInt(productIdString) || 0;
  const variantId = parseInt(variantIdString) || 0;
  return { productId, variantId };
}

export const sleep = (ms: number) => new Promise(r => setTimeout(r, ms));

export const clampValue = (min: number, value: number, max: number) => Math.min(Math.max(value, min), max);

export const scrollToTop = (smooth = true) => window.scrollTo({ top: 0, behavior: smooth ? 'smooth' : undefined })

export const scrollToBlockRef = (blockRef: React.RefObject<HTMLDivElement>, offset = -20) => {
  const elementPosition = blockRef.current?.getBoundingClientRect().top!;
  const offsetPosition = elementPosition + window.scrollY + offset;

  window.scrollTo({
    top: offsetPosition,
    behavior: "smooth"
  });
};

export const validateEmail = (input: string) => {
  const emailValidationRegExp = /^[a-zA-Z0-9]+(?:\.[a-zA-Z0-9]+)*@(?:[a-zA-Z0-9]+\.)+[a-zA-Z0-9]+(?:\.[a-zA-Z0-9]+)*$/;
  const isValid = emailValidationRegExp.test(input);
  return isValid;
};

export const getCensoredEmailString = (email?: string) => {
  if (!email) return '';

  const inputBeforeAtSymbol = email.split('@')[0];
  const emailNameLength = inputBeforeAtSymbol.length;
  if (emailNameLength <= 2) return email;

  const censoredEmail = email[0] + '*'.repeat(Math.min(emailNameLength - 3, 3)) + email.substring(emailNameLength - 2);
  return censoredEmail;
};

export const validatePhoneNumber = (input: string) => {
  const onlyNumbers = input.replaceAll(/\D/g, '');
  const isValid = onlyNumbers.length === 11;
  return isValid;
};

export const getProductTitle = (productData: ProductData) => {
  const isMedconcierge = productData.devName === ProductDevName.medconcierge;

  switch (productData.type) {
    case ProductType.TariffPlan:
      return `Программа «${productData.title}${isMedconcierge ?  '' : ` ${productData.prefix}`}»`;

    case ProductType.Guide:
      return `${productData.prefix} «${productData.title}»`;

    default:
      return productData.title;
  }
}

export const getCorrectPluralForPeople = (input: number) => {
  const inputString = input.toString();

  // for numbers that end with 10...19, use this
  const isSingleDigit = inputString.length < 2;
  if (!isSingleDigit && parseInt(inputString[inputString.length - 2]) === 1) {
    return "человек";
  };

  // for other numbers, it depends on last digit
  const lastDigit = parseInt(inputString[inputString.length - 1]);
  switch (lastDigit) {
    case 2: case 3: case 4: 
      return "человека";
    default:
      return "человек";
  };
};

export const splitTextsByLineBreak = (input: string) => {
  if (input.includes('\r\n')) return input.split('\r\n');
  else return [input];
}