import React from 'react';
import images from '../../../../utils/images';
import { DimensionsData, ProductData } from '../../../../utils/types';
import { GuideDevName, TariffPlanDevName } from '../5.Products/constants/constants';
import { productItems } from '../5.Products/constants/products';

import MainBannerCarousel from './components/MainBannerCarousel';
import MainBannerProductItem from './components/MainBannerProductItem';
// import PurchaseProductModal from '../../../../components/PurchaseProductModal/PurchaseProductModal';
import './MainBanner.scss';
import MainBannerFreeGuideItem from './components/MainBannerFreeGuideItem';

const MainBanner = ({
  dimensionsData,
}: {
  dimensionsData: DimensionsData;
}) => {
  const { screenSize } = dimensionsData;
  const useMobileDesign = screenSize.width <= 1280;

  const advantagesList: { focusText: string; description: string }[] = [
    { focusText: '10+', description: 'лет в области фитнеса  и диетологии' },
    { focusText: '3000', description: 'успешных кейсов коррекции фигуры' },
    { focusText: '15+', description: 'лет в организации медицинского обслуживания' },
    { focusText: '2500', description: 'клиник по всей России' },
  ]


  // ###################################################################################################
  // const [modalIsOpen, setModalIsOpen] = useState(false);
  // const openModal = () => setModalIsOpen(true);
  // const closeModal = () => setModalIsOpen(false);

  // const [productData, setProductData] = useState<ProductData>();
  // const [selectedVariantId, setSelectedVariantId] = useState<number>();

  // const onPurchaseClick = (item: ProductData, variantId: number) => {
  //   setProductData(item);
  //   setSelectedVariantId(variantId);
  //   openModal();
  // }

  // ###################################################################################################
  interface MainBannerProduct {
    productData: ProductData;
    backgroundImage: string;
    backgroundStyles?: React.CSSProperties;
  }

  const mainBannerProducts: MainBannerProduct[] = [
    {
      productData: productItems.find((item) => item.devName === GuideDevName.energyOfAbundance)!,
      backgroundImage: images.mainBannerProgramBg4,
      backgroundStyles: { backgroundPosition: 'center 25%' },
    },
    {
      productData: productItems.find((item) => item.devName === TariffPlanDevName.liteHealthDiet)!,
      backgroundImage: images.mainBannerProgramBg2,
    },
    {
      productData: productItems.find((item) => item.devName === TariffPlanDevName.liteHealthDietSport)!,
      backgroundImage: images.mainBannerProgramBg3,
    },
  ];

  // const PurchaseModal = () => {
  //   return (
  //     <PurchaseProductModal
  //       productData={productData!}
  //       selectedVariantId={selectedVariantId!}
  //       selectedTags={[]}
  //       modalIsOpen={modalIsOpen}
  //       closeModal={closeModal}
  //     />
  //   )
  // }

  if (useMobileDesign) return (
    <div className="mainBannerContainerMobile">
      {/* <PurchaseModal /> */}
      <MainBannerFreeGuideItem />
      <MainBannerCarousel />
      <MainBannerProductItem
        // onPurchaseClick={onPurchaseClick}
        { ...mainBannerProducts[1] }
      />
      <MainBannerProductItem
        // onPurchaseClick={onPurchaseClick}
        { ...mainBannerProducts[2] }
      />
      <div className="mainBannerAdvantagesBlock">
        <div className="mainBannerAdvantagesBlockTitle">{'Наши преимущества:'}</div>
        <div className="mainBannerAdvantagesList">
          {advantagesList.map((item) => (
            <div className="mainBannerAdvantageItem" key={item.description}>
              <div className="mainBannerAdvantageItemFocusTextBlock">
                <div className="mainBannerAdvantageItemFocusTextCircle" />
                <div className="mainBannerAdvantageItemFocusText">{item.focusText}</div>
              </div>
              <div className="mainBannerAdvantagesItemDescription">{item.description}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );

  return (
    <div className="mainBannerContainer">
      {/* <PurchaseModal /> */}
      <MainBannerCarousel />
      <div className="mainBannerRightBlockGrid">
        <MainBannerFreeGuideItem />
        <MainBannerProductItem
          // onPurchaseClick={onPurchaseClick}
          { ...mainBannerProducts[1] }
        />
        <div className="mainBannerAdvantagesBlock">
          <div className="mainBannerAdvantagesBlockTitle">{'Наши преимущества:'}</div>
          <div className="mainBannerAdvantagesList">
            {advantagesList.map((item) => (
              <div className="mainBannerAdvantageItem" key={item.description}>
                <div className="mainBannerAdvantageItemFocusTextBlock">
                  <div className="mainBannerAdvantageItemFocusTextCircle" />
                  <div className="mainBannerAdvantageItemFocusText">{item.focusText}</div>
                </div>
                <div className="mainBannerAdvantagesItemDescription">{item.description}</div>
              </div>
            ))}
          </div>
        </div>
        <MainBannerProductItem
          // onPurchaseClick={onPurchaseClick}
          { ...mainBannerProducts[2] }
        />
      </div>
    </div>
  );
};

export default MainBanner;
