import { GuideDevName, ProductDevName, TariffPlanDevName } from "./constants";
import guides from "./guides";
import tariffPlans from "./tariffPlans";

// when order/amount of products or their variants (durations) change,
// make sure to adjust index.js so that meta titles and descriptions are correct
export const productItems = [  
  ...tariffPlans,
  ...guides,
];

// products ordered by their devnames
const productsOrder: string[] = [
  TariffPlanDevName.medconcierge,
  TariffPlanDevName.liteHealthDiet ,
  TariffPlanDevName.standardHealthDietSport,
  GuideDevName.energyOfAbundance,
  GuideDevName.personalSpace,
  GuideDevName.stressImportantMoments,
  GuideDevName.methodsToHelpSelfStressPanicAttacks,
  GuideDevName.acceptingSelf,

  GuideDevName.selfDefinition,
  GuideDevName.blackAndWhiteThinking,
  GuideDevName.pushedOut,
  GuideDevName.breakingUp,
  GuideDevName.selfFulfillment,
  GuideDevName.yourMentalExpansion,
  GuideDevName.energyCapacityOfAWoman,
  GuideDevName.techPersonalDevelopment,

  TariffPlanDevName.standardHealthDiet ,
  TariffPlanDevName.maximumHealthDiet ,
  TariffPlanDevName.liteHealthDietSport,
  TariffPlanDevName.maximumHealthDietSport,
  TariffPlanDevName.personalProgram,
  TariffPlanDevName.consultProgram,
]

export const sortedProductItems = [...productItems].sort((a, b) => {
  return productsOrder.indexOf(a.devName) - productsOrder.indexOf(b.devName);
});