import React from 'react';
import images from '../../../utils/images';
import { ProductData } from '../../../utils/types';
import './ItemDescriptionPageMainBanner.scss';
import { getCorrectPluralForPeople } from '../../../utils/helper';
import { Link } from 'react-router-dom';
import { PagePath } from '../../../utils/enums';

const ItemDescriptionPageMainBanner = ({
  productTitle,
  productData,
  selectedVariantId,
  setSelectedVariantId,
  openPurchaseModal,
}: {
  productTitle: string;
  productData: ProductData;
  selectedVariantId: number;
  setSelectedVariantId: (variandId: number) => void;
  openPurchaseModal: () => void;
}) => {
  const {
    descriptionShort,
    descriptionPageBackground,
    descriptionPageBackgroundMobile,
    descriptionPageBackgroundStyleMobile,
    countersData,
  } = productData;

  const currentVariantData = productData.variants[selectedVariantId];

  return (
    <div className="itemDescriptionMainSection" style={{ backgroundImage: `url(${descriptionPageBackground})` }}>
      <div className="itemDescriptionMainSectionFirstBlock">
        <Link
          to={PagePath.MainPage}
          className="itemDescriptionMainSectionFirstBlockBackButton link opacity-on-hover"
        >
          <img className="itemDescriptionMainSectionFirstBlockBackButtonImage" src={images.arrowLeft} />
          {'Назад'}
        </Link>
        <h1 className="itemDescriptionMainSectionFirstBlockTitle">{productTitle}</h1>
        <div className="itemDescriptionMainSectionFirstBlockDurationButtonsContainer">
          {productData.variants.map((variant, index) => (
            <button
              key={variant.durationText}
              onClick={productData.variants.length > 1 ? () => setSelectedVariantId(index) : undefined}
              className={
                index === selectedVariantId
                  ? 'itemDescriptionMainSectionFirstBlockDurationButtonActive'
                  : 'itemDescriptionMainSectionFirstBlockDurationButton'
              }
              style={{ ...(!variant.durationText && { display: 'none' }) }}
            >
              {variant.durationText}
            </button>
          ))}
        </div>
        <div className="itemDescriptionMainSectionFirstBlockMainText">{descriptionShort}</div>

        <div
          className="itemDescriptionMainSectionSecondBlockMobile"
          style={{
            backgroundImage: `url(${descriptionPageBackgroundMobile})`,
            ...descriptionPageBackgroundStyleMobile,
          }}
        >
          {countersData.map((item) => (
            <div className="itemDescriptionMainSectionSecondBlockCounterItem" key={item.title}>
              <div className="itemDescriptionMainSectionSecondBlockCounterItemTitle">
                <div className="itemDescriptionMainSectionSecondBlockCounterItemTitleDot" />
                {item.title}
              </div>
              <div className="itemDescriptionMainSectionSecondBlockCounterItemNumbers">
                {`${item.count} ${getCorrectPluralForPeople(item.count)}`}
              </div>
            </div>
          ))}
        </div>

        <div className="itemDescriptionMainSectionFirstBlockPrice">{currentVariantData.price}</div>
        <button className="app-button itemDescriptionMainSectionFirstBlockPurchaseButton" onClick={openPurchaseModal}>
          {'Купить'}
        </button>
      </div>

      <div className="itemDescriptionMainSectionSecondBlock">
        {countersData.map((item) => (
          <div className="itemDescriptionMainSectionSecondBlockCounterItem" key={item.title}>
            <div className="itemDescriptionMainSectionSecondBlockCounterItemTitle">
              <div className="itemDescriptionMainSectionSecondBlockCounterItemTitleDot" />
              {item.title}
            </div>
            <div className="itemDescriptionMainSectionSecondBlockCounterItemNumbers">
            {`${item.count} ${getCorrectPluralForPeople(item.count)}`}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ItemDescriptionPageMainBanner;
