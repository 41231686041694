import { ProductType, Duration, ProductCounterTitle, ProductTag } from "../../../../../utils/enums";
import images from "../../../../../utils/images";
import { ProductData } from "../../../../../utils/types";
import { ProductDevName, ProductService } from "./constants";
import tariffPlanTextBlocks from './tariffPlanTextBlocks';
import { commonTags } from "./tags";

const {
  MedconciergeTextBlock,
  TB1,
  TB1Sport,
  TB2,
  OnlineConsultTextBlock,
  FullDietarySupportTextBlock,
  TB5,
  OnlineTrainingsTextBlock,
  ExercisingProcessTextBlock,
  TB6,
  TB7,
} = tariffPlanTextBlocks;

const tariffPlans: ProductData[] = [  
  {
    type: ProductType.TariffPlan,
    devName: ProductDevName.medconcierge,
    productRoute: '/meditsinskaya-pomoshch',
    prefix: 'Basic',
    title: 'Медконсьерж',
    variants: [
      {
        price: '5 790 ₽',
        durationText: `на ${Duration.d3m}`,
        durationInMonths: 3,
        includesArray: [
          { optionName: ProductService.OnlineConsultDoctor, note: Duration.d3, altDescription: '3 онлайн-консультации с врачом' },
          { optionName: ProductService.AnalyzeMedicalRecords, note: Duration.d2, altDescription: '2 анализа медицинской документации' },
          { optionName: ProductService.SuitableSpecialist, note: Duration.d1 },
        ],
      },
    ],
    
    titleBackground: images.servicesLine1,
    descriptionPageBackground: images.itemDescriptionBackground0,
    descriptionPageBackgroundMobile: images.itemDescriptionBackgroundMobile0,
    descriptionPageBackgroundStyleMobile: { backgroundPositionX: '60%' },
    
    descriptionShort: 'Мы предлагаем популярную базовую программу «Медконсьерж» для людей, которые нуждаются в информационно-консультационной поддержке по медицинским вопросам, организации медицинской помощи в рамках бесплатного полиса ОМС или платных услуг на территории России, а также в юридической поддержке, оформлении медицинской документации или получения налогового вычета.',
    descriptionComponents: [MedconciergeTextBlock],

    countersData: [
      { title: ProductCounterTitle.UndergoingProgram, count: 197 },
      { title: ProductCounterTitle.CompletedProgram, count: 962 },
    ],

    tags: [
      ...commonTags,

      ProductTag.pregnancy,
      ProductTag.treatment,
      ProductTag.chronicDiseases,
      ProductTag.psychAssistance,
      ProductTag.sleepIssues,
      ProductTag.noEnergy,
      ProductTag.stopSmoking,
      ProductTag.stopAlcohol,
      ProductTag.badHabits,
      ProductTag.anger,
      ProductTag.depression,
      ProductTag.apathy,
      ProductTag.complexCases,
    ],
  },
  {
    type: ProductType.TariffPlan,
    devName: ProductDevName.liteHealthDiet,
    productRoute: '/programma-pitaniya-30-dney',
    prefix: 'Lite',
    title: 'ЗОЖ Питание',
    variants: [
      {
        price: '2 990 ₽',
        durationText: `на ${Duration.d1m}`,
        durationInMonths: 1,
        includesArray: [
          { optionName: ProductService.DailyDietPlan, altDescription: 'План питания на 30 дней' },
          { optionName: ProductService.GroceriesList },
        ],
      },
    ],
    
    titleBackground: images.servicesLine2,
    descriptionPageBackground: images.itemDescriptionBackground1,
    descriptionPageBackgroundMobile: images.itemDescriptionBackgroundMobile1,
    descriptionPageBackgroundStyleMobile: { backgroundPositionX: '95%' },
    
    descriptionShort: 'Программа «ЗОЖ Питание Lite» — это не просто диета, а целостный подход к заботе о вашем здоровье. Мы предлагаем персонализированные решения для вашего питания, учитывая ваши цели, предпочтения в еде, желание и возможности готовить, физическую активность и особенности организма. Наша цель — помочь вам не только достичь желаемого веса, но и улучшить общее самочувствие и качество жизни.',
    descriptionComponents: [TB1, TB2, TB5, TB6, TB7],

    countersData: [
      { title: ProductCounterTitle.UndergoingProgram, count: 188 },
      { title: ProductCounterTitle.CompletedProgram, count: 846 },
    ],
    
    tags: [
      ...commonTags,
      
      ProductTag.sport,
      ProductTag.diet,
      ProductTag.sleepIssues,
      ProductTag.stopAlcohol,
      ProductTag.badHabits,
      ProductTag.anger,
      ProductTag.depression,
      ProductTag.apathy,
    ],
  },
  {
    type: ProductType.TariffPlan,
    devName: ProductDevName.standardHealthDiet,
    productRoute: '/programma-zozh-pitaniye-standard',
    prefix: 'Standard',
    title: 'ЗОЖ Питание',
    variants: [
      {
        price: '4 500 ₽',
        durationText: `на ${Duration.d1m}`,
        durationInMonths: 1,
        includesArray: [
          { optionName: ProductService.DailyDietPlan },
          { optionName: ProductService.GroceriesList },
          { optionName: ProductService.OnlineConsult },
        ],
      },
    ],
    
    titleBackground: images.servicesLine2,
    descriptionPageBackground: images.itemDescriptionBackground2,
    descriptionPageBackgroundMobile: images.itemDescriptionBackgroundMobile2,
    descriptionPageBackgroundStyleMobile: { backgroundPositionX: '100%' },
    
    descriptionShort: 'Программа «ЗОЖ Питание Standard» — это не просто диета, а целостный подход к заботе о вашем здоровье. Мы предлагаем персонализированные решения для вашего питания, учитывая ваши цели, предпочтения в еде, желание и возможности готовить, физическую активность и особенности организма. Наша цель — помочь вам не только достичь желаемого веса, но и улучшить общее самочувствие и качество жизни.',
    descriptionComponents: [TB1, TB2, OnlineConsultTextBlock, TB5, TB6, TB7],

    countersData: [
      { title: ProductCounterTitle.UndergoingProgram, count: 156 },
      { title: ProductCounterTitle.CompletedProgram, count: 648 },
    ],
    
    tags: [
      ...commonTags,
      
      ProductTag.sport,
      ProductTag.diet,
      ProductTag.sleepIssues,
      ProductTag.stopAlcohol,
      ProductTag.badHabits,
      ProductTag.anger,
      ProductTag.depression,
      ProductTag.apathy,
    ],
  },
  {
    type: ProductType.TariffPlan,
    devName: ProductDevName.maximumHealthDiet,
    productRoute: '/programma-zozh-pitaniye-maximum',
    prefix: 'Maximum',
    title: 'ЗОЖ Питание',
    variants: [
      {
        price: '19 500 ₽',
        durationText: `на ${Duration.d1m}`,
        durationInMonths: 1,
        includesArray: [
          { optionName: ProductService.DailyDietPlan },
          { optionName: ProductService.GroceriesList },
          { optionName: ProductService.OnlineConsult },
          { optionName: ProductService.FullDietarySupportUntilResult, note: Duration.d1m },
        ],
        bonuses: [
          { optionName: ProductService.OnlineExerciseGift },
        ]
      },
      {
        price: '39 000 ₽',
        durationText: `на ${Duration.d3m}`,
        durationInMonths: 3,
        includesArray: [
          { optionName: ProductService.DailyDietPlan },
          { optionName: ProductService.GroceriesList },
          { optionName: ProductService.OnlineConsult },
          { optionName: ProductService.FullDietarySupportUntilResult, note: Duration.d3m },
        ],
        bonuses: [
          { optionName: ProductService.OnlineExerciseGift },
        ]
      },
    ],
    titleBackground: images.servicesLine2,
    descriptionPageBackground: images.itemDescriptionBackground3,
    descriptionPageBackgroundMobile: images.itemDescriptionBackgroundMobile3,
    descriptionPageBackgroundStyleMobile: { backgroundPositionX: '93%' },
    
    descriptionShort: 'Программа «ЗОЖ Питание Maximum» — это не просто диета, а целостный подход к заботе о вашем здоровье. Мы предлагаем персонализированные решения для вашего питания, учитывая ваши цели, предпочтения в еде, желание и возможности готовить, физическую активность и особенности организма. Наша цель — помочь вам не только достичь желаемого веса, но и улучшить общее самочувствие и качество жизни.',
    descriptionComponents: [TB1, TB2, OnlineConsultTextBlock, FullDietarySupportTextBlock, TB5, TB6, TB7],

    countersData: [
      { title: ProductCounterTitle.UndergoingProgram, count: 112 },
      { title: ProductCounterTitle.CompletedProgram, count: 535 },
    ],
    
    tags: [
      ...commonTags,
      
      ProductTag.sport,
      ProductTag.diet,
      ProductTag.sleepIssues,
      ProductTag.stopAlcohol,
      ProductTag.badHabits,
      ProductTag.anger,
      ProductTag.depression,
      ProductTag.apathy,
    ],
  },
  {
    type: ProductType.TariffPlan,
    devName: ProductDevName.liteHealthDietSport,
    productRoute: '/programma-zozh-pitaniye-sport-lite',
    prefix: 'Lite',
    title: 'ЗОЖ Питание + Спорт',
    variants: [
      {
        price: '5 990 ₽',
        durationText: `на ${Duration.d1m}`,
        durationInMonths: 1,
        includesArray: [
          { optionName: ProductService.DailyDietPlan, altDescription: 'План питания на 30 дней' },
          { optionName: ProductService.ExercisePlan },
          { optionName: ProductService.GroceriesList },
        ],
      },
    ],
    
    titleBackground: images.servicesLine3,
    descriptionPageBackground: images.itemDescriptionBackground4,
    descriptionPageBackgroundMobile: images.itemDescriptionBackgroundMobile4,
    descriptionPageBackgroundStyleMobile: { backgroundPositionX: '97%' },
    
    descriptionShort: 'Программа "ЗОЖ Питание+Спорт" — это не просто диета и изнурительные тренировки, а гармоничный подход к вашему здоровью. Мы предлагаем персонализированные решения для питания, комбинируя это с индивидуально разработанным планом тренировок на каждую неделю, контролем выполнения и коррекцией плана. Наша цель — помочь вам достичь желаемого веса, улучшить общее самочувствие и качество жизни.',
    descriptionComponents: [TB1Sport, TB2, TB5, ExercisingProcessTextBlock, TB6, TB7],

    countersData: [
      { title: ProductCounterTitle.UndergoingProgram, count: 167 },
      { title: ProductCounterTitle.CompletedProgram, count: 745 },
    ],
    
    tags: [
      ...commonTags,
      
      ProductTag.sport,
      ProductTag.diet,
      ProductTag.sleepIssues,
      ProductTag.noEnergy,
      ProductTag.stopSmoking,
      ProductTag.stopAlcohol,
      ProductTag.badHabits,
    ],
  },
  {
    type: ProductType.TariffPlan,
    devName: ProductDevName.standardHealthDietSport,
    productRoute: '/plan-pitaniya-i-trenirovok-standart',
    prefix: 'Standard',
    title: 'ЗОЖ Питание + Спорт',
    variants: [
      {
        price: '8 900 ₽',
        durationText: `на ${Duration.d1m}`,
        durationInMonths: 1,
        includesArray: [
          { optionName: ProductService.DailyDietPlan },
          { optionName: ProductService.ExercisePlan },
          { optionName: ProductService.GroceriesList },
          { optionName: ProductService.OnlineConsult, note: Duration.d1 },
          { optionName: ProductService.OnlineExercise, note: '1 тренировка' },
        ],
      },
    ],
    
    titleBackground: images.servicesLine3,
    descriptionPageBackground: images.itemDescriptionBackground5,
    descriptionPageBackgroundMobile: images.itemDescriptionBackgroundMobile5,
    descriptionPageBackgroundStyleMobile: { backgroundPositionX: '100%' },
    
    descriptionShort: 'Программа "ЗОЖ Питание+Спорт" — это не просто диета и изнурительные тренировки, а гармоничный подход к вашему здоровью. Мы предлагаем персонализированные решения для питания, комбинируя это с индивидуально разработанным планом тренировок на каждую неделю, контролем выполнения и коррекцией плана. Наша цель — помочь вам достичь желаемого веса, улучшить общее самочувствие и качество жизни.',
    descriptionComponents: [TB1Sport, TB2, OnlineConsultTextBlock, TB5, OnlineTrainingsTextBlock, ExercisingProcessTextBlock, TB6, TB7],

    countersData: [
      { title: ProductCounterTitle.UndergoingProgram, count: 156 },
      { title: ProductCounterTitle.CompletedProgram, count: 715 },
    ],
    
    tags: [
      ...commonTags,
      
      ProductTag.sport,
      ProductTag.diet,
      ProductTag.sleepIssues,
      ProductTag.noEnergy,
      ProductTag.stopSmoking,
      ProductTag.stopAlcohol,
      ProductTag.badHabits,
    ],
  },
  {
    type: ProductType.TariffPlan,
    devName: ProductDevName.maximumHealthDietSport,
    productRoute: '/programma-zozh-pitaniye-sport-maximum',
    prefix: 'Maximum',
    title: 'ЗОЖ Питание + Спорт',
    variants: [
      {
        price: '49 950 ₽',
        durationText: `на ${Duration.d1m}`,
        durationInMonths: 1,
        includesArray: [
          { optionName: ProductService.DailyDietPlan },
          { optionName: ProductService.ExercisePlan },
          { optionName: ProductService.GroceriesList },
          { optionName: ProductService.OnlineConsult, note: Duration.d1 },
          { optionName: ProductService.OnlineExercise, note: '8 шт. за 1 месяц' },
          { optionName: ProductService.FullDietarySupportUntilResult, note: Duration.d3m },
          { optionName: ProductService.SuitableTrainerAndLocation },
        ],
      },
      {
        price: '69 000 ₽',
        durationText: `на ${Duration.d3m}`,
        durationInMonths: 3,
        includesArray: [
          { optionName: ProductService.DailyDietPlan },
          { optionName: ProductService.ExercisePlan },
          { optionName: ProductService.GroceriesList },
          { optionName: ProductService.OnlineConsult, note: Duration.d1 },
          { optionName: ProductService.OnlineExercise, note: '24 шт. за 3 месяца' },
          { optionName: ProductService.FullDietarySupportUntilResult, note: Duration.d3m },
          { optionName: ProductService.SuitableTrainerAndLocation },
        ],
      },
    ],
    
    titleBackground: images.servicesLine3,
    descriptionPageBackground: images.itemDescriptionBackground6,
    descriptionPageBackgroundMobile: images.itemDescriptionBackgroundMobile6,
    descriptionPageBackgroundStyleMobile: { backgroundPositionX: '67%' },
    
    descriptionShort: 'Программа "ЗОЖ Питание+Спорт" — это не просто диета и изнурительные тренировки, а гармоничный подход к вашему здоровью. Мы предлагаем персонализированные решения для питания, комбинируя это с индивидуально разработанным планом тренировок на каждую неделю, контролем выполнения и коррекцией плана. Наша цель — помочь вам достичь желаемого веса, улучшить общее самочувствие и качество жизни.',
    descriptionComponents: [TB1Sport, TB2, OnlineConsultTextBlock, FullDietarySupportTextBlock, TB5, OnlineTrainingsTextBlock, ExercisingProcessTextBlock, TB6, TB7],

    countersData: [
      { title: ProductCounterTitle.UndergoingProgram, count: 89 },
      { title: ProductCounterTitle.CompletedProgram, count: 627 },
    ],
    
    tags: [
      ...commonTags,
      
      ProductTag.sport,
      ProductTag.diet,
      ProductTag.sleepIssues,
      ProductTag.noEnergy,
      ProductTag.stopSmoking,
      ProductTag.stopAlcohol,
      ProductTag.badHabits,
    ],
  },
  {
    type: ProductType.TariffPlan,
    devName: ProductDevName.personalProgram,
    productRoute: '/',
    prefix: 'Exclusive',
    title: 'Персональная',
    variants: [
      {
        price: 'Цена по запросу',
        durationText: '',
        durationInMonths: 0,
        includesArray: [
          { optionName: ProductService.SuitableSpecialistsForYourGoals },
        ],
      },
    ],
    
    titleBackground: images.servicesLine1,
    descriptionPageBackground: images.itemDescriptionBackground0,
    descriptionPageBackgroundMobile: images.itemDescriptionBackgroundMobile0,
    descriptionPageBackgroundStyleMobile: { backgroundPositionX: '100%' },
    
    descriptionShort: '',
    descriptionComponents: [],

    countersData: [
      { title: ProductCounterTitle.UndergoingProgram, count: 0 },
      { title: ProductCounterTitle.CompletedProgram, count: 0 },
    ],
    
    tags: [
      ...commonTags,
      
      ProductTag.pregnancy,
      ProductTag.treatment,
      ProductTag.international,
      ProductTag.chronicDiseases,
      ProductTag.psychAssistance,
      ProductTag.sleepIssues,
      ProductTag.badHabits,
      ProductTag.stopAlcohol,
      ProductTag.complexCases,
    ],
  },
];

export default tariffPlans;