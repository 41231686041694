import React from 'react';
import images from '../../utils/images';
import './AppCheckbox.scss';

const AppCheckbox = ({
  isChecked,
  setCheckBoxValue,
}: {
  isChecked: boolean;
  setCheckBoxValue: (b: boolean) => void;
}) => {
  const onClick = () => {
    setCheckBoxValue(!isChecked);
  };

  return (
    <img
      onClick={onClick}
      src={isChecked ? images.checkboxChecked : images.checkboxEmpty}
      className="appCheckbox link opacity-on-hover"
    />
  );
};

export default AppCheckbox;
