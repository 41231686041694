export enum PagePath {
  MainPage = "/",
  OrderStatusPage = "/order", // this is where backend navigates us, and should remain the same
  SentFreeGuidePage = "/sentfreeguide", // this is where backend navigates us, and should remain the same
  // ItemDescriptionPage = "/item",
  ValuesPage = "/values",
}

export enum SectionName {
  Values = 'Values',
  Products = 'Products',
  ServicesList = 'ServicesList',
  ConsultSection1 = 'ConsultSection1',
  JoinUs = 'JoinUs',
  ConsultSection2 = 'ConsultSection2',
  FAQ = 'FAQ',
}

export enum ProductType {
  TariffPlan = 'TariffPlan',
  Guide = 'Guide',
}

export enum ProductCounterTitle {
  UndergoingProgram = 'Сейчас проходят',
  CompletedProgram = 'Уже прошли программу',
  BoughtGuide = 'Уже купили гайд',
}

export enum ProductTag {
  man = 'Мужчина',
  woman = 'Женщина',
  child = 'Ребёнок',
  parents = 'Родители',
  family = 'Семья',
  ageRange1 = '0–3 года',
  ageRange2 = '3–10 лет',
  ageRange3 = '10–18 лет',
  ageRange4 = '18–25 лет',
  ageRange5 = '25–35 лет',
  ageRange6 = '35–45 лет',
  ageRange7 = '45–60 лет',
  ageRange8 = '60+ лет',
  pregnancy = 'Беременность',
  sport = 'Спорт и фитнес',
  diet = 'Правильное питание',
  treatment = 'Лечение',
  international = 'Медицина за рубежом',
  chronicDiseases = 'Хронические заболевания',
  psychAssistance = 'Психологическая поддержка',
  sleepIssues = 'Проблемы со сном',
  noEnergy = 'Отсутствие энергии',
  stopSmoking = 'Бросить курить',
  stopAlcohol = 'Отказаться от алкоголя',
  badHabits = 'Вредные привычки',
  anger = 'Гнев',
  depression = 'Депрессия',
  apathy = 'Апатия',
  complexCases = 'Сложные случаи',
  // prophylaxis = 'Профилактика',
}

export enum Duration {
  d1 = '1 раз',
  d2 = '2 раза',
  d3 = '3 раза',
  d4 = '4 раза',
  d1m = '1 месяц',
  d3m = '3 месяца',
  d6m = '6 месяцев',
  d1y = '1 год',
};