import React, { useMemo } from 'react';
import { Duration, ProductType } from '../../../../../utils/enums';
import images from '../../../../../utils/images';
import { IncludedServiceItem, ProductData } from '../../../../../utils/types';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setInitialProductVariantId, setSectionToReturnTo } from '../../../../../redux/mainReducer';
import { getItemDescriptionNavLink } from '../../../../../utils/helper';
import './MainBannerProductItem.scss';
import { yandexMetrics } from '../../../../../utils/yandexMetrics';

const MainBannerProductItem = ({
  productData,
  // onPurchaseClick,
  backgroundImage,
  backgroundStyles,
  variantId = 0,
}: {
  productData: ProductData;
  // onPurchaseClick: (item: ProductData, variantId: number) => void;
  backgroundImage: string;
  backgroundStyles?: React.CSSProperties;
  variantId?: number;
}) => {
  const variantData = productData.variants[variantId];

  // const onArrowButtonClick = () => {
  //   onPurchaseClick(productData, variantId);
  // }

  const getServiceDescriptionText = (includedServiceItem: IncludedServiceItem) => {
    const { optionName, note, altDescription } = includedServiceItem;
    if (altDescription) return altDescription;
    if (note && note !== Duration.d1) return `${optionName} (${note})`;
    return optionName;
  }

  const itemDescriptionPageNavLink = getItemDescriptionNavLink(productData.productRoute);

  const dispatch = useDispatch();
  const onNavigateToProductDescriptionPage = () => {
    yandexMetrics.productDetailsClick();
    dispatch(setInitialProductVariantId(0));
    dispatch(setSectionToReturnTo(null));
  }

  const typeTitle = useMemo(() => {
    switch(productData.type) {
      case ProductType.Guide: return 'Гайд';
      case ProductType.TariffPlan: return 'Программа';
      default: return 'Услуга';
    };
  }, [productData.type])

  const isGuide = productData.type === ProductType.Guide;

  return (
    <div className="mainBannerProductItemOuterContainer" key={productData.devName}>
      <Link
        className="mainBannerProductItemMainContainer link"
        style={{ backgroundImage: `url('${backgroundImage}')`, ...backgroundStyles }}
        to={itemDescriptionPageNavLink}
        onClick={onNavigateToProductDescriptionPage}
      >
        <div className="mainBannerProductItemMainContainerTitle">{typeTitle}</div>
        <div className="mainBannerProductItemMainContainerBottomAlignedColumn">
          <div className="mainBannerProductItemMainContainerServicesList">
            {isGuide && 
              <span className="mainBannerProductItemMainContainerServiceItem" style={{ marginBottom: 4 }}>
                {productData.mainPageDescription}
              </span>
            }
            {!isGuide && variantData.includesArray.map((includedServiceItem) => (
              <span className="mainBannerProductItemMainContainerServiceItem" key={includedServiceItem.optionName}>
                {getServiceDescriptionText(includedServiceItem)}
              </span>
            ))}
          </div>
          <div className="mainBannerProductItemMainContainerBottomRow">
            <div className="mainBannerProductItemMainContainerPrice">{variantData.price}</div>
            <div className="mainBannerProductItemMainContainerArrowButton">
              <img className="mainBannerProductItemMainContainerArrow" src={images.mainBannerArrow} />
            </div>
          </div>
        </div>
      </Link>
      <div className="mainBannerProductItemBottomContainer">
        {productData.title}
        {variantData.durationText && <div className="mainBannerProductItemBottomContainerDuration">
          {variantData.durationText}
        </div>}
      </div>
    </div>
  );
};

export default MainBannerProductItem;
