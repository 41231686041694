import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { setSectionToScrollTo } from '../../redux/mainReducer';
import {
  companyMail,
  companyMailHref,
  companyPhone1,
  companyPhone1Href,
  companyPhone2,
  companyPhone2Href,
  privacyPolicyHref,
  socialMediaButtons,
} from '../../utils/appConstants';
import { PagePath, SectionName } from '../../utils/enums';
import { DimensionsData, MenuItem } from '../../utils/types';
import LogoBlock from '../LogoBlock/LogoBlock';
import './Footer.scss';
import SocialMediaButton from '../SocialMediaButton/SocialMediaButton';
import { productRoutes } from '../..';

const Footer = ({
  dimensionsData,
  hideSectionLinks,
}: {
  dimensionsData: DimensionsData;
  hideSectionLinks: boolean;
}) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const scrollToSection = async (sectionName: SectionName) => {
    dispatch(setSectionToScrollTo(sectionName));

    if (
      productRoutes.includes(location.pathname) &&
      (sectionName === SectionName.FAQ || sectionName === SectionName.ConsultSection2)
    ) {
      return;
    }

    if (location.pathname !== PagePath.MainPage) navigate(PagePath.MainPage);
  };

  const menuItems: MenuItem[] = [
    {
      text: 'Программы и услуги',
      onClick: () => scrollToSection(SectionName.Products),
    },
    {
      text: 'Частые вопросы',
      onClick: () => scrollToSection(SectionName.FAQ),
    },
  ];

  const currentYear = new Date().getFullYear();
  const c3t1 = 'Мед. услуги оказывает ООО «Медлейбл»';
  const c3t3 = `2017–${currentYear} ©️ ООО «Этнамед»`;
  const c3t4 = 'Политика конфиденциальности';

  const { isVerticallyOriented: useMobileDesign } = dimensionsData;

  return (
    <div className="footerContainer">
      <div className="footerContainerInner">
        <div className="footerColumn12container">
          <div className="footerColumn1">
            <LogoBlock isClickable={true} />
            <a className="footerCompanyPhone link opacity-on-hover" href={companyPhone1Href}>
              {companyPhone1}
            </a>
            <a
              style={{ marginTop: 10 }}
              href={companyPhone2Href}
              className="footerCompanyPhone link opacity-on-hover"
            >
              {companyPhone2}
            </a>
            <a href={companyMailHref} className="footerTextMain link opacity-on-hover">
              {companyMail}
            </a>
            <div className="footerSocialMediaButtonsContainerMobile">
              {socialMediaButtons.map((item) => (
                <SocialMediaButton item={item} key={item.href} />
              ))}
            </div>
          </div>
          {!hideSectionLinks && (
            <div className="footerColumn2">
              {menuItems.map((item) => (
                <div className="footerTextMain link opacity-on-hover" onClick={item.onClick} key={item.text}>
                  {item.text}
                </div>
              ))}
              <div className="footerSocialMediaButtonsContainerDesktop">
                {socialMediaButtons.map((item) => (
                  <SocialMediaButton item={item} key={item.href} />
                ))}
              </div>
            </div>
          )}
        </div>
        <div className="footerColumn3">
          <div className="footerColumn3text">{c3t1}</div>
          <div className="footerColumn3text" style={{ marginTop: useMobileDesign ? 18 : 17 }}>
            {c3t3}
          </div>
          <div style={{ marginTop: useMobileDesign ? 18 : 81 }}>
            <a className="footerPrivacyPolicyLink link opacity-on-hover" href={privacyPolicyHref} target="_blank">
              {c3t4}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
