import React from 'react';
import { splitTextsByLineBreak } from '../../../../../utils/helper';

const MedconciergeTextBlock = () => {
  const title1 = '1. Онлайн консультация с врачом (3 раза в период действия).';
  const listItems1 = [
    'консультация врача по вопросам медицинской тематики,',
    'в том числе сбор анамнеза,',
    'предоставление рекомендаций по возможному плану лечения.',
  ]
  const text1 = `В течение дня с момента покупки программы, с вами свяжется координатор нашего колл-центра для уточнения контактной информации и вместе с вами согласует и назначит время онлайн консультации. Первая консультация проходит с врачом общей практики для определения причины обращения, сбора анамнеза и уточнения других деталей заболевания (причины обращения). Вторая и третья консультации уже будут доступны с другими врачами, например, узкой специализации (педиатрия, эндокринология, гастроэнтерология и т.д.), по необходимости. Если такой необходимости нет, то вы сможете консультироваться с нашим врачом-консультантом повторно.
  \r\nГлавное, что за эти 3 консультации мы поможем найти ответ на вопрос: «Что делать и Как быть, именно в Вашей ситуации»?`;
  
  const title2 = '2. Анализ текущей медицинской документации (2 раза в период действия).';
  const listItems2 = [
    'консультация врача по вопросам медицинской тематики,',
    'в том числе сбор анамнеза,',
    'обзор направленных клиентом медицинских заключений, документов;',
    'предоставление рекомендаций по дальнейшему лечению на основании проведенного анализа/обзора направленной медицинской документации.',
  ]
  const text2 = `При наличии уже имеющихся каких-либо справок, выписок, результатов обследования или анализов, на первой консультации врач-общей практики попросит Вас предоставить эту информацию, чтобы оценить объем проведенного обследования. В последующем при необходимости, эти результаты и заключения смогут быть использованы при организации консилиумов или составления независимого мнения. Все медицинские данные используются нами и хранятся в строгом соответствии с Законом о защите персональных данных ФЗ-152 от 27.07.2006г.
  \r\nПовторный анализ документации проводится в случае, когда врач рекомендует Вам пройти дополнительные обследования, например по месту жительства, и затем составляются рекомендации о дальнейших действиях уже на основании полученных новых результатов.`;

  const title3 = 'Зачем Вам это нужно?';
  const text3 = `Самый распространенный уровень недовольства среди пациентов складывается, когда клиенты жалуются со словами: «кучу ненужных анализов выписали и обследовали всего вдоль и поперёк, но ничего не помогает, никто толком сказать не может почему болячка болит и продолжает беспокоить?» Так происходит, прямо скажем, когда попадаешь в некий конвейер и, к сожалению, когда не всегда врачам удается по месту обращения уделить должное внимание пациенту, в силу больших ограничений по времени приема и т.п.
  \r\nНаша команда специалистов всегда подходит индивидуально к клиенту, прежде чем лечить болезнь, нужно разобраться в ситуации, в которой он оказался. У нас нет ограничений по времени, у нас нет жестких рамок, которые многим коллегам в разных лечебных учреждениях связывают руки и не позволяют выйти за бюрократические рамки.
  \r\nЗа многие годы работы у нас сложились личные отношения с коллегами во многих городах России – профессионалами высочайшего уровня, просто так к которым записаться на прием даже не возможно.`;

  const title4 = '3. Подбор нужного специалиста и клиники.';
  const listItems4 = [
    'консультация врача по вопросам медицинской тематики,',
    'в том числе сбор анамнеза;',
    'предоставление рекомендаций по дальнейшему лечению',
    'подбор целевого специалиста по имеющимся показаниям в одном из рекомендованных медицинских учреждений РФ, запись на прием к данному специалисту.',
  ]
  const text4 = `После того, как мы проведем оценку текущего состояния и согласуем с Вами план действий, связанный именно с Вашими потребностями, мы организуем очный визит на прием к специалисту, который специализируется на лечении именно Вашей патологии и имеет многолетний опыт по лечению в этом направлении. Для нас нет разницы какого статуса наш клиент, но для Вас мы подберем нужного врача любой категории, хоть в платной клинике, хоть в учреждении федерального уровня, оказывающего высокотехнологическую медицинскую помощь, где работают светила нашей отечественной медицины и передают свой лучший опыт их последователям. Все организационные вопросы, связанные с записью на прием в индивидуальном порядке, даты, времени, подготовкой и передачей медицинской информации, чтобы были готовы и ждали Вас в назначенное время и сопровождение, мы берем на себя, и поверьте, вы останетесь довольны нашей заботой.`;

  return (
    <div className='itemDescriptionPageTextBlock'>
      <div className='itemDescriptionPageTextBlockTitle'>{title1}</div>
      <ul className="itemDescriptionPageTextBlockItemsList">
        {listItems1.map((listItem) => <li key={listItem}>{listItem}</li>)}
      </ul>
      {splitTextsByLineBreak(text1).map(text => <div className='itemDescriptionPageTextBlockText'>{text}</div>)}

      <div className='itemDescriptionPageTextBlockTitle'>{title2}</div>
      <ul className="itemDescriptionPageTextBlockItemsList">
        {listItems2.map((listItem) => <li key={listItem}>{listItem}</li>)}
      </ul>
      {splitTextsByLineBreak(text2).map(text => <div className='itemDescriptionPageTextBlockText'>{text}</div>)}

      <div className='itemDescriptionPageTextBlockTitle'>{title3}</div>
      {splitTextsByLineBreak(text3).map(text => <div className='itemDescriptionPageTextBlockText'>{text}</div>)}

      <div className='itemDescriptionPageTextBlockTitle'>{title4}</div>
      <ul className="itemDescriptionPageTextBlockItemsList">
        {listItems4.map((listItem) => <li key={listItem}>{listItem}</li>)}
      </ul>
      {splitTextsByLineBreak(text4).map(text => <div className='itemDescriptionPageTextBlockText'>{text}</div>)}

    </div>
  );
};


const TB1 = () => {
  const title = 'Жизнь с удовольствием от еды!';
  const mainText = `Часто, при неправильно подобранном рационе питания, людям не удается добиться желаемого снижения веса. Например, слишком строгий дефицит калорий или недостаточное потребление определенных питательных веществ может замедлить метаболизм и привести к потере мышечной массы вместо сокращения жира. Также важно учитывать индивидуальные потребности и особенности организма каждого человека при составлении рациона.
  \r\nМы учитываем эти факторы при разработке персонализированных планов питания, чтобы обеспечить нашим клиентам оптимальный рацион, способствующий похудению без ущерба для здоровья.`;

  const dietPlanHref = 'https://planpitania.ru/app/p/7768dcee-1a14-49ca-939c-629d0deba0fd';

  return (
    <div className='itemDescriptionPageTextBlock'>
      <div className='itemDescriptionPageTextBlockTitle'>{title}</div>
      {splitTextsByLineBreak(mainText).map(text => <div className='itemDescriptionPageTextBlockText'>{text}</div>)}
      <div className='itemDescriptionPageTextBlockAccentText'>
        Пример плана питания можно посмотреть <a className='opacity-on-hover link' href={dietPlanHref} target="_blank">по ссылке</a>
      </div>
    </div>
  );
};

const TB1Sport = () => {
  const title = 'Жизнь с удовольствием от еды и спорта!';
  const mainText = `Часто, при неправильно подобранном рационе питания, людям не удается добиться желаемого снижения веса. Например, слишком строгий дефицит калорий или недостаточное потребление определенных питательных веществ может замедлить метаболизм и привести к потере мышечной массы вместо сокращения жира. Также важно учитывать индивидуальные потребности и особенности организма каждого человека при составлении рациона.
  \r\nМы учитываем эти факторы при разработке персонализированных планов питания, чтобы обеспечить нашим клиентам оптимальный рацион, способствующий похудению без ущерба для здоровья.`;

  const dietPlanHref = 'https://planpitania.ru/app/p/7768dcee-1a14-49ca-939c-629d0deba0fd';

  return (
    <div className='itemDescriptionPageTextBlock'>
      <div className='itemDescriptionPageTextBlockTitle'>{title}</div>
      {splitTextsByLineBreak(mainText).map(text => <div className='itemDescriptionPageTextBlockText'>{text}</div>)}
      <div className='itemDescriptionPageTextBlockAccentText'>
        Пример плана питания можно посмотреть <a className='opacity-on-hover link' href={dietPlanHref} target="_blank">по ссылке</a>
      </div>
    </div>
  );
};

const T2 = () => {
  const title = 'Наши индивидуальные планы питания учитывают:';
  const listItems = [
    'ваши предпочтения в еде - любимые блюда, вегетарианство, без глютена/без лактозы/без сахара и т.д.),',
    'цели - похудение, набор веса, РПП (расстройство пищевого поведения), снижение процента жира и т.д.,',
    'уровень активности - сидячий образ жизни, легкая, умеренная, высокая активность,',
    'ваше желание и сколько раз в неделю вы хотите готовить,',
    'сколько раз подряд вы хотите кушать одно и тоже на завтрак, обед, ужин,',
    'какое количество приемов пищи в день вам комфортно,',
    'когда вы хотите перекусы.',
  ]

  return (
    <div className='itemDescriptionPageTextBlock'>
      <div className='itemDescriptionPageTextBlockTitle'>{title}</div>
      <ul className="itemDescriptionPageTextBlockItemsList">
        {listItems.map((listItem) => <li key={listItem}>{listItem}</li>)}
      </ul>
    </div>
  );
};

const T3 = () => {
  const title = 'Как будет составляться план питания?';
  const mainText = `Сначала мы проведем вводную консультацию, в ходе которой обсудим ваши цели, предпочтения и текущий образ жизни. Затем мы перейдем к заполнению подробной онлайн-анкеты. В ней мы учтем ваши предпочтения по продуктам, ваши цели, уровень физической активности, а также сколько раз в неделю вы хотите готовить. Это поможет нам создать наиболее подходящий и персонализированный план питания для вас.`;
  return (
    <div className='itemDescriptionPageTextBlock'>
      <div className='itemDescriptionPageTextBlockTitle'>{title}</div>
      {splitTextsByLineBreak(mainText).map(text => <div className='itemDescriptionPageTextBlockText'>{text}</div>)}
    </div>
  );
};

const T4 = () => {
  const title = 'Что я получу?';
  const listItems = [
    'Сбалансированный план питания и дополнительно к нему список продуктов, удобно разбитый по категориям продуктов и сроку их хранения. Таким образом вы быстро сможете все приобрести без спонтанных покупок и лишних трат.',
    'Детальные рецепты с пошаговыми инструкциями, чтобы вы могли легко и быстро приготовить вкусные и здоровые блюда. Независимо от вашего опыта в кулинарии, наши инструкции помогут вам готовить вкусные и питательные блюда, которые поддерживают ваши цели похудения или поддержания здорового образа жизни.',
  ]

  return (
    <div className='itemDescriptionPageTextBlock'>
      <div className='itemDescriptionPageTextBlockTitle'>{title}</div>
      <ul className="itemDescriptionPageTextBlockItemsList">
        {listItems.map((listItem) => <li key={listItem}>{listItem}</li>)}
      </ul>
    </div>
  );
};

const TB2 = () => {
  return (
    <div>
      <T2 />
      <T3 />
      <T4 />
    </div>
  )
}

const OnlineConsultTextBlock = () => {
  const title = 'Онлайн-консультация';
  const mainText = `Онлайн-консультация — это ваша возможность получить исчерпывающую информацию и подробные ответы на все ваши вопросы. В течение одного часа мы уточним каждую деталь, обсудим ваши индивидуальные потребности и цели, и разработаем стратегию действий, нацеленную на достижение желаемых результатов. Вы сможете получить всю необходимую информацию, чтобы принять информированное решение о вашем плане действий.`;
  return (
    <div className='itemDescriptionPageTextBlock'>
      <div className='itemDescriptionPageTextBlockTitle'>{title}</div>
      {splitTextsByLineBreak(mainText).map(text => <div className='itemDescriptionPageTextBlockText'>{text}</div>)}
    </div>
  );
};

const FullDietarySupportTextBlock = () => {
  const title = 'Зачем мне полное диетологическое сопровождение?';
  const mainText = `Иногда люди стараются придерживаться правильного питания, но им не хватает дисциплины или они теряют мотивацию из-за отсутствия результатов. В таких случаях полное диетологическое сопровождение становится незаменимым.
  \r\nВы получите:`;
  const listItems = [
    'Полноценный диетологический коучинг, который поможет вам освоить правильные пищевые привычки. Вы не просто похудеете, но и научитесь удерживать результат на автомате.',
    'Контроль и анализ результата каждую неделю.',
    'Ваш персональный ментор будет доступен для вас каждый день, обеспечивая вам постоянную волну мотивации и поддержки. Весь процесс адаптируется под ваш ритм жизни. При таких условиях не добиться результата просто невозможно.',
  ]

  return (
    <div className='itemDescriptionPageTextBlock'>
      <div className='itemDescriptionPageTextBlockTitle'>{title}</div>
      {splitTextsByLineBreak(mainText).map(text => <div className='itemDescriptionPageTextBlockText'>{text}</div>)}
      <ul className="itemDescriptionPageTextBlockItemsList">
        {listItems.map((listItem) => <li key={listItem}>{listItem}</li>)}
      </ul>
    </div>
  );
};



const TB5 = () => {
  const title = 'А если будет дорого?';
  const mainText = `Продукты для рациона выбираете ИМЕННО ВЫ и таким образом сами регулируете бюджет. Можно составить план из дорогих продуктов: креветки, икра и т.д., а можно сделать то же самое из доступных продуктов. При этом оба плана будут одинаково эффективные. Вы сами решаете, сколько хотите тратить на продукты.`;
  return (
    <div className='itemDescriptionPageTextBlock'>
      <div className='itemDescriptionPageTextBlockTitle'>{title}</div>
      {splitTextsByLineBreak(mainText).map(text => <div className='itemDescriptionPageTextBlockText'>{text}</div>)}
    </div>
  );
};

const OnlineTrainingsTextBlock = () => {
  const title = 'Онлайн-тренировки';
  const mainText = `У нас есть высококвалифицированные тренеры разных направлений - фитнес, пилатес, стретчинг и т. д. Для эффективности работы или психологического разнообразия можно менять и сочетать разные виды тренировок и тренеров. Во время онлайн-тренировки специалист отслеживает и исправляет технику сразу. 
  \r\nМы тщательно разрабатываем планы занятий, обеспечивая эффективность и безопасность каждой тренировки, при этом предоставляя возможность выбора и комбинирования разных видов активности для обеспечения как физического, так и психологического разнообразия.
  \r\nОсобое внимание уделяется выбору тренера. Мы понимаем, что успешные результаты и комфорт в процессе тренировок зависят не только от профессиональных качеств тренера, но и от его личностных характеристик. В нашей команде вы найдете тренера, который будет соответствовать вашему полу, направлению интересов и психологическому темпераменту. Независимо от того, ищете ли вы в тренере строгого наставника или дружелюбного мотиватора, мы подберем для вас идеального специалиста, с которым вам будет комфортно достигать поставленных целей.`;
  return (
    <div className='itemDescriptionPageTextBlock'>
      <div className='itemDescriptionPageTextBlockTitle'>{title}</div>
      {splitTextsByLineBreak(mainText).map(text => <div className='itemDescriptionPageTextBlockText'>{text}</div>)}
    </div>
  );
};

const ExercisingProcessTextBlock = () => {
  const title = 'Как проходит процесс ведения по тренировкам:';
  const numericListItems = [
    'Раз в неделю вы получаете тренировочный план со всеми инструкциями, видео,  включая регулировку интенсивности и технику выполнения упражнений. Тренировки будут составляться под ваши цели, пожелания, состояние здоровья, и возможности по инвентарю (дома или в фитнес-клубе)',
    'Ваша задача будет выполнять тренировочный план и регулярно сообщать ментору о выполненных упражнениях, а также информацией о своем самочувствии, пожеланиях, чтобы ментор мог адаптировать план тренировок под ваши потребности.',
    'Таким образом, ваш ментор будет учитывать ваши реальные возможности, пожелания, самочувствие, и каждую неделю будет составлять новую тренировочную программу, адаптированную под вас.',
    'Если вы сомневаетесь в правильности техники выполнения упражнения, вы всегда можете поставить видео в телефоне на таймер, снять выполнение упражнения на 5-10 сек и прислать своему ментору для проверки.',
  ]
  const mainText = `Таким образом ментором будут контролироваться все тренировочные показатели, самочувствие, ваши пожелания и техника выполнения.`;
  return (
    <div className='itemDescriptionPageTextBlock'>
      <div className='itemDescriptionPageTextBlockTitle'>{title}</div>
      <ol className="itemDescriptionPageTextBlockItemsList">
        {numericListItems.map((listItem) => <li key={listItem}>{listItem}</li>)}
      </ol>
      {splitTextsByLineBreak(mainText).map(text => <div className='itemDescriptionPageTextBlockText'>{text}</div>)}
    </div>
  );
};

// const DifferenceFromFreePublicDietPlans = () => {
const TB6 = () => {
  const title = 'Чем отличается этот план от того, что есть в общем доступе в интернете? Сработает ли он?';
  const text1 = 'В интернете есть 3 типа планов питания:';
  const numericListItems = [
    'Шаблоны — непонятно, кто их сделал, для кого и по каким принципам. Они не подойдут, потому что не ясно, похудеете вы в итоге или наберёте вес.',
    'Спортивный подход — это сушки для спортсменов бодибилдеров при подготовке к соревнованиям. Они вам тоже не подойдут, потому что в сушку нужно определённым образом войти и правильно из неё выйти. Этот процесс сопровождается упражнениями и препаратами, зачастую очень вредными для здоровья и запрещёнными законом.',
    'Различные диеты — низкокалорийные, низкоуглеводные, безжировые и другие диеты. Они тоже не подойдут, потому что их могут назначать только врачи по анализам и соответствующим показаниям. После таких рационов почти всегда неизбежен откат и возвращение сброшенного веса.',
  ]
  
  const text2 = 'Наш план питания будет не только учитывать нужное количество калорий, но и сбалансированность по составу и соотношение БЖУ. А именно:';
  const listItems = [
    'Нужное количество белков: растительных; животных.',
    'Нужное количество жиров: насыщенных; ненасыщенных.',
    'Нужное количество углеводов: быстрых; медленных.',
  ]
  
  const text3 = 'Рацион идеально сбалансирован, но и это ещё не все. Он составлен с учётом баланса микро- и макронутриентов и витаминов. Потому что этот план в первую очередь про здоровье и стабильный результат.';

  return (
    <div className='itemDescriptionPageTextBlock'>
      <div className='itemDescriptionPageTextBlockTitle'>{title}</div>

      {splitTextsByLineBreak(text1).map(text => <div className='itemDescriptionPageTextBlockText'>{text}</div>)}
      <ol className="itemDescriptionPageTextBlockItemsList">
        {numericListItems.map((listItem) => <li key={listItem}>{listItem}</li>)}
      </ol>

      {splitTextsByLineBreak(text2).map(text => <div className='itemDescriptionPageTextBlockText'>{text}</div>)}
      <ul className="itemDescriptionPageTextBlockItemsList">
        {listItems.map((listItem) => <li key={listItem}>{listItem}</li>)}
      </ul>

      {splitTextsByLineBreak(text3).map(text => <div className='itemDescriptionPageTextBlockText'>{text}</div>)}
    </div>
  );
};

const TB7 = () => {
  const title1 = 'Каким должен быть план питания, чтобы он идеально сработал?';
  const text1 = `Персонализированный. 
  \r\nВсе расчеты, рекомендации и сам рацион должны быть основаны на ваших параметрах и особенностях. Индивидуальный план питания, ориентированный на ваши вкусовые предпочтения, который комфортно вписывается в ваш ритм жизни и учитывает возможности и желание готовить.
  \r\nШаблонные планы питания не учитывают вкусовые предпочтения. А это очень важно! Иначе вам будет невкусно, непривычно и некомфортно. Вы будете срываться, нарушать план и не получите результата!`;

  const title2 = 'А сладкое можно?';
  const text2 = 'Нет ограничений. Сладкое можно! В плане может быть шоколад, мармелад, печенье, вафли и т.д.';

  const title3 = 'Насколько я похудею за месяц?';
  const numericListItems3 = [
    'У нас здоровый подход. Реалистично похудеть на 3-7 кг за 1 месяц.',
    'С планом уходят и жир, и вода.',
    'Отката не произойдёт, потому что не захочется съесть лишнего. Вы будете сыты.',
  ]

  const title4 = 'Мне каждый месяц нужно покупать новый план? ';
  const text4 = 'Нет. Советуем делать перерасчет плана, когда ваш вес изменится на 5-10 кг.';

  const title5 = 'В плане питания очень много блюд. Как мне столько съесть?';
  const text5 = `Почему возникает этот вопрос? Потому что план питания составлен так, чтобы вы не голодали. Когда вы на дефиците и сжигаете жир, организм получает калорий меньше, чем ему требуется. Из-за этого чувство голода усиливается. 
  \r\nЕсли питание подобрано неправильно, то человек рано или поздно сорвется!
  \r\nНаш план питания составляется таким образом, чтобы вы были сыты. Наши подопечные не только не голодают, некоторые даже не могут за день съесть полный рацион. 
  \r\nСъедать все полностью не обязательно! Главное – не переедать!`;

  const title6 = 'Что на что из продуктов можно менять?';
  const text6 = 'План будет идеально выстроен под ваши вкусовые предпочтения. Но у вас есть возможность делать любые самостоятельные замены по желанию. В инструкции будет описано, как делать замены, чтобы не снизить эффективность плана. Имеет смысл менять схожие продукты: крупу на крупу, гарнир на гарнир, рыбу на рыбу и т.д.';

  const title7 = 'Можно ли похудеть только за счет питания?';
  const text7 = `Можно,но мы очень рекомендуем добавить тренировки (дома или в зале). Результат будет намного быстрее и качественнее.
  \r\nЕсли сидеть только на диетах:`;
  const listItems7 = [
    'Минус на весах достигается в первую очередь за счёт ухода мышц и воды.',
    'Жира сжигается гораздо меньше. Из-за этого тело будет выглядеть дрябло.',
    'Замучает постоянное чувство голода.',
  ]

  const title8 = 'Чем меньше буду есть, тем быстрее похудею';
  const text8 = `Это не так. Многие сидят на низкокалорийных диетах в надежде получить быстрые результаты. Но зачастую эти результаты не получается сохранить. Дело заканчивается срывом и набором еще большего веса! Почему? Потому что нельзя длительное время кушать меньше базового обмена, если это не рекомендация врача на основе анализов.
  \r\nЧтобы убрать проблемные зоны, нужно употреблять на 20-30% меньше калорий, чем тратит ваше тело.
  \r\nВНИМАНИЕ! Эти 20-30% нужно отнимать не от основного обмена, а от суммарного количества калорий, которое тратит ваше тело. Для этого нужно учитывать бытовую и тренировочную активность. 
  \r\nПочему 20-30%? Потому что такой дефицит обеспечит быстрые и безопасные темпы снижения: 3-7 кг жира в месяц или 4-8 см в животе за месяц.
  \r\nОн позволяет поддерживать достигнутый результат и не срываться. Именно такой дефицит мы делаем в планах для своих подопечных.
  `;

  const title9 = 'Обязательно нужно 2 грамма белка на 1 кг веса';
  const text9 = `Не совсем так. 2 грамма белка — это для набора веса или для профессиональных спортсменов на сушке. Рекомендация ВОЗ (всемирная организация здравоохранения) – 1 грамм белка на кг идеальной массы тела при поддержке веса, когда не надо набирать мышечную массу и снижать жир.
  \r\nНапример, если ваш фактический вес 100 кг, а идеальный — 70, значит и белка для поддержания веса вам нужно 70. Если вы набираете или снижаете вес, белка нужно больше. Если вы спортсмен, много тренируетесь, то у вас другие затраты и белка нужно больше. Все это учитывается при составлении индивидуального плана питания.`;

  const title10 = 'Нужно убирать жиры';
  const text10 = `Нет. Жира должно быть около 1 грамма на кг. Он нужен для здоровья кожи, волос, ногтей и нормальной работы гормонов.`;

  const title11 = 'Нужно убирать углеводы';
  const text11 = `Нет. Норма ВОЗ – 4 грамма на кг идеальной массы тела. Когда мы худеем, ее нужно снижать до 2,5-3 грамм в зависимости от количества тренировок. Имейте в виду, 2 грамма – это минимум, который нужен ЦНС, чтобы у вас было хорошее настроение, вы могли функционировать, думать и избежать срывов.`;

  const title12 = 'Делаете ли вы планы для вегетарианцев?';
  const text12 = `Да — для любого типа вегетарианцев (строгие, ововегетарианцы, лактовегетарианцы и т.д.). Учитывая то, что часть продуктов вегетарианцы не могут включить в свой рацион, очень важно чтобы рацион был сбалансирован по БЖУ и микронутриентам.`;

  const title = '';
  const text = ``;


  return (
    <div className='itemDescriptionPageTextBlock'>
      <div className='itemDescriptionPageTextBlockTitle'>{title1}</div>
      {splitTextsByLineBreak(text1).map(text => <div className='itemDescriptionPageTextBlockText'>{text}</div>)}
      
      <div className='itemDescriptionPageTextBlockTitle'>{title2}</div>
      {splitTextsByLineBreak(text2).map(text => <div className='itemDescriptionPageTextBlockText'>{text}</div>)}

      <div className='itemDescriptionPageTextBlockTitle'>{title3}</div>
      <ol className="itemDescriptionPageTextBlockItemsList">
        {numericListItems3.map((listItem) => <li key={listItem}>{listItem}</li>)}
      </ol>

      <div className='itemDescriptionPageTextBlockTitle'>{title4}</div>
      {splitTextsByLineBreak(text4).map(text => <div className='itemDescriptionPageTextBlockText'>{text}</div>)}

      <div className='itemDescriptionPageTextBlockTitle'>{title5}</div>
      {splitTextsByLineBreak(text5).map(text => <div className='itemDescriptionPageTextBlockText'>{text}</div>)}

      <div className='itemDescriptionPageTextBlockTitle'>{title6}</div>
      {splitTextsByLineBreak(text6).map(text => <div className='itemDescriptionPageTextBlockText'>{text}</div>)}

      <div className='itemDescriptionPageTextBlockTitle'>{title7}</div>
      {splitTextsByLineBreak(text7).map(text => <div className='itemDescriptionPageTextBlockText'>{text}</div>)}
      <ul className="itemDescriptionPageTextBlockItemsList">
        {listItems7.map((listItem) => <li key={listItem}>{listItem}</li>)}
      </ul>

      <div className='itemDescriptionPageTextBlockTitle'>{title8}</div>
      {splitTextsByLineBreak(text8).map(text => <div className='itemDescriptionPageTextBlockText'>{text}</div>)}

      <div className='itemDescriptionPageTextBlockTitle'>{title9}</div>
      {splitTextsByLineBreak(text9).map(text => <div className='itemDescriptionPageTextBlockText'>{text}</div>)}

      <div className='itemDescriptionPageTextBlockTitle'>{title10}</div>
      {splitTextsByLineBreak(text10).map(text => <div className='itemDescriptionPageTextBlockText'>{text}</div>)}

      <div className='itemDescriptionPageTextBlockTitle'>{title11}</div>
      {splitTextsByLineBreak(text11).map(text => <div className='itemDescriptionPageTextBlockText'>{text}</div>)}

      <div className='itemDescriptionPageTextBlockTitle'>{title12}</div>
      {splitTextsByLineBreak(text12).map(text => <div className='itemDescriptionPageTextBlockText'>{text}</div>)}
    </div>
  );
};

const TariffPlanTextBlocks = {
  MedconciergeTextBlock,
  TB1,
  TB1Sport,
  TB2,
  OnlineConsultTextBlock,
  FullDietarySupportTextBlock,
  TB5,
  OnlineTrainingsTextBlock,
  ExercisingProcessTextBlock,
  TB6,
  TB7,
};

export default TariffPlanTextBlocks;