import { ReactNode, useEffect } from 'react';

import { useScreenSize, useSetTitle } from '../utils/hooks';
import { DimensionsData } from '../utils/types';

import { useSelector } from 'react-redux';
import CookiesConsentNotification from '../components/CookiesConsentNotification/CookiesConsentNotification';
import Footer from '../components/Footer/Footer';
import Header from '../components/Header/Header';
import { RootState } from '../redux/store';

import './BaseLayout.scss';

const BaseLayout = ({
  content,
  hideSectionLinks,
  hideFooter,
}: {
  content: ReactNode;
  hideSectionLinks: boolean;
  hideFooter?: boolean;
}) => {
  console.log(`upd. 13.05.24 #1 (${process.env.REACT_APP_ENV})`);

  useSetTitle();

  useEffect(() => {
    window.ym(96548425, "init", {triggerEvent: true});
  }, [])
  

  const { screenSize, isVerticallyOriented } = useScreenSize();
  const dimensionsData: DimensionsData = { screenSize, isVerticallyOriented };

  const blockInterface = useSelector((state: RootState) => state.main.blockInterface);

  return (
    <div className="appContainer">
      {blockInterface && <div className="disablePageOverlay" />}

      <CookiesConsentNotification />

      <Header
        dimensionsData={dimensionsData}
        hideSectionLinks={hideSectionLinks}
      />

      {content}

      {!hideFooter && <Footer dimensionsData={dimensionsData} hideSectionLinks={hideSectionLinks} />}
    </div>
  );
};

export default BaseLayout;
