enum ProductTag {
  man = 'Мужчина',
  woman = 'Женщина',
  child = 'Ребёнок',
  parents = 'Родители',
  family = 'Семья',
  ageRange1 = '0–3 года',
  ageRange2 = '3–10 лет',
  ageRange3 = '10–18 лет',
  ageRange4 = '18–25 лет',
  ageRange5 = '25–35 лет',
  ageRange6 = '35–45 лет',
  ageRange7 = '45–60 лет',
  ageRange8 = '60+ лет',
  pregnancy = 'Беременность',
  sport = 'Спорт и фитнес',
  diet = 'Правильное питание',
  treatment = 'Лечение',
  international = 'Медицина за рубежом',
  chronicDiseases = 'Хронические заболевания',
  psychAssistance = 'Психологическая поддержка',
  sleepIssues = 'Проблемы со сном',
  noEnergy = 'Отсутствие энергии',
  stopSmoking = 'Бросить курить',
  stopAlcohol = 'Отказаться от алкоголя',
  badHabits = 'Вредные привычки',
  anger = 'Гнев',
  depression = 'Депрессия',
  apathy = 'Апатия',
  complexCases = 'Сложные случаи',
  // prophylaxis = 'Профилактика',
}

export const commonTags: ProductTag[] = [
  ProductTag.man,
  ProductTag.woman,
  ProductTag.child,
  ProductTag.parents,
  ProductTag.family,

  ProductTag.ageRange1,
  ProductTag.ageRange2,
  ProductTag.ageRange3,
  ProductTag.ageRange4,
  ProductTag.ageRange5,
  ProductTag.ageRange6,
  ProductTag.ageRange7,
  ProductTag.ageRange8,
];