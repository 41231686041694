import React from 'react'
import BaseLayout from '../BaseLayout';
import { useLocation } from 'react-router-dom';
import images from '../../utils/images';
import { useOnReturnToMainPageButtonClick, useScrollToTop } from '../../utils/hooks';
import './ValuesPage.scss';

interface LearnMoreData {
  title: string;
  titleShort: string;
  icon: string;
  mainTexts: string[];
}

export const learnMoreDataArray: LearnMoreData[] = [
  {
    titleShort: 'Персональное общение с ментором',
    title: 'Персональное общение с ментором',
    icon: images.handshake,
    mainTexts: [
      `Личный ментор – это чуть больше, чем просто коучер, тренер или наставник, это ваш личный проводник, который сначала проведет диагностику, расскажет что нужно делать, грамотно составит мотивационную программу и приведет к достижению ваших личных целей.
      \r\nНачинается всё с первичной консультации непосредственно с наставником - ментором, который потом будет поддерживать вас до результата весь период.`,
      `На первичной консультации выявляются основные потребности, индивидуальные особенности, пожелания и мотивация. По итогу вы получаете индивидуальный план (сколько раз, какие продукты можно или не нужно, чем заменить, какие упражнения дома или на свежем воздухе и т.д., в зависимости от выбранной программы), рассчитанный именно по вашему запросу и составленный с учетом ваших пожеланий и параметров (калории/белки/жиры/углеводы, вес, объем, мышечная масса, психоэмоциональное состояние и т.д.). `,
      `На протяжении всего курса ваш наставник будет лично сопровождать, отвечать на вопросы, подсказывать правильно или не правильно вы следуете намеченному плану, делиться опытом, подсказывать и стремиться к позитивному изменению настроения. Общаться онлайн удобным доступным образом или с помощью мобильного приложения ETNA APP с интегрированным мессенджером.
      \r\nВы можете схалтурить или пропустить тренировку, отступить от намеченного плана или графика приема пищи, но потом тренер спросит у вас, что пошло не так? Конечно, такие случаи встречаются, и на это последует корректировка персонального плана, и ваш наставник скажет об этом.
      \r\nДля особо настойчивых и целеустремленных предусмотрены совместные с тренером-наставником онлайн или офлайн тренировки, занятия, встречи на спортивных площадках или в специальных залах для групповых занятий с удобной локацией. Обычно, такие совместные встречи нужны в самом начале, когда тяжело самому настроиться на оптимальный ритм и много соблазнов.
      \r\nМы даем каждому клиенту возможность личного общения и поддержки на всем протяжении ЗОЖ-программы. В этом и заключается суть менторства. Зная об общих социальных «проблемах», связанных с потерей жизненной энергии, выгоранием, депрессиях, ленью и желанием отложить всё «на потом», ваш наставник в онлайн режиме напомнит о намеченных планах и поможет достичь желаемого результата! `,
      `За 10 лет работы наших тренеров-наставников и менторов, более 3 500 человек достигли гармонии и радости от общения с единомышленниками. Мы помогли стать энтузиастами в ведении здорового образа жизни, многие из тех, кто начинал заниматься с нами много лет назад, сегодня сами являются наставниками и помогают другим поддерживать свое здоровье и получать радость от жизни! `,
    ],
  },
  {
    titleShort: 'Готовый пакет ЗОЖ',
    title: 'Wellness-программы. Готовый пакет ЗОЖ.',
    icon: images.document,
    mainTexts: [
      `В 60% случаев у мужчин и женщин запросы на увеличение мышечной массы, снижение лишнего веса, повышение жизненной энергии и т.д. совпадают с общими причинами их возникновения и не требуют каких-либо радикальных методов коррекции. Нашими специалистами разработаны «Готовые пакеты ЗОЖ» для подобных случаев и они также подходят для начинающих. Этого достаточно, чтобы с чего-то начать, за 1 месяц получить ощутимый результат или с чем-то сравнить.`,
      `Например, вы не переплачиваете за годовой абонемент в спортзал, не ходите две-три недели, а потом бросаете занятия, потому что одному не получается. Вы не тратите кучу времени на поиск и выбор специалиста в интернете, чтобы заплатить ему за разовую консультацию и, возможно, разочароваться, потому что вам что-то не понравилось и т.д. Так может быть не один раз, потому что действительно, сложно разобраться в глобальном информационном потоке. Гарантируем, что среди наших предложений нет блогеров-любителей, временных людей без специального образования и подготовки. Мы сознательно избегаем таких контактов, потому что предоставляем только качественные услуги от профессионалов, которые знают как устроен организм человека и как он функционирует.
      \r\n«Готовый пакет ЗОЖ» - это предложение от профессиональных наставников, тренеров, менторов и терапевтов с многолетним опытом работы в своей области, с высшим медицинским или специальным образованием, сертифицированные тренеры, прошедшие специальную подготовку в образовательных учреждениях. Это неs новички, у всех давно есть свои постоянные клиенты. И готовые пакеты уже отработаны на первопроходцах, разработаны специально с учетом наиболее распространенных запросов, чтобы облегчить клиенту задачу выбора и решить с чего начать, чтобы получить ощутимый результат.
      \r\nМинимальный срок программы 1 месяц. Это связано прежде всего с необходимостью настройки (перенастройки) организма на новый лад. Он должен научиться соблюдать режим, привыкнуть к дисциплине или может быть «вспомнить» как это было раньше, как работали мышцы, сосуды, внутренние органы, чтобы избавиться от чего-то лишнего (внутренний жир, токсины, ненужные воспоминания, тревоги и т.д.). За один день или за одну тренировку накопленную годами проблему не убрать и нового себя не сделать. Нужно немного потрудиться, и если вы пришли к нам, значит на 50% приблизились к своему успеху!
      \r\nЗа 1 месяц и за фиксированную цену, без переплат вы получите гарантированный ощутимый результат. Ваш персональный тренер-наставник или терапевт знает с чего начать, что нужно делать и как найти подход к клиенту. По окончанию программы можно ставить новые цели и проявлять новые возможности, а иначе зачем всё это нужно? 😊`,
    ],
  },
  {
    titleShort: 'Сопровождение до результата',
    title: 'Сопровождение до результата. Повышаем качество жизни.',
    icon: images.rocket,
    mainTexts: [
      `Или зачем всё это нужно? 😊`,
      `Стремление быть лучше, быть красивым и успешным заложено в нас природой. При этом, как ни странно, не для всех людей это главное, не все хотят взобраться на вершину горы и быть выше всех, быстрее всех, сильнее всех. Для нас, например, важнее уделить внимание тому, что мы можем сделать для себя и для других в обычной жизни и как справиться со всеми этими внешними напастями, которые почему-то накапливаются внутри нас, портят настроение, обостряют отношения с близкими, утомляют, от этого страдает внешний вид и вылазят разного рода болячки. Все в нашем организме взаимосвязано, в том числе на ментальном уровне. Мы считаем, что важно суметь прислушаться к себе и понять, что дорогу к своей мечте, благополучию в доме или уважению со стороны коллег можно построить самому. Начать можно с себя и тогда жизнь заиграет новыми красками, об этом говорят все кто пришел к нам!
      \r\nКак это сделать, знают и помогают наши наставники-профессионалы, менторы и терапевты с большим опытом работы. После диагностики и составления индивидуального плана вас будут вести и сопровождать на протяжении всей программы. Общение проходит в онлайн или офлайн формате, или посредством мобильного приложения ETNA APP с интегрированным мессенджером в удобное для вас время. Вы сразу почувствуете как легко и непринужденно вас подхватят позитивные люди, особенно это важно в первые 10-12 дней, самый трудный период. Нужно найти свой ритм тренировок, питания, упражнений и т.д. Это не сразу приходит, организм будет сопротивляться против ваших же усилий над собой, а пройти этот период легче всего под присмотром нашего специалиста, тренера-наставника или терапевта, который подскажет что происходит в вашем организме и как на это реагировать? Главная задача тренера-наставника – не бросить вас в трудный период в самом начале пути, помочь найти собственный ритм, закрепить эффект на уровне позитивных изменений, а дальше, при желании конечно, выйти на следующий уровень!
      \r\nВ конечном итоге, при регулярных физических нагрузках мышцы начнут возвращать свою форму и организм будет больше вырабатывать дофамин, адреналин, кортизол и др., вызывая ощущение удовлетворенности, улучшится работа имунной системы, повысится либидо, что положительно влияет на качество интимных потребностей и сексуальных возможностей. 😊
      \r\nПри снижении веса за счет жировой ткани, особенно в брюшной полости улучшится кровообращение внутренних органов и деятельность сердечно-сосудистой системы, уйдет функциональная одышка, заметно улучшится цвет лица, коллеги заметят как вы похудели, появится улыбка. 😊
      \r\nПри правильном подборе рациона питания вы почувствуете новые вкусовые ощущения, даже от тех продуктов, на которые раньше не обратили бы внимания, научитесь наслаждаться пищей в достаточном для человека количестве и своими вкусовыми ощущениями. 😊
      \r\nПри комплексном подходе и на ментальном уровне вы увидите как заметно изменились внешне в лучшую сторону, сомнения растворятся, тревоги уйдут, дыхание станет глубже, а сон крепче. И главное – у вас улучшится настроение. Улучшится физиологический и энергетический баланс, вы сможете самостоятельно регулировать свое отношение к важному и не важному, срочному и не очень, настоящему и ненастоящему. Как следствие это отношение придаст больше уверенности в себе и по цепочке отразится на ваших взаимоотношениях дома, в семье, на работе и даже с незнакомыми людьми – с вами захотят дружить! 😊`,
    ],
  },
]

const ValuesPage = () => {
  useScrollToTop({});

  // use location object to get query parameters (order id) from e.g. .../xxx?id=123
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const itemId = searchParams.get('id');
  const learnMoreData = learnMoreDataArray[itemId ? parseInt(itemId) : 0];

  const onReturnButtonClick = useOnReturnToMainPageButtonClick();

  return (
    <BaseLayout
      hideSectionLinks={false}
      content={
        <div className="learnMorePageContainer">

          <div className="learnMorePageBackButton link opacity-on-hover" onClick={onReturnButtonClick}>
            <img className="learnMorePageBackButtonImage" src={images.arrowLeft} />
            {'Назад'}
          </div>
          <div className="learnMorePageTitle">{learnMoreData.title}</div>

          <div className="learnMorePageMainBlock">
            <div className="learnMorePageMainColumn">
              <div>{learnMoreData.mainTexts[0]}</div>

              <img className='learnMorePageBannerMobile' src={images.learnMoreBannerMobile} />

              {learnMoreData.mainTexts.slice(1).map(text => <div>{text}</div>)}

              <button className="app-button learnMorePageBottomReturnButton" onClick={onReturnButtonClick}>
                {'На главную'}
              </button>
            </div>

            <div className='learnMorePageBannerDesktopContainer'>
              <img className='learnMorePageBannerDesktop' src={images.learnMoreBannerDesktop} width={522} height={264} />
            </div>
          </div>
        </div>
      }
    />
  );
}

export default ValuesPage;