import { useState } from 'react';
import PurchaseProductModal from '../../components/PurchaseProductModal/PurchaseProductModal';
import { useOnReturnToMainPageButtonClick, useScrollToTop } from '../../utils/hooks';
import images from '../../utils/images';
import { ProductData } from '../../utils/types';
import BaseLayout from '../BaseLayout';
import ProductItem from '../MainPage/components/5.Products/components/ProductItem';
import { ProductDevName } from '../MainPage/components/5.Products/constants/constants';
import { productItems } from '../MainPage/components/5.Products/constants/products';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import '../OrderStatusPage/components/PaymentProcessingBlock.scss';
import { getCensoredEmailString } from '../../utils/helper';

const SentFreeGuidePage = () => {
  const onReturnButtonClick = useOnReturnToMainPageButtonClick();
  useScrollToTop({ smooth: false });

  // ####################################################################################
  const emailString = useSelector((state: RootState) => state.main.freeGuideEmail);

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const openPurchaseProductModal = () => setModalIsOpen(true);
  const closePurchaseProductModal = () => setModalIsOpen(false);

  const [productData, setProductData] = useState<ProductData>();
  const [selectedVariantId, setSelectedVariantId] = useState<number>();
  const onPurchaseClick = (item: ProductData, variantId: number) => {
    setProductData(item);
    setSelectedVariantId(variantId);
    openPurchaseProductModal();
  }

  // ####################################################################################
  const suggestedTariffPlans = [
    ProductDevName.medconcierge,
    ProductDevName.liteHealthDiet,
  ];

  // ####################################################################################
  const displayData = {
    statusImage: images.modalSuccess,
    statusImageClass: 'paymentProcessingStatusIconSuccess',
    firstColumnTitle: 'Мы отправили вам бесплатный гайд: «Хочу в ПП. С чего начать?»',
    firstColumnText: `Мы отправили письмо с гайдом на почту ${getCensoredEmailString(emailString)}`,
    buttonTitle: 'Вернуться на главную',
    onButtonClick: onReturnButtonClick,
    secondColumnTitle: 'Может быть интересно',
  }

  return (
    <BaseLayout
      hideSectionLinks={false}
      content={
        <div className="paymentProcessingContainer">
          <PurchaseProductModal
            productData={productData!}
            selectedVariantId={selectedVariantId!}
            selectedTags={[]}
            modalIsOpen={modalIsOpen}
            closeModal={closePurchaseProductModal}
          />
          <div className="paymentProcessingMainSectionContainer">
            <div className="paymentProcessingMainSectionContainerInner" style={{ maxWidth: 500 }}>
              <img src={displayData.statusImage} alt="" className={displayData.statusImageClass} />
              <div className="paymentProcessingMainSectionTitle">{displayData.firstColumnTitle}</div>
              <div className="paymentProcessingMainSectionText">{displayData.firstColumnText}</div>
              <button
                onClick={displayData.onButtonClick}
                className="app-button paymentProcessingButton"
              >
                {'Вернуться на главную'}
              </button>
            </div>
          </div>
          <div
            className="paymentProcessingSuggestedSectionContainer"
            style={{ backgroundImage: images.successfulPaymentSecondColumnBackground }}
          >
            <div className="paymentProcessingSuggestedSectionTitle">{displayData.secondColumnTitle}</div>
            <div className="paymentProcessingSuggestedSectionContainerInner">
              {suggestedTariffPlans.map((devName) => (
                <ProductItem
                  key={devName}
                  productData={productItems.find(item => item.devName === devName)!}
                  onPurchaseClick={onPurchaseClick}
                  onMoveUserToConsult={() => {}}
                  useMobileDesign={false}
                  usePaymentProcessingVariant
                />
              ))}
            </div>
          </div>
        </div>
      }
    />
  );
}

export default SentFreeGuidePage;
